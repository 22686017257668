import * as React from 'react';
import IApplicationFilters from 'common/types/IApplicationFilters';
import styles from './ApplicationFilters.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import CustomSelectField from '../../common/components/CustomSelectField';
import IDmc from '../types/IDmc';
import { sortBy } from 'lodash';
import { Operators } from 'reports/types/ICommon';

interface IProps extends WithStyles<typeof styles> {
  initialApplicationFilters: IApplicationFilters;
  dmcs: IDmc[];
  onDmcChange: any;
  selectedDmcs: number[];
  addMessage: any;
  removeMessage: any;
  operators: Operators
  selectedOperator: string
  onOperatorChange: any
}

class ApplicationFilters extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

  }

  public render() {
    const { classes, dmcs, onDmcChange, selectedDmcs, operators, selectedOperator, onOperatorChange } = this.props;

    const dmcsSorted = sortBy(dmcs, dmc =>
      (dmc.name ? `${dmc.name.trim().toLowerCase()}` : '')
    );

    const shouldDisplayOperator = () => {
      return operators && operators.length > 1 && window.location.pathname === '/planning/dashboard'
    }

    const dmcsOptions = dmcs && dmcsSorted.map(x => ({ value: x.id, text: x.name }));

    return (
      dmcs && (
        <div className={classes.container}>
          <div className={classes.inputContainer}>
            <CustomSelectField
              name={'dmcs'}
              label="Operator"
              value={selectedDmcs}
              onChange={value => onDmcChange(value)}
              options={dmcsOptions}
              clearable={true}
              labelClass={classes.label}
              multiSelect={false}
              selectClass={classes.select}
            />
          </div>
          {shouldDisplayOperator() && (
            <div className={classes.inputContainer}>
              <CustomSelectField
                name={'agent'}
                label="Agent"
                value={[selectedOperator]}
                onChange={(value) => onOperatorChange(value[0])}
                options={operators}
                clearable={true}
                labelClass={classes.label}
                multiSelect={false}
                selectClass={classes.select}
              />
            </div>
          )}

        </div>
      )
    );
  }
}

export default withStyles(styles)(ApplicationFilters);
