import formatDate from 'common/helpers/formatDate';
import IDepartureStatusCriteria from 'reports/types/IDepartureStatusCriteria';

import { downloadReport } from 'reports/utils/downloadReport';

export default class DepartureStatus {
  public service: any;

  constructor(service) {
    this.service = service;
  }

  public async getDepartureStatusReport(
    departureStatusData: IDepartureStatusCriteria,
    fileType: string
  ) {
    const resourcePath = '/departurestatusreport';

    const data = await this.service.post(resourcePath, departureStatusData);
    const fileName = 'Departure Status_' + formatDate(departureStatusData.departureDateFrom, 'ddMMMyyyy')
      + '_' + formatDate(departureStatusData.departureDateTo, 'ddMMMyyyy');
    downloadReport(data.body, fileName, fileType);
  }

  public async getTripCodes(operatorId: number) {
    const resourcePath = `/commonresources/tripcodes/${operatorId}`;

    const data = await this.service.get(resourcePath);
    return data.body;
  }
}
