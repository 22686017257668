import * as React from 'react';
import ErrorMessage from './ErrorMessage';
import createLink from '../../common/helpers/createLink';
import { ROUTE_DASHBOARD } from 'common/constants/routes';

const NotFound404 = () => (
  <ErrorMessage
    code="404"
    name="Not Found"
    message="The requested page could not be found"
    actions={[{ name: 'Go to Dashboard', href: createLink(ROUTE_DASHBOARD) }]}
  />
);

export default NotFound404;
