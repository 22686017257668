export default class DepartureCovidQuestionnaire {
  public service: any;
  public baseResourcePath: string;
  constructor(service) {
    this.service = service;
    this.baseResourcePath = '/covidQuestionnaireResponses';
  }

  public async getByDeparture(id: number, type: number) {
    const resourcePath = `/departure/${encodeURIComponent(id.toString())}/${encodeURIComponent(type.toString())}${this.baseResourcePath
      }`;

    const data = await this.service.get(resourcePath);
    return data.body;
  }
}
