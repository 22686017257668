import * as actionTypes from '../../actionTypes/planningDepartures/search';
import IPlanningDeparture from '../../types/IPlanningDeparture';

type State = IPlanningDeparture[] | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.PLANNING_DEPARTURES_GET_SUCCESS:
      return action.payload.departures;
    default:
      return state;
  }
};
