export default class SystemConfigs {
  public service: any;
  constructor(service) {
    this.service = service;
  }

  public async get(codes) {
    const baseResourcePath = '/systemConfig';
    const resourcePath = `${baseResourcePath}/${encodeURIComponent(
      codes
    )}`;
    const data = await this.service.get(resourcePath);
    const systemConfigs = data.body;
    for (const key in systemConfigs) {
      if (systemConfigs[key] === 'true') {
        systemConfigs[key] = true;
      } else if (systemConfigs[key] === 'false') {
        systemConfigs[key] = false;
      }
    }
    return systemConfigs;
  }
}
