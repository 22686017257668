import * as React from 'react';
import IPlanningDeparture from '../types/IPlanningDeparture';
import { Card, CardHeader, CardContent, Collapse } from '@material-ui/core';
import PlanningDeparture from './PlanningDeparture';
import Typography from '@material-ui/core/Typography';
import AvatarLogo from 'common/components/AvatarLogo';

interface IState {
  collapsed: boolean;
}

interface IProps {
  departures: IPlanningDeparture[];
}

class PlanningProductDepartures extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true
    };
  }

  public render() {
    const { departures } = this.props;
    const productHeader = this.getProductHeader(departures[0]);

    return (
      <Card key={productHeader}>
        <CardHeader
          avatar={
            <AvatarLogo
              brand={departures[0].brand.name}
              imgId={'img.header_' + productHeader}
            />
          }
          title={
            <Typography variant="body2" color="inherit">
              {productHeader}
            </Typography>
          }
          id={'productGroupKey_' + productHeader}
          onClick={() => this.handleClick()}
        />

        <Collapse in={!this.state.collapsed} unmountOnExit>
          <CardContent>{this.renderDepartures(departures)}</CardContent>
        </Collapse>
      </Card>
    );
  }

  private getProductHeader(departure: IPlanningDeparture) {
    return departure.product.code + ' - ' + departure.product.name;
  }

  private renderDepartures(departures: IPlanningDeparture[] | undefined) {
    return (
      departures &&
      departures.map(dep => <PlanningDeparture departure={dep} key={dep.id} />)
    );
  }

  private handleClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
}

export default PlanningProductDepartures;
