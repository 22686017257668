import { TripCodes } from 'reports/types/ICommon';
import * as actionTypes from '../actionTypes/tripCodes';

type State = TripCodes | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.GET_TRIP_CODES:
      return action.payload.tripCodeList;
    default:
      return state;
  }
};
