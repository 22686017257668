import * as searchResults from './searchResults';
import * as selectedDeparturePax from './selectedDeparturePax';
import * as selectedDepartureTransfers from './selectedDepartureTransfers';
import * as selectedDepartureAccommodation from './selectedDepartureAccommodation';
import * as selectedDepartureSightseeing from './selectedDepartureSightseeing';
import * as selectedDeparturePaxChanges from './selectedDeparturePaxChanges';
import * as selectedDeparture from './selectedDeparture';
import * as selectedDepartureItinerary from './selectedDepartureItinerary';
import * as selectedDepartureCrew from './selectedDepartureCrew';
import * as searchCriteria from './searchCriteria';
import * as selectedDepartureCovidQuestionnaireResponses from './selectedDepartureCovidQuestionnaireResponses';
import * as selectedDeparturePaxHealthScreeningResponses from './selectedDeparturePaxHealthScreeningResponses';

export default {
  searchCriteria,
  searchResults,
  // Selected Departure
  selectedDeparture,
  selectedDeparturePax,
  selectedDepartureTransfers,
  selectedDepartureAccommodation,
  selectedDepartureSightseeing,
  selectedDeparturePaxChanges,
  selectedDepartureCrew,
  selectedDepartureItinerary,
  selectedDepartureCovidQuestionnaireResponses,
  selectedDeparturePaxHealthScreeningResponses
};
