import * as React from 'react';
import { connect } from 'react-redux';
import ApplicationFilters from '../components/ApplicationFilters';
import IDmc from '../types/IDmc';
import { bindActionCreators } from 'redux';
import actions from 'common/actions';
import { addMessage, removeMessage } from 'common/actions/globalMessages';
import IApplicationFilters from 'common/types/IApplicationFilters';
import selectors from 'common/selectors';
import { getTripCodeListByUser } from 'reports/actions/tripCodes';
import { getOperatorList } from 'reports/selectors/operators';
import { getSelectedOperator } from 'reports/selectors/selectedOperator';
import { setSelectedOperator } from 'reports/actions/selectedOperator';
import { getOperatorListByUser } from 'reports/actions/operators';
import { Operators } from 'reports/types/ICommon';

interface IDispatchProps {
  actions?: any;
}

interface IProps {
  dmcs: IDmc[];
  selectedDmcs: number[];
  operators: Operators
  selectedOperator: string;
}

type Props = IProps & IDispatchProps;

class ApplicationFiltersContainer extends React.Component<Props> {

  public async componentDidUpdate(prevState, prevProps): Promise<void> {
    if (prevState.selectedDmcs && (this.props.selectedDmcs[0] !== prevState.selectedDmcs[0])) {

      this.props.actions.getOperatorList();
    }

    if (prevState.selectedOperator !== this.props.selectedOperator) {
      this.props.actions.getTripCodes();
    }

  }

  public render() {
    const { selectedDmcs } = this.props;

    if (this.props.dmcs) {
      return (
        <>
          <ApplicationFilters
            dmcs={this.props.dmcs}
            selectedDmcs={selectedDmcs}
            initialApplicationFilters={this.initialFilters()}
            onDmcChange={this.props.actions.onDmcChange}
            addMessage={this.props.actions.addMessage}
            removeMessage={this.props.actions.removeMessage}
            operators={this.props.operators}
            selectedOperator={this.props.selectedOperator}
            onOperatorChange={this.props.actions.onOperatorChange}
          />
        </>

      );
    } else {
      return <div style={{ minHeight: '70px' }} />;
    }
  }

  public componentDidMount() {
    this.props.actions.getDmcs();
  }

  private initialFilters(): IApplicationFilters {
    return {
      dmcs: this.props.dmcs && this.props.dmcs.map(x => x.id)
    };
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDmcs: actions.dmcs.getByUser,
      onDmcChange: actions.selectedDmcs.setSelectedDMCs,
      addMessage: addMessage,
      removeMessage: removeMessage,
      getTripCodes: getTripCodeListByUser,
      getOperatorList: getOperatorListByUser,
      onOperatorChange: setSelectedOperator,
    },
    dispatch
  )
});

const mapStateToProps = state => ({
  dmcs: selectors.dmc.getUsersDmcs(state),
  selectedDmcs: selectors.selectedDmcs.getSelectedDmcs(state),
  operators: getOperatorList(state),
  selectedOperator: getSelectedOperator(state)
});

export default connect<IProps>(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationFiltersContainer);
