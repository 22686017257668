import * as actionTypes from '../../actionTypes/departureItinerary';
import IDepartureItinerary from '../../types/IDepartureItinerary';

type State = IDepartureItinerary | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.DEPARTURE_ITINERARY_GET_SUCCESS:
      return action.payload.selectedDepartureItinerary;
    default:
      return state;
  }
};
