import { AppState } from 'common/reducers/reducer';
import operationsPortalService from 'common/services/OperationsPortalService';
import * as actionTypes from '../../actionTypes/planningDepartures/selectedDeparturePax';
import * as selectors from '../../selectors/planningDepartures/selectedDeparture';
import IPax from 'departures/types/IPax';

function shouldFetch(state: AppState, departureId: number) {
  const stateDeparture = selectors.getSelectedDeparture(state);

  return !stateDeparture || stateDeparture.id !== departureId;
}

const paxListGetByDepartureRequest = (departureId: number) => ({
  type: actionTypes.PLANNING_DEPARTURE_PAX_LIST_GET_REQUEST,
  payload: { departureId: departureId }
});

const paxListGetSuccess = (selectedDeparturePax: IPax[]) => ({
  type: actionTypes.PLANNING_DEPARTURE_PAX_LIST_GET_SUCCESS,
  payload: { selectedDeparturePax }
});

const paxListGetFailure = () => ({
  type: actionTypes.PLANNING_DEPARTURE_PAX_LIST_GET_FAILURE
});

const paxClear = () => ({
  type: actionTypes.PLANNING_DEPARTURE_PAX_LIST_CLEAR
});

export const getPaxListByDeparture = (departureId: number) => async (
  dispatch,
  getState
) => {
  try {
    if (!shouldFetch(getState(), departureId)) {
      return null;
    }

    dispatch(paxClear());

    dispatch(paxListGetByDepartureRequest(departureId));

    const data = await operationsPortalService.Pax.getPlanningPaxListByDeparture(
      departureId
    );

    dispatch(paxListGetSuccess(data as IPax[]));
    return;
  } catch (e) {
    dispatch(paxListGetFailure());
    throw e;
  }
};
