import * as actionTypes from '../actionTypes/dmc';
import operationsPortalService from 'common/services/OperationsPortalService';
import IDmc from '../types/IDmc';
import { selectedDmcsSetRequest } from './selectedDMCs';
import { getSelectedDmcs } from '../../common/selectors/selectedDmcs';
import { getOperatorListByUser } from 'reports/actions/operators';

export const dmcGetByUserRequest = () => ({
  type: actionTypes.DMC_GET_REQUEST,
});

export const dmcGetSuccess = (dmcs) => ({
  type: actionTypes.DMC_GET_SUCCESS,
  payload: {
    dmcs,
  },
});

export const dmcGetByUserFailure = () => ({
  type: actionTypes.DMC_GET_FAILURE,
});

function shouldFetch(state) {
  const selectedDmc = getSelectedDmcs(state);

  return !selectedDmc;
}

export const getByUser = () => async (dispatch, getstate) => {
  try {
    dispatch(dmcGetByUserRequest());

    const data = (await operationsPortalService.Dmc.getByUser()) as IDmc[];

    dispatch(dmcGetSuccess(data));

    const defaultDmc: number[] = data && data.length > 0 ? [data[0].id] : [];
    if (shouldFetch(getstate())) {
      dispatch(selectedDmcsSetRequest(defaultDmc));
    }

    // Get the OperatorList from the Selected DMC.
    dispatch(getOperatorListByUser());
  } catch (e) {
    dispatch(dmcGetByUserFailure());
    throw e;
  }
};
