import * as React from 'react';
import Button from '@material-ui/core/Button';
import { Card, CardActions, CardHeader, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import styles from './ErrorMessage.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';

interface IProps extends WithStyles<typeof styles> {
  classes: Record<'textField', string> | any;
  code: string;
  name: string;
  message: string;
  actions?: any;
}

class ErrorMessage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { code, name, message, actions, classes } = this.props;

    return (
      <Card>
        <div>
          <CardHeader
            title={
              <Typography variant="h3" className={classes.errorCode}>
                {code}
                <Typography variant="h3" className={classes.errorName}>
                  {name}
                </Typography>
              </Typography>
            }
          />
          <CardContent className={classes.cardContent}>
            <Typography>{message}</Typography>
          </CardContent>
          {actions && (
            <div>
              <Divider />
              <CardActions>
                {actions.map(v => (
                  <Button size="small" color="primary" href={v.href}>
                    {v.name}
                  </Button>
                ))}
              </CardActions>
            </div>
          )}
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(ErrorMessage);
