import * as React from 'react';
import { connect } from 'react-redux';
import selectors from '../selectors';
import IPlanningTransfer from '../types/IPlanningTransfer';
import PlanningTransferSearchContainer from './PlanningTransferSearchContainer';
import PlanningTransferList from './PlanningTransferList';
import IPlanningTransferSearchCriteria from 'common/types/IPlanningTransferSearchCriteria';

interface IDispatchProps {
  actions?: any;
}

interface IProps {
  transfers: IPlanningTransfer[];
}

type Props = IProps & IDispatchProps;

class PlanningTransferContainer extends React.Component<Props> {
  public render() {
    return (
      <React.Fragment>
        <PlanningTransferSearchContainer
          initialSearchCriteria={initialSearchCriteria}
        />
        <PlanningTransferList transfers={this.props.transfers} />
      </React.Fragment>
    );
  }
}

const initialSearchCriteria: IPlanningTransferSearchCriteria = {
  statuses: [],
  transferDateFrom: new Date(),
  transferDateTo: null
};

const mapStateToProps = state => ({
  transfers: selectors.planningTransfers.getTransfers(state)
});

export default connect<IProps>(mapStateToProps)(PlanningTransferContainer);
