import styles from './TripReportDialog.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CustomButton from 'common/components/CustomButton';

import IDeparture from '../types/IDeparture';
import ICrew from '../types/ICrew';
import CrewTripReportStatus from './CrewTripReportStatus';

interface IState {
  tripReportDialogOpen: boolean;
  selectedDepartureCrew: ICrew[];
}

interface IProps extends WithStyles<typeof styles> {
  departure: IDeparture;
  selectedDepartureCrew?: ICrew[];
  open: boolean;
  showLoading?: any;
  hideLoading?: any;
  getAndDisplayFile?: any;
  close: () => any;
  actions: any;
}

class TripReportDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      tripReportDialogOpen: false,
      selectedDepartureCrew: []
    };
  }

  public openTripReportDialog = event => {
    this.props.actions.getCrew(this.props.departure!.id).then(crewMembers => {
      this.handleGetCrew();
    });
  };

  public handleGetCrew() {
    this.setState({
      tripReportDialogOpen: true,
      selectedDepartureCrew: this.props.selectedDepartureCrew!
    });
  }

  public closeTripReportDialog = () => {
    this.setState({
      tripReportDialogOpen: false
    });
  };

  public render() {
    const { departure } = this.props;

    const leaders = departure.crew.filter(m => m.role === 'LEADER');

    const selectedDepartureCrew = this.state.selectedDepartureCrew.filter(
      m => m.role === 'LEADER'
    );

    const title = 'Trip Report - ' + departure!.code;

    return (
      <div>
        <CustomButton
          onClick={this.openTripReportDialog}
          id={'btn.tripReport_' + departure!.code}
          label="Trip Report"
          disabled={leaders.length <= 0}
          fontIcon="assignment"
        />
        <Dialog
          open={this.state.tripReportDialogOpen}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <CrewTripReportStatus
              departure={departure}
              selectedDepartureCrew={selectedDepartureCrew}
              showLoading={this.props.showLoading}
              hideLoading={this.props.hideLoading}
              getAndDisplayFile={this.props.getAndDisplayFile}
              actions={this.props.actions}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeTripReportDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(TripReportDialog);
