import { store } from 'common/store';
import { getSelectedOperator } from 'reports/selectors/selectedOperator';
import planningPortalService from 'reports/services/PlanningPortalServices';
import { TripCodes } from 'reports/types/ICommon';
import * as actionTypes from '../actionTypes/tripCodes';

export const tripCodeListSetRequest = (tripCodeList: TripCodes) => ({
  type: actionTypes.GET_TRIP_CODES,
  payload: {
    tripCodeList,
  },
});

export const getTripCodeListByUser = () => async (dispatch) => {
  try {
    const selectedOperator = getSelectedOperator(store.getState());

    const data = await planningPortalService.DepartureStatus.getTripCodes(
      selectedOperator
    );
    dispatch(
      tripCodeListSetRequest(
        data.map((value) => ({ value: value, text: value }))
      )
    );
  } catch (e) {
    throw e;
  }
};
