import { createStyles } from '@material-ui/core/styles';
import { messageTypes } from '../actions/globalMessages';

export default createStyles({
  [messageTypes.success]: {
    color: '#2eea2e'
  },
  [messageTypes.info]: {
    color: 'white'
  },
  [messageTypes.error]: {
    color: '#f44336'
  },
  messageContainer: {
    display: 'flex'
  },
  message: {
    flexGrow: 1,
    padding: '0 10px',
    lineHeight: 2
  }
});