import * as actionTypes from '../actionTypes/planningProducts';
import IPlanningProduct from '../types/IPlanningProduct';

type State = IPlanningProduct[] | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.PLANNING_PRODUCTS_GET_SUCCESS:
      return action.payload.products;
    default:
      return state;
  }
};
