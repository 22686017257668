import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  appBarContainer: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1
  },
  logOffButton: {
    height: '55px'
  },
  logOffButtonIcon: {
    fontSize: '30px'
  },
  titleButton: {
    textTransform: 'none',
    backgroundColor: 'transparent !important'
  },
  divider : {
    display: "inline" , 
    border : '2px solid white',
    margin : '0 5px'
  }
});
