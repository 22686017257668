import * as React from 'react';
import Card from '@material-ui/core/Card';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateRangeFilter from 'common/forms/DateRangeFilter';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CustomSelectField from '../../common/components/CustomSelectField';
import CustomTextField from '../../common/components/CustomTextField';
import IDepartureSearchCriteria from 'common/types/IDepartureSearchCriteria';
import styles from './DepartureSearch.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { addWeeks } from 'date-fns';
import avoidEnterOnKeyPress from '../../common/helpers/avoidEnterOnKeyPress';
import tripTypes from 'departures/enums/tripTypes';
import { Checkbox } from '@material-ui/core';

interface IState {
  searchCriteria: IDepartureSearchCriteria;
}

interface IProps extends WithStyles<typeof styles> {
  searchCriteria: IDepartureSearchCriteria;
  selectedDmcs: number[];
  actions: any;
  isOperationsDashboardCovidSurveyEnabled: boolean;
}

class DepartureSearch extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const classes = this.props.classes;

    const brands: Array<{ value: string; text: string }> = [
      { value: 'Peregrine', text: 'Peregrine' },
      { value: 'Intrepid', text: 'Intrepid' },
      { value: 'Other', text: 'Other' }
    ];

    const microRegions: Array<{ value: string; text: string }> = [
      { value: 'Eastern_Africa', text: 'Eastern Africa' },
      { value: 'Middle_Africa', text: 'Middle Africa' },
      { value: 'Northern_Africa', text: 'Northern Africa' },
      { value: 'Southern_Africa', text: 'Southern Africa' },
      { value: 'Western_Africa', text: 'Western Africa' },
      { value: 'Caribbean', text: 'Caribbean' },
      { value: 'Central_America', text: 'Central America' },
      { value: 'South_America', text: 'South America' },
      { value: 'Northern_America', text: 'Northern America' },
      { value: 'Central_Asia', text: 'Central Asia' },
      { value: 'Eastern_Asia', text: 'Eastern Asia' },
      { value: 'Southern_Asia', text: 'Southern Asia' },
      { value: 'South_Eastern_Asia', text: 'South-Eastern Asia' },
      { value: 'Western_Asia', text: 'Western Asia' },
      { value: 'Eastern_Europe', text: 'Eastern Europe' },
      { value: 'Northern_Europe', text: 'Northern Europe' },
      { value: 'Southern_Europe', text: 'Southern Europe' },
      { value: 'Western_Europe', text: 'Western Europe' },
      { value: 'Australia_and_New_Zealand', text: 'Australia and New Zealand' },
      { value: 'Melanesia', text: 'Melanesia' },
      { value: 'Micronesia', text: 'Micronesia' },
      { value: 'Polynesia', text: 'Polynesia' },
      { value: 'Antarctica', text: 'Antarctica' },
      { value: 'Arctic', text: 'Arctic' }
    ];

    const outstandingAdminWithCovidSurveyOption: Array<{ value: string; text: string }> = [
      { value: 'CrewCovidSurvey', text: 'Crew Covid Survey' },
      { value: 'PaxCovidSurvey', text: 'Pax Covid Survey' },
      { value: 'PaxCovidHealthScreening', text: 'Pax Covid Health Screening' },
      { value: 'PaxConfirmed', text: 'Pax Confirmed' },
      { value: 'EmergencyInformation', text: 'Emergency Information' },
      { value: 'TripReport', text: 'Trip Report' }
    ];

    const outstandingAdmin: Array<{ value: string; text: string }> = [
      { value: 'PaxConfirmed', text: 'Pax Confirmed' },
      { value: 'EmergencyInformation', text: 'Emergency Information' },
      { value: 'TripReport', text: 'Trip Report' }
    ];

    const onSubmit = async values => {
      try {
        const currentDate = new Date();

        const cleansedSearchCriteria: IDepartureSearchCriteria = {
          tripTypes: values.tripTypes.length > 0 ? values.tripTypes : [],
          departureCode: values.departureCode.trim(),
          leaderFullName: values.leaderFullName.trim(),
          brands: values.brands,
          microRegions: values.microRegions,
          outstandingAdmin: values.outstandingAdmin,
          startDateFrom:
            values.dateFilterType === 'Current_Departures'
              ? currentDate
              : values.startDateFrom,
          startDateTo:
            values.dateFilterType === 'Current_Departures'
              ? addWeeks(currentDate, 1)
              : values.startDateTo,
          dateFilterType: values.dateFilterType,
          anyPaxDeclarationAlerts: values.anyPaxDeclarationAlerts,
          anyCrewDeclarationAlerts: values.anyCrewDeclarationAlerts,
          anyPaxCovidHealthScreeningAlerts: values.anyPaxCovidHealthScreeningAlerts
        };

        await this.props.actions.setSearchCriteria(cleansedSearchCriteria);
        await this.props.actions.getDepartures(cleansedSearchCriteria);
      } catch (e) {
        throw e;
      }
    };
    return (
      <Card>
        <CardContent>
          <Form
            onSubmit={onSubmit}
            initialValues={this.props.searchCriteria}
            render={({
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
              errors
            }) => (
                <form
                  onSubmit={handleSubmit}
                  noValidate={true}
                  onKeyPress={avoidEnterOnKeyPress}
                >
                  <div className={classes.searchContainer}>
                    <div className={classes.dateFilterContainer}>
                      <div>
                        <Field name={'dateFilterType'} allowNull={true}>
                          {({ input, meta }) => {
                            const { value, onChange, ...restInput } = input;

                            return (
                              <RadioGroup
                                aria-label="Gender"
                                id="dateFiltering"
                                value={value}
                                onChange={onChange}
                                {...restInput}
                              >
                                <FormControlLabel
                                  value="Current_Departures"
                                  control={<Radio />}
                                  label="All Current Departures"
                                />
                                <FormControlLabel
                                  value="Departure_Start_Dates"
                                  control={<Radio />}
                                  label="Departure Start Dates"
                                />
                              </RadioGroup>
                            );
                          }}
                        </Field>
                      </div>

                      <DateRangeFilter
                        id="startDate"
                        isDisabled={
                          values.dateFilterType === 'Current_Departures'
                        }
                      />
                    </div>
                    <div className={classes.otherFilterContainer}>
                      <div className={classes.inputsContainer}>
                        <Field
                          name={'departureCode'}
                          // Allow empty strings in the form
                          parse={value => {
                            return !value ? '' : value;
                          }}
                        >
                          {({ input, meta }) => {
                            const { value, onChange } = input;

                            return (
                              <CustomTextField
                                label="Departure Code"
                                value={value}
                                onChange={onChange}
                                clearable={true}
                                onClear={onChange}
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className={classes.inputsContainer}>
                        <Field
                          name={'leaderFullName'}
                          // Allow empty strings in the form
                          parse={value => {
                            return !value ? '' : value;
                          }}
                        >
                          {({ input, meta }) => {
                            const { value, onChange } = input;

                            return (
                              <CustomTextField
                                label="Leader Name"
                                value={value}
                                onChange={onChange}
                                clearable={true}
                                onClear={onChange}
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className={classes.inputsContainer}>
                        <Field name={'tripTypes'} allowNull={true}>
                          {({ input, meta }) => {
                            const { value, onChange } = input;

                            return (
                              <CustomSelectField
                                label="Trip Type"
                                value={value}
                                onChange={onChange}
                                options={tripTypes}
                                clearable={true}
                                onClear={onChange}
                                aggregateMultiSelectionsOnDisplay={true}
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className={classes.inputsContainer}>
                        <Field name={'brands'} allowNull={true}>
                          {({ input, meta }) => {
                            const { value, onChange } = input;

                            return (
                              <CustomSelectField
                                label="Brand"
                                value={value}
                                onChange={onChange}
                                options={brands}
                                clearable={true}
                                onClear={onChange}
                                aggregateMultiSelectionsOnDisplay={true}
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className={classes.inputsContainer}>
                        <Field name={'microRegions'} allowNull={true}>
                          {({ input, meta }) => {
                            const { value, onChange } = input;

                            return (
                              <CustomSelectField
                                label="Micro Region"
                                value={value}
                                onChange={onChange}
                                options={microRegions}
                                clearable={true}
                                onClear={onChange}
                                aggregateMultiSelectionsOnDisplay={true}
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className={classes.inputsContainer}>
                        <Field name={'outstandingAdmin'} allowNull={true}>
                          {({ input, meta }) => {
                            const { value, onChange } = input;

                            return (
                              <CustomSelectField
                                label="Outstanding Admin"
                                value={value}
                                onChange={onChange}
                                options={this.props.isOperationsDashboardCovidSurveyEnabled ? outstandingAdminWithCovidSurveyOption : outstandingAdmin}
                                clearable={true}
                                onClear={onChange}
                                aggregateMultiSelectionsOnDisplay={true}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      {this.props.isOperationsDashboardCovidSurveyEnabled && (
                        <div className={classes.inputsContainer}>
                          <Field name={'anyCrewDeclarationAlerts'} allowNull={true}>
                            {({ input, meta }) => {
                              const { value, onChange, ...restInput } = input;

                              return (
                                <div className={classes.checkboxContainer}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        id="anyCrewDeclarationAlerts"
                                        value={value}
                                        onChange={onChange}
                                        {...restInput}
                                      />
                                    }
                                    label="Crew Covid Survey Alerts"
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </div>
                      )}
                      {this.props.isOperationsDashboardCovidSurveyEnabled && (
                        <div className={classes.inputsContainer}>
                          <Field name={'anyPaxDeclarationAlerts'} allowNull={true}>
                            {({ input, meta }) => {
                              const { value, onChange, ...restInput } = input;

                              return (
                                <div className={classes.checkboxContainer}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        id="anyPaxDeclarationAlerts"
                                        value={value}
                                        onChange={onChange}
                                        {...restInput}
                                      />
                                    }
                                    label="Pax Covid Survey Alerts"
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </div>
                      )}

                      {this.props.isOperationsDashboardCovidSurveyEnabled && (
                        <div className={classes.inputsContainer}>
                          <Field name={'anyPaxCovidHealthScreeningAlerts'} allowNull={true}>
                            {({ input, meta }) => {
                              const { value, onChange, ...restInput } = input;

                              return (
                                <div className={classes.checkboxContainer}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        id="anyPaxCovidHealthScreeningAlerts"
                                        value={value}
                                        onChange={onChange}
                                        {...restInput}
                                      />
                                    }
                                    label="Pax Covid Health Screening Alerts"
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.actionButtonsContainer}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      id="btn.search"
                      disabled={submitting}
                    >
                      Search
                  </Button>
                  </div>
                </form>
              )}
          />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(DepartureSearch);
