import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import KeyValueItem from '../../common/components/KeyValueItem';
import { withStyles } from '@material-ui/core/styles';
import { sortBy, groupBy } from 'lodash';
import styles from './DepartureAccommodation.styles';
import formatDate from 'common/helpers/formatDate';

const DepartureAccommodation = ({ accommodations, classes }) => {
  // Add Booking to accommodations for grouping
  const accommodationsExtended = accommodations.map(item => {
    const bookingId = {
      bookingId: item.bookingId
    };
    return { ...item, ...bookingId };
  });

  const sorterAccomodations = sortBy(accommodationsExtended, val => {
    return `${val.hotelName.trim().toLowerCase()}`;
  });

  const accommodationGroups = groupBy(sorterAccomodations, val => {
    return `${val.hotelName.trim().toLowerCase()}`;
  });

  return (
    <List className={classes.root}>
      {Object.keys(accommodationGroups).map(accommodationGroupKey => {
        const accommodationListItems: any[] = [];
        const accommodations = sortBy(
          accommodationGroups[accommodationGroupKey],
          'checkInDate'
        );

        const checkInDates = groupBy(
          accommodations,
          'checkInDate',
          'checkOutDate',
          'bookingId',
          'roomTypeName'
        );

        Object.keys(checkInDates).map(checkInDateKey => {
          const accommsByCheckInDate = checkInDates[checkInDateKey];

          const checkOutDates = groupBy(
            accommsByCheckInDate,
            'checkOutDate',
            'bookingId',
            'roomTypeName'
          );

          Object.keys(checkOutDates).map(checkOutDateKey => {
            const accommsByCheckOutDate = checkOutDates[checkOutDateKey];

            const bookingNumbers = groupBy(
              accommsByCheckOutDate,
              'bookingId',
              'roomTypeName'
            );

            Object.keys(bookingNumbers).map(bookingNumberKey => {
              const accommsByBookingNumber = bookingNumbers[bookingNumberKey];
              const roomTypes = groupBy(accommsByBookingNumber, 'roomTypeName');

              accommodationListItems.push(
                Object.keys(roomTypes).map(roomTypeKey => {
                  const accomms = roomTypes[roomTypeKey];
                  return GetListItem(accomms, accommodationGroupKey, classes);
                })
              );
            });
          });
        });

        return (
          <div key={accommodationGroupKey}>
            <ListSubheader
              disableSticky={true}
              id={
                'pax.accommodation.accommodationGroupKey_' +
                accommodationGroupKey
              }
              className={classes.subHeader}
            >
              {accommodations[0].hotelName}
            </ListSubheader>
            {accommodationListItems}
            <Divider />
          </div>
        );
      })}
    </List>
  );
};

function GetListItem(accommodations, accommodationGroupKey, classes) {
  const names: any[] = [];
  accommodations.map(accommodation => {
    const fullName = accommodation.fullNameWithTitle;

    names.push(
      <Typography
        variant="body2"
        id={
          'pax.accommodation.fullName_' +
          accommodation.paxId +
          '_' +
          accommodationGroupKey
        }
        className={classes.paxName}
      >
        {fullName}
      </Typography>
    );
  });

  const accommodation = accommodations[0];
  const checkInDate = formatDate(accommodation.checkInDate);
  const checkOutDate = formatDate(accommodation.checkOutDate);

  return (
    <div key={`${accommodation.paxId}${accommodation.hotelName}`}>
      <ListItem>
        <ListItemText
          disableTypography={true}
          primary={
            <span>
              {names.reduce((prev, curr) => [
                <span key={'names'}>
                  {prev}
                  {curr}
                </span>
              ])}
            </span>
          }
          secondary={
            <div className={classes.secondaryContainer}>
              <Grid container spacing={0}>
                <Grid item xs={2} sm={2}>
                  <KeyValueItem
                    label="Check In"
                    value={checkInDate}
                    valueId={
                      'pax.accommodation.checkIn_' +
                      accommodation.paxId +
                      '_' +
                      accommodationGroupKey
                    }
                  />
                </Grid>
                <Grid item xs={10} sm={10}>
                  <KeyValueItem
                    label="Check Out"
                    value={checkOutDate}
                    valueId={
                      'pax.accommodation.checkOut_' +
                      accommodation.paxId +
                      '_' +
                      accommodationGroupKey
                    }
                  />
                </Grid>
                <Grid item xs={2} sm={2}>
                  <KeyValueItem
                    label="Room Type"
                    value={accommodation.roomTypeName}
                    valueId={
                      'pax.accommodation.roomType_' +
                      accommodation.paxId +
                      '_' +
                      accommodationGroupKey
                    }
                  />
                </Grid>
                <Grid item xs={10} sm={10}>
                  <KeyValueItem
                    label="Booking Number"
                    value={accommodation.bookingId}
                    valueId={
                      'pax.accommodation.bookingId' +
                      accommodation.paxId +
                      '_' +
                      accommodationGroupKey
                    }
                  />
                </Grid>
              </Grid>
            </div>
          }
        />
      </ListItem>
    </div>
  );
}

export default withStyles(styles)(DepartureAccommodation);
