import ApiError from 'common/errors/ApiError';

class NetworkError extends ApiError {
  constructor(message?: string, ...params: any) {
    const calculatedMessage =
      message === undefined ? 'Unable to connect to server' : message;
    super('Network Error', calculatedMessage, params, new Error().stack);
  }
}

export default NetworkError;