import IDepartureManifestCriteria from 'reports/types/IDepartureManifestCriteria';

import { downloadReport } from 'reports/utils/downloadReport';

export default class DepartureManifest {
  public service: any;

  constructor(service) {
    this.service = service;
  }

  public async getDepartureManifestReport(
    departureManifestData: IDepartureManifestCriteria,
    fileType: string
  ): Promise<boolean> {
    let downloadable = false;
    const resourcePath = '/departuremanifestreport';

    const data = await this.service.post(resourcePath, departureManifestData);
    if (data.body !== '') {
      const fileName = 'Departure Manifest_' + departureManifestData.departureCode;
      downloadReport(data.body, fileName, fileType);
      downloadable = true;
    }

    return downloadable;
  }
}
