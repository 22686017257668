import * as actionTypes from '../../actionTypes/departureSightseeing';
import IDepartureSightseeing from '../../types/IDepartureSightSeeing';

type State = IDepartureSightseeing | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.DEPARTURE_SIGHTSEEING_GET_SUCCESS:
      return action.payload.selectedDepartureSightSeeing;
    default:
      return state;
  }
};
