import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './NoResultsMessage.styles';

const NoResultsMessage = ({ iconType = 'info', message, classes }) => (
  <Paper className={classes.paper} elevation={2}>
    <div className={classes.container}>
      <div className={classes.content}>
        <Icon className={classes.icon}>{iconType}</Icon>
      </div>
      <div className={classes.content}>
        <Typography align="left" variant="body2">
          {message}
        </Typography>
      </div>
    </div>
  </Paper>
);

export default withStyles(styles)(NoResultsMessage);
