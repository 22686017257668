import { getDepartureById } from './selectedDeparture';
import { getPaxListByDeparture } from './selectedDeparturePax';
import { getDeparturesBySearchCriteria, setSearchCriteria } from './search';

export default {
  getDepartureById,
  getPaxListByDeparture,
  getDeparturesBySearchCriteria,
  setSearchCriteria
};
