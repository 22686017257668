import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  actionButtonsContainer: {
    textAlign: 'right',
    paddingLeft: '30px'
  },
  searchContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  inputsContainer: {
    paddingLeft: '15px',
    paddingRight: '15px',
    width: '200px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  select: {
    background: 'white',
    padding: '0 5px',
    width: '210px'
  },
  stickyContent: {
    top: '1px',
    position: 'sticky',
    width: '100%',
    zIndex: 10
  }
});
