import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  root: {
    width: '100%',
    overflowX: 'scroll'
  },
  table: {
    width: '100%'
  },
  headerCol: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.85rem",
    padding: "10px"

  },
  tableCol: {
    fontSize: "0.75rem",
    padding: "10px"
  },
});