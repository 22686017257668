import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LoadingBar from 'react-redux-loading-bar';
import styles from './ProgressIndicator.styles';

const ProgressIndicator = ({ classes }) => {
  return (
    <div className={classes.container}>
      <LoadingBar
        className={classes.bar}
        updateTime={30}
        maxProgress={100}
        progressIncrease={10}
      />
    </div>
  );
};

export default withStyles(styles)(ProgressIndicator);
