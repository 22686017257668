import * as React from 'react';
import ErrorMessage from './ErrorMessage';
import createLink from '../../common/helpers/createLink';
import { ROUTE_DASHBOARD } from 'common/constants/routes';

const Forbidden403 = () => (
  <ErrorMessage
    code="403"
    name="Access Denied"
    message="You do not have access to the requested resource."
    actions={[{ name: 'Go to Dashboard', href: createLink(ROUTE_DASHBOARD) }]}
  />
);

export default Forbidden403;
