import * as React from 'react';
import { connect } from 'react-redux';
import selectors from '../selectors/planningDepartures';
import IPlanningDeparture from '../types/IPlanningDeparture';
import PlanningDepartureSearchContainer from './PlanningDepartureSearchContainer';
import PlanningDepartureList from './PlanningDepartureList';

interface IDispatchProps {
  actions?: any;
}

interface IProps {
  departures: IPlanningDeparture[];
}

type Props = IProps & IDispatchProps;

class PlanningDepartureContainer extends React.Component<Props> {
  public render() {
    return (
      <React.Fragment>
        <PlanningDepartureSearchContainer />
        <PlanningDepartureList departures={this.props.departures} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  departures: selectors.searchResults.getSearchResults(state)
});

export default connect<IProps>(mapStateToProps)(PlanningDepartureContainer);
