import * as actionTypes from '../../actionTypes/pax';
import IPax from '../../types/IPax';

type State = IPax[] | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.PAX_LIST_GET_SUCCESS:
      return action.payload.selectedDeparturePax;
    case actionTypes.PAX_LIST_CLEAR:
      return null;
    default:
      return state;
  }
};
