import * as React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { isDate } from 'date-fns';
import { sortBy } from 'lodash';
import KeyValueItem from 'common/components/KeyValueItem';
import styles from './DeparturePaxChanges.styles';
import Paper from '@material-ui/core/Paper';
import CopyStringToClipboard from '../../common/components/CopyStringToClipboard';
import formatDate from 'common/helpers/formatDate';

const labelMappings = {
  'Passport.No': 'Passport - Number',
  'Passport.DateOfExpiry': 'Passport - Passport Expiry'
};

const getLabel = propertyName => {
  return (
    labelMappings[propertyName] ||
    propertyName
      .split('.')
      .map(v => v.replace(/([A-Z])/g, ' $1'))
      .join(' - ')
  );
};

const renderChange = (change, paxId, classes) => {
  return (
    <KeyValueItem
      key={paxId + '-' + change.propertyName}
      label={getLabel(change.propertyName)}
      value={
        <Grid container component="span" spacing={0}>
          <Grid
            component="span"
            item
            xs={5}
            sm={5}
            className={change.values.old ? classes.oldValue : null}
          >
            {change.values.old ? formatValue(change.values.old) : '—'}
          </Grid>
          <Grid component="span" item xs={2} sm={2}>
            →
          </Grid>
          <Grid component="span" item xs={5} sm={5}>
            {formatValue(change.values.new)}
          </Grid>
        </Grid>
      }
      valueId={'pax.changes.' + change.propertyName + '_' + paxId}
    />
  );
};

const renderChanges = (paxChange, paxList, classes) => {
  const changes = paxChange.changes.map((change, i) =>
    renderChange(change, paxChange.id, classes)
  );

  const pax = paxList.find(x => x.id === paxChange.paxId);

  return (
    <ListItem key={paxChange.id}>
      <ListItemText
        primary={
          <span>
            <Typography
              variant="body2"
              className={classes.paxName}
              id={'pax.changes.fullNameAndBookingId_' + paxChange.id}
            >{`${pax.fullNameWithTitle} - ${pax.bookingId}`}</Typography>
          </span>
        }
        secondary={changes}
        disableTypography
      />
    </ListItem>
  );
};

const formatValue = value => {
  return isDate(value) ? formatDate(value) : value;
};

const formatChangesForCopy = (paxChanges, paxList) => {
  const getPaxHeader = pax => {
    return `${pax.fullNameWithTitle} - ${pax.bookingId}\n`;
  };

  const changesFormatted = paxChanges.map(paxChange => {
    const pax = paxList.find(x => x.id === paxChange.paxId);
    const paxHeader = getPaxHeader(pax);

    const changes = paxChange.changes.map((change, i) => {
      const propertyHeader = getLabel(change.propertyName);

      const oldValue = change.values.old ? formatValue(change.values.old) : '—';
      const newValue = formatValue(change.values.new);

      return `\t${propertyHeader}\n\t\t ${oldValue} --> ${newValue}`;
    });

    return `${paxHeader} ${changes.join('\n')}\n\n`;
  });

  return changesFormatted.join('');
};

const PaxChanges = ({
  paxChanges,
  paxList,
  showPaxChangesCopiedMessage,
  classes
}) => {
  let paxChangesExtended = paxChanges.map(item => {
    const bookingId = {
      bookingId: paxList.find(x => x.id === item.paxId).bookingId
    };
    return { ...item, ...bookingId };
  });

  paxChangesExtended = sortBy(paxChangesExtended, [
    { bookingId: 'asc' },
    'surname',
    'firstName'
  ]);

  return (
    <React.Fragment>
      <Paper>
        <div className={classes.actionsSection}>
          <CopyStringToClipboard
            stringToCopy={formatChangesForCopy(paxChanges, paxList)}
            showConfirmationMessage={showPaxChangesCopiedMessage}
          >
            <Button variant="contained" color="primary" id="btn.copy">
              COPY
            </Button>
          </CopyStringToClipboard>
        </div>
        <List className={classes.root}>
          {paxChangesExtended.map(paxChange =>
            renderChanges(paxChange, paxList, classes)
          )}
        </List>
      </Paper>
    </React.Fragment>
  );
};

export default withStyles(styles)(PaxChanges);
