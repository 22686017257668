import { Operators } from 'reports/types/ICommon';
import * as actionTypes from '../actionTypes/operators';

type State = Operators | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.GET_OPERATOR_LIST:
      return action.payload.operatorList;
    default:
      return state;
  }
};
