import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import AvatarLogo from 'common/components/AvatarLogo';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import PlanningDepartureGeneralDetails from './PlanningDepartureGeneralDetails';
import IPlanningDeparture from 'planning/types/IPlanningDeparture';
import PlanningDepartureExtendedDetails from './PlanningDepartureExtendedDetails';

interface IProps {
  departure: IPlanningDeparture;
}

class PlanningDepartureCard extends React.Component<IProps> {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  public render() {
    const { departure } = this.props;

    return (
      departure && (
        <React.Fragment>
          <Paper>
            <CardHeader
              avatar={
                <AvatarLogo
                  brand={departure.brand.name}
                  imgId={'img.header_' + departure.code}
                />
              }
              title={
                <Typography variant="body1" color="inherit">
                  <span>{`${departure.code} - ${departure.product.name}`}</span>
                </Typography>
              }
              subheader={<PlanningDepartureGeneralDetails departure={departure} />}
            />
            <hr />
            <PlanningDepartureExtendedDetails departure={departure} />
          </Paper>
        </React.Fragment>
      )
    );
  }


}

export default PlanningDepartureCard;
