import * as actionTypes from '../actionTypes/planningAccommodation';
import IPlanningAccommodation from '../types/IPlanningAccommodation';

type State = IPlanningAccommodation[] | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.PLANNING_ACCOMMODATION_GET_SUCCESS:
      return action.payload.accommodation;
    default:
      return state;
  }
};
