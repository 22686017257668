import * as actionTypes from '../actionTypes/crew';
import ICrew from '../types/ICrew';
import operationsPortalService from 'common/services/OperationsPortalService';

export const crewGetByDepartureRequest = departureId => ({
  type: actionTypes.CREW_GET_REQUEST,
  payload: {
    departureId
  }
});

export const crewGetSuccess = selectedDepartureCrew => ({
  type: actionTypes.CREW_GET_SUCCESS,
  payload: {
    selectedDepartureCrew
  }
});

export const crewGetByDepartureFailure = () => ({
  type: actionTypes.CREW_GET_FAILURE
});

export const getByDeparture = (departureId: number) => async dispatch => {
  try {
    dispatch(crewGetByDepartureRequest(departureId));
    const data = await operationsPortalService.Crew.getByDeparture(departureId);

    dispatch(crewGetSuccess(data as ICrew[]));
  } catch (e) {
    dispatch(crewGetByDepartureFailure());
    throw e;
  }
};
