import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
  },
});
