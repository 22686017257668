import * as actionTypes from '../actionTypes/planningProducts';
import operationsPortalService from 'common/services/OperationsPortalService';
import IPlanningProduct from 'planning/types/IPlanningProduct';

export const productsGetRequest = () => ({
  type: actionTypes.PLANNING_PRODUCTS_GET_REQUEST
});

export const productsGetSuccess = (products: IPlanningProduct) => ({
  type: actionTypes.PLANNING_PRODUCTS_GET_SUCCESS,
  payload: {
    products: products
  }
});

export const productsGetFailure = () => ({
  type: actionTypes.PLANNING_PRODUCTS_GET_FAILURE
});

export const getProducts = () => async dispatch => {
  try {
    dispatch(productsGetRequest());
    const data = await operationsPortalService.PlanningProducts.get();

    dispatch(productsGetSuccess(data));
  } catch (e) {
    dispatch(productsGetFailure());
    throw e;
  }
};
