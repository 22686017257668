export default class Pax {
  public service: any;
  public baseResourcePath: string;

  constructor(service) {
    this.service = service;
  }

  public async getPaxListByDeparture(departureId: number) {
    const resourcePath = `/departure/${encodeURIComponent(
      departureId.toString()
    )}/pax`;

    const data = await this.service.get(resourcePath);
    return data.body;
  }

  public async getPlanningPaxListByDeparture(departureId: number) {
    const resourcePath = `/planning/departure/${encodeURIComponent(
      departureId.toString()
    )}/pax`;

    const data = await this.service.get(resourcePath);
    return data.body;
  }
}
