import * as actionTypes from '../../actionTypes/departureHealthScreening';
import IHealthScreeningResponses from '../../types/IHealthScreeningResponses';

type State = IHealthScreeningResponses | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.HEALTH_SCREENING_RESULTS_GET_SUCCESS:
      return action.payload.departureHealthScreening;
    default:
      return state;
  }
};
