import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import KeyValueItem from '../../common/components/KeyValueItem';
import { withStyles } from '@material-ui/core/styles';
import { sortBy, groupBy } from 'lodash';
import styles from './DepartureSightseeing.styles';
import Grid from '@material-ui/core/Grid';
import formatDate from 'common/helpers/formatDate';

const getFormattedDate = date => {
  return date !== null ? formatDate(date) : null;
};

const DepartureSightseeing = ({ sightseeings, classes }) => {
  const sortedSightseeings = sortBy(sightseeings, ['serviceName', 'startDate']);
  const sightseeingGroups = groupBy(sortedSightseeings, 'serviceName');

  return (
    <List className={classes.root}>
      {Object.keys(sightseeingGroups).map(sightseeingGroupKey => {
        const sortedSightseeings = sightseeingGroups[sightseeingGroupKey];

        const sortedSightseeingsDates = groupBy(
          sortedSightseeings,
          'startDate'
        );

        const sightseeingListItems: JSX.Element[] = Object.keys(
          sortedSightseeingsDates
        ).map(sortedSightseeingsDatesKey => {
          const sortedSightseeingsDate =
            sortedSightseeingsDates[sortedSightseeingsDatesKey];

          return getListItems(
            sortedSightseeingsDate,
            sightseeingGroupKey,
            sortedSightseeingsDatesKey,
            classes
          );
        });

        return (
          <div key={sightseeingGroupKey}>
            <ListSubheader
              disableSticky={true}
              id={'pax.sightseeing.sightseeingGroupKey_' + sightseeingGroupKey}
              className={classes.subHeader}
            >
              {sightseeingGroupKey}
            </ListSubheader>
            {sightseeingListItems}
            <Divider />
          </div>
        );
      })}
    </List>
  );
};

function getListItems(
  sortedSightseeingsDate,
  sightseeingGroupKey,
  sortedSightseeingsDatesKey,
  classes
) {
  const names = sortedSightseeingsDate.map((sightseeing, index) =>
    getName(sightseeing, index, classes)
  );
  const sightseeing = sortedSightseeingsDate[0];

  return (
    <div key={`${sightseeing.paxId}`}>
      <ListItem>
        <ListItemText
          disableTypography={true}
          primary={
            <span>
              {names.reduce((prev, curr) => [
                <span key={'names'}>
                  {prev}
                  {curr}
                </span>
              ])}
            </span>
          }
          secondary={
            <div className={classes.secondaryContainer}>
              <Grid container spacing={0}>
                <Grid item xs={6} sm={6}>
                  <KeyValueItem
                    label="From"
                    value={getFormattedDate(
                      new Date(sortedSightseeingsDatesKey)
                    )}
                    valueId={
                      'pax.sightseeing.from_' +
                      sightseeing.paxId +
                      '_' +
                      sightseeingGroupKey
                    }
                  />
                </Grid>
              </Grid>
            </div>
          }
        />
      </ListItem>
    </div>
  );
}

function getName(sightseeing, sightseeingGroupKey, classes) {
  const fullName = sightseeing.fullNameWithTitle;

  return (
    <Typography
      variant="body2"
      id={
        'pax.sightseeing.fullName_' +
        sightseeing.paxId +
        '_' +
        sightseeingGroupKey
      }
      className={classes.paxName}
    >{`${fullName}`}</Typography>
  );
}

export default withStyles(styles)(DepartureSightseeing);
