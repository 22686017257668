import styles from './TripReportDialog.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import ICrew from '../types/ICrew';
import { fileUploadStatus } from 'common/constants/constants';
import IUnlockTripReportCriteria from '../../common/types/IUnlockTripReportCriteria';
import CustomButton from 'common/components/CustomButton';
import IDeparture from 'departures/types/IDeparture';

interface IProps extends WithStyles<typeof styles> {
  departure: IDeparture;
  crewMember: ICrew;
  actions: any;
}

interface IState {
  disabled: boolean;
}

class CrewTripReportUnlockButton extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false
    };
  }

  public render() {
    const { crewMember } = this.props;

    return (
      <CustomButton
        onClick={this.handleUnlockTripReport}
        id="btn.unlockTripReport"
        label="Unlock"
        disabled={this.isDisabled(crewMember.tripReportUploadStatus)}
        fontIcon="lock_open"
      />
    );
  }

  private handleUnlockTripReport = event => {
    const { departure, crewMember } = this.props;

    const searchCriteria: IUnlockTripReportCriteria = {
      departureId: departure.id,
      crewId: crewMember.crewId
    };

    this.setState({
      disabled: true
    });

    this.props.actions.unlockTripReport(searchCriteria);
  };

  private isDisabled(uploadStatus) {
    const date30DaysAgo = new Date();
    date30DaysAgo.setDate(new Date().getDate() - 31);

    return (
      uploadStatus === fileUploadStatus.NOT_UPLOADED ||
      uploadStatus === fileUploadStatus.REACTIVATED ||
      this.props.departure.endDate < date30DaysAgo ||
      this.state.disabled
    );
  }
}

export default withStyles(styles)(CrewTripReportUnlockButton);
