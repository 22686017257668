import * as React from "react";
import styles from './Logo.styles'
import { withStyles } from '@material-ui/core/styles';

const Logo = ({ brand, classes, imgId }) => {
    let logo;
    const brandText = brand || 'Generic Brand';

    switch (brand) {
        case 'Intrepid':
          logo = '/images/Intrepid.png';
          break;
        case 'Geckos':
          logo = '/images/Geckos.png';
          break;
        case 'Peregrine':
          logo = '/images/Peregrine.png';
          break;
        default:
          logo = '/images/DefaultBrand.png';
          break;
      }

      return <img alt={brandText} src={logo} className={classes.logo} id={imgId} />;
}  

export default withStyles(styles)(Logo);