import * as searchCriteria from './searchCriteria';
import * as searchResults from './searchResults';
import * as selectedDeparture from './selectedDeparture';
import * as selectedDeparturePax from './selectedDeparturePax';

export default {
  searchCriteria,
  searchResults,
  selectedDeparture,
  selectedDeparturePax
};
