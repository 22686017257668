import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  actionsSection: {
    padding: '5px 10px 0px 10px',
    textAlign: 'center'
  },
  root: {
    width: '100%',
    backgroundColor: '#fff',
    paddingBottom: 0
  },
  oldValue: {
    textDecoration: 'line-through'
  },
  paxName: {
    'font-weight': '500'
  }
});
