import * as actionTypes from '../actionTypes/pax';
import IPax from '../types/IPax';
import operationsPortalService from 'common/services/OperationsPortalService';
import selectors from 'departures/selectors';

export const paxListGetByDepartureRequest = (departureId: number) => ({
  type: actionTypes.PAX_LIST_GET_REQUEST,
  payload: { departureId: departureId }
});

export const paxListGetSuccess = (selectedDeparturePax: IPax[]) => ({
  type: actionTypes.PAX_LIST_GET_SUCCESS,
  payload: { selectedDeparturePax }
});

export const paxListGetFailure = () => ({
  type: actionTypes.PAX_LIST_GET_FAILURE
});

export const paxClear = () => ({
  type: actionTypes.PAX_LIST_CLEAR
});

function shouldFetch(state, departureId) {
  const stateDeparture = selectors.selectedDeparture.getDepartureById(state);

  return !stateDeparture || stateDeparture.id !== departureId;
}

export const getPaxListByDeparture = (departureId: number) => async (
  dispatch,
  getState
) => {
  try {
    if (!shouldFetch(getState(), departureId)) {
      return null;
    }

    dispatch(paxClear());

    dispatch(paxListGetByDepartureRequest(departureId));

    const data = await operationsPortalService.Pax.getPaxListByDeparture(
      departureId
    );

    dispatch(paxListGetSuccess(data as IPax[]));
    return;
  } catch (e) {
    dispatch(paxListGetFailure());
    throw e;
  }
};
