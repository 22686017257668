import { AdalConfig, AuthenticationContext, runWithAdal } from 'react-adal';
import env from '../../common/constants/env';

const DO_NOT_LOGIN = false;

export const adalConfig: AdalConfig = {
  cacheLocation: 'sessionStorage',
  clientId: env.REACT_APP_OPERATIONS_PORTAL_API_AAD_GUID,
  redirectUri: env.REACT_APP_OPERATIONS_PORTAL_API_REDIRECT_URI,
  tenant: env.REACT_APP_OPERATIONS_PORTAL_API_TENANT_URI
};

export const authContext = new AuthenticationContext(adalConfig);

export const runWithReactAdal = (app: () => void): void =>
  runWithAdal(
    authContext,
    () => {
      app();
    },
    DO_NOT_LOGIN
  );
