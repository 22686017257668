import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  actionButtonsContainer: {
    width: '95%',
    textAlign: 'right'
  },
  searchContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  otherFiltersContainer: {
    flex: '1',
    display: 'flex',
    flexWrap: 'wrap',
    'flex-direction': 'row',
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  inputsContainer: {
    paddingLeft: '15px',
    paddingRight: '15px',
    width: '200px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  buttonsContainer: {
    textAlign: 'right'
  },
  select: {
    width: '250px'
  }
});
