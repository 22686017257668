export default class DepartureSightseeing {
  public service: any;
  public baseResourcePath: string;
  constructor(service) {
    this.service = service;
    this.baseResourcePath = '/sightseeings';
  }

  public async getByDeparture(id: number) {
    const resourcePath = `/departure/${encodeURIComponent(id.toString())}${
      this.baseResourcePath
    }`;

    const data = await this.service.get(resourcePath);
    return data.body;
  }
}
