import * as actionTypes from '../actionTypes/selectedDMCs';

export const selectedDmcsSetRequest = (selectedDMCs: number[]) => ({
  type: actionTypes.SELECTED_DMC_SET,
  payload: {
    selectedDMCs
  }
});

export const setSelectedDMCs = (values: number[]) => dispatch => {

  dispatch(selectedDmcsSetRequest(values));
};