import productType from 'reports/enums/productType';
import IExtraServicesCriteria from 'reports/types/IExtraServicesCriteria';
import { downloadReport } from 'reports/utils/downloadReport';
import formatDate from 'common/helpers/formatDate';

export default class ExtraServices {
  public service: any;

  constructor(service) {
    this.service = service;
  }

  public async getExtraServicesReport(
    ExtraServicesData: IExtraServicesCriteria,
    fileType: string
  ) {
    const resourcePath = '/extraservicesreport';

    const data = await this.service.post(resourcePath, ExtraServicesData);

    const fileName =
      ExtraServicesData.product === productType.ACCOMMODATION
        ? 'Pre-Post Accommodation_' + formatDate(ExtraServicesData.dateFrom, 'ddMMMyyyy') + '_'
        + formatDate(ExtraServicesData.dateTo, 'ddMMMyyyy')
        : 'Transfers_' + formatDate(ExtraServicesData.dateFrom, 'ddMMMyyyy') + '_'
        + formatDate(ExtraServicesData.dateTo, 'ddMMMyyyy');

    downloadReport(data.body, fileName, fileType);
  }

  // Get Country List
  public async getCountryList() {
    const resourcePath = '/commonresources/countries';
    try {
      const data = await this.service.get(resourcePath);
      return data.body;
    } catch (error) {
      return [];
    }
  }
}
