import { startOfDay, isBefore } from 'date-fns';
import formatDate from 'common/helpers/formatDate';

export default function isAfterDate(value: Date, minDate: Date | undefined) {
  if (value) {
    if (minDate && isBefore(value, startOfDay(minDate))) {
      return `Invalid. Date must be on or after ${formatDate(minDate)}`;
    }
  }

  return undefined;
}
