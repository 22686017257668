import * as actionTypes from '../actionTypes/departureCovidQuestionnaire';
import ICovidQuestionnaireResponses from '../types/ICovidQuestionnaireResponses';
import operationsPortalService from 'common/services/OperationsPortalService';

export const departureCovidQuestionnaireGetRequest = departureId => ({
  type: actionTypes.COVID_QUESTIONNAIRE_RESULTS_GET,
  payload: {
    departureId
  }
});

export const departureCovidQuestionnaireGetSuccess = departureCovidQuestionnaire => ({
  type: actionTypes.COVID_QUESTIONNAIRE_RESULTS_GET_SUCCESS,
  payload: {
    departureCovidQuestionnaire: departureCovidQuestionnaire
  }
});

export const departureCovidQuestionnaireGetFailure = () => ({
  type: actionTypes.COVID_QUESTIONNAIRE_RESULTS_GET_FAILURE
});

export const getDepartureCovidQuestionnaireResponses = (id, type) => async dispatch => {

  try {
    dispatch(departureCovidQuestionnaireGetRequest(id));

    const data = await operationsPortalService.DepartureCovidQuestionnaire.getByDeparture(
      id, type
    );
    dispatch(departureCovidQuestionnaireGetSuccess(data as ICovidQuestionnaireResponses));
  } catch (e) {
    dispatch(departureCovidQuestionnaireGetFailure());
    throw e;
  }
};
