import * as actionTypes from '../actionTypes/departureSightseeing';
import IDepartureSightSeeing from '../types/IDepartureSightSeeing';
import operationsPortalService from 'common/services/OperationsPortalService';

export const departureSightSeeingGetRequest = departureId => ({
  type: actionTypes.DEPARTURE_SIGHTSEEING_GET_REQUEST,
  payload: {
    departureId
  }
});

export const departureSightSeeingGetSuccess = selectedDepartureSightSeeing => ({
  type: actionTypes.DEPARTURE_SIGHTSEEING_GET_SUCCESS,
  payload: {
    selectedDepartureSightSeeing: selectedDepartureSightSeeing
  }
});

export const departureSightSeeingGetByDepartureFailure = () => ({
  type: actionTypes.DEPARTURE_SIGHTSEEING_GET_FAILURE
});

export const getDepartureSightseeing = id => async dispatch => {
  try {
    dispatch(departureSightSeeingGetRequest(id));

    const data = await operationsPortalService.DepartureSightseeing.getByDeparture(
      id
    );
    dispatch(departureSightSeeingGetSuccess(data as IDepartureSightSeeing[]));
  } catch (e) {
    dispatch(departureSightSeeingGetByDepartureFailure());
    throw e;
  }
};
