import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import selectors from '../selectors';
import PaxDetails from './PaxDetails';
import DepartureCard from './DepartureCard';
import IPax from '../types/IPax';
import IDeparture from '../types/IDeparture';
import actions from '../actions';
import IDepartureTransfer from 'departures/types/IDepartureTransfer';
import IDepartureAccommodation from 'departures/types/IDepartureAccommodation';
import IPaxChanges from 'departures/types/IPaxChanges';
import IDepartureSightseeing from 'departures/types/IDepartureSightSeeing';
import { addMessage, messageTypes } from 'common/actions/globalMessages';
import { RouteComponentProps } from 'react-router';
import { AppState } from 'common/reducers/reducer';

interface IDispatchProps {
  actions?: any;
}

interface IProps {
  departureId: number;
  departure: IDeparture;
  pax: IPax[];
  departureTransfers: IDepartureTransfer[];
  departureAccommodation: IDepartureAccommodation[];
  departureSightseeing: IDepartureSightseeing[];
  departurePaxChanges: IPaxChanges[];
}

interface IRouteParams {
  id: string;
}

type Props = IProps & IDispatchProps & RouteComponentProps<IRouteParams>;

class PaxDetailsContainer extends React.Component<Props> {
  public render() {
    const {
      departure,
      pax,
      departureTransfers,
      departureAccommodation,
      departureSightseeing,
      departurePaxChanges
    } = this.props;

    return (
      departure &&
      pax &&
      departureTransfers &&
      departureAccommodation &&
      departureSightseeing &&
      departurePaxChanges && (
        <React.Fragment>
          <DepartureCard departure={departure} />
          <PaxDetails
            pax={pax}
            departure={departure}
            departureTransfers={departureTransfers}
            departureAccommodation={departureAccommodation}
            departureSightseeing={departureSightseeing}
            departurePaxChanges={departurePaxChanges}
            showPaxChangesCopiedMessage={
              this.props.actions.showPaxChangesCopiedMessage
            }
          />
        </React.Fragment>
      )
    );
  }

  public componentDidMount() {
    this.loadData();
  }

  public loadData() {
    this.props.actions.getDeparture(this.props.departureId);
    this.props.actions.getPaxList(this.props.departureId);
    this.props.actions.getDepartureTransfers(this.props.departureId);
    this.props.actions.getDepartureAccommodation(this.props.departureId);
    this.props.actions.getDepartureSightseeing(this.props.departureId);
    this.props.actions.getDeparturePaxChanges(this.props.departureId);
  }
}

const mapStateToProps = (
  state: AppState,
  params: RouteComponentProps<IRouteParams>
): IProps => {
  const departure = selectors.selectedDeparture.getDepartureById(state);
  const pax = selectors.selectedDeparturePax.getPaxListByDeparture(state);
  const departureTransfers = selectors.selectedDepartureTransfers.getTransferListByDeparture(
    state
  );

  const departureAccommodation = selectors.selectedDepartureAccommodation.getAccommodationListByDeparture(
    state
  );

  const departureSightseeing = selectors.selectedDepartureSightseeing.getSightseeingListByDeparture(
    state
  );

  const departurePaxChanges = selectors.selectedDeparturePaxChanges.getPaxChangesByDeparture(
    state
  );

  return {
    departureId: parseInt(params.match.params.id, 10),
    departure: departure,
    pax: pax,
    departureTransfers: departureTransfers,
    departureAccommodation: departureAccommodation,
    departureSightseeing: departureSightseeing,
    departurePaxChanges: departurePaxChanges
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        getDeparture: actions.selectedDeparture.getDepartureById,
        getPaxList: actions.pax.getPaxListByDeparture,
        getDepartureTransfers: actions.departureTransfers.getDepartureTransfers,
        getDepartureAccommodation:
          actions.departureAccommodation.getDepartureAccommodation,
        getDepartureSightseeing:
          actions.departureSightseeing.getDepartureSightseeing,
        getDeparturePaxChanges:
          actions.departurePaxChanges.getDeparturePaxChanges,
        showPaxChangesCopiedMessage: () => async dispatch => {
          dispatch(
            addMessage(
              'Pax changes copied to clipboard',
              messageTypes.info,
              false,
              false
            )
          );
        }
      },
      dispatch
    )
  }
});

export default connect<IProps>(
  mapStateToProps,
  mapDispatchToProps
)(PaxDetailsContainer);
