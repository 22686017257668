import env from 'common/constants/env';
import adalFetch from 'common/helpers/adalFetch';
import BaseService from 'common/services/BaseService';
import DepartureManifest from './DepartureManifest';
import DepartureStatus from './DepartureStatus';
import ExtraServices from './ExtraServices';
import Operators from './Operators';

class PlanningPortalService extends BaseService {
  public ExtraServices: ExtraServices;
  public DepartureManifest: DepartureManifest;
  public DepartureStatus: DepartureStatus;
  public baseURL: string;
  public Operators: Operators;

  constructor() {
    super();
    this.ExtraServices = new ExtraServices(this);
    this.DepartureManifest = new DepartureManifest(this);
    this.DepartureStatus = new DepartureStatus(this);
    this.fetch = adalFetch(super.fetch).bind(this);
    this.Operators = new Operators(this);
    this.baseURL = env.REACT_APP_OPERATIONS_PORTAL_SERVICE_URL;
  }

  public post(href, data) {
    const headers = new Headers();

    headers.append('Content-Type', 'application/json;charset=utf-8');

    const request = this.createRequest(this.baseURL, href, {
      body: JSON.stringify(data),
      method: 'POST',
      headers: headers,
    });

    return this.fetch(request).then(this.extract);
  }

  public get(href) {
    const headers = new Headers();
    const request = this.createRequest(this.baseURL, href, {
      method: 'GET',
      body: null,
      headers: headers,
    });
    return this.fetch(request).then(this.extract);
  }
}

const planningPortalService: PlanningPortalService =
  new PlanningPortalService();

export default planningPortalService;
