import { loadingBarMiddleware } from 'react-redux-loading-bar';

export default function loadingBarInterceptor(config = {}) {       
  const reduxMiddleware = loadingBarMiddleware(config);
  return dispatch => next => action => {
    if (!(action.meta && action.meta.disableLoadingBar)) {
      return reduxMiddleware(dispatch)(next)(action);
    }

    return next(action);
  };
}