import { createStyles } from '@material-ui/core/styles';
export default createStyles({
  paper: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    padding: '5px 10px 5px 10px'
  }
});