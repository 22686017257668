import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PlanningDepartureTabContainer from './PlanningDepartureTabContainer';
import PlanningDepartureCard from './PlanningDepartureCard';
import { AppState } from 'common/reducers/reducer';
import selectors from '../selectors/planningDepartures';
import actions from '../actions/planningDepartures';
import { RouteComponentProps } from 'react-router';
import IPax from 'departures/types/IPax';
import IPlanningDeparture from 'planning/types/IPlanningDeparture';

interface IDispatchProps {
  actions?;
}

interface IProps {
  departureId: number;
  departure: IPlanningDeparture | null;
  pax: IPax[] | null;
}

interface IRouteParams {
  id: string;
}

type Props = IProps & IDispatchProps & RouteComponentProps<IRouteParams>;

class PlanningDepartureDetailsContainer extends React.Component<Props> {
  public render() {
    const { departure, pax } = this.props;

    return (
      <div>
        {departure && pax && (
          <React.Fragment>
            <PlanningDepartureCard departure={departure} />
            <PlanningDepartureTabContainer pax={pax} />
          </React.Fragment>
        )}
      </div>
    );
  }

  public componentDidMount() {
    this.loadData();
  }

  public loadData() {
    this.props.actions.getDepartureById(this.props.departureId);
    this.props.actions.getPaxList(this.props.departureId);
  }
}

const mapStateToProps = (
  state: AppState,
  params: RouteComponentProps<IRouteParams>
): IProps => {
  const departure = selectors.selectedDeparture.getSelectedDeparture(state);
  const pax = selectors.selectedDeparturePax.getSelectedDeparturePax(state);

  return {
    departureId: parseInt(params.match.params.id, 10),
    departure: departure,
    pax: pax
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        getDepartureById: actions.getDepartureById,
        getPaxList: actions.getPaxListByDeparture
      },
      dispatch
    )
  }
});

export default connect<IProps>(
  mapStateToProps,
  mapDispatchToProps
)(PlanningDepartureDetailsContainer);
