import * as React from 'react';
import IPlanningDeparture from '../types/IPlanningDeparture';
import styles from './PlanningDepartureGeneralDetails.styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import formatDate from 'common/helpers/formatDate';

interface IProps extends WithStyles<typeof styles> {
  departure: IPlanningDeparture;
}

class PlanningDepartureGeneralDetails extends React.Component<IProps> {
  public render() {
    const { departure, classes } = this.props;

    return (
      <React.Fragment>
        {this.renderInfo(
          'Dates',
          <span>
            {formatDate(departure.startDate)}{' '}
            <Icon className={classes.durationIcon} fontSize="small">
              arrow_right_alt
            </Icon>{' '}
            {formatDate(departure.endDate)}
          </span>
        )}
        {this.renderInfo(
          'From/To',
          <span>
            {departure.startPoint}{' '}
            <Icon className={classes.durationIcon} fontSize="small">
              arrow_right_alt
            </Icon>{' '}
            {departure.endPoint}
          </span>
        )}
      </React.Fragment>
    );
  }

  private renderInfo(label: string, content: JSX.Element) {
    const { classes } = this.props;
    return (
      <div className={classes.infoContainer}>
        <Typography variant="caption">
          <span className={classes.label}>{label}</span>
        </Typography>
        <Typography variant="caption">{content}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(PlanningDepartureGeneralDetails);
