import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import selectors from '../selectors';
import actions from '../actions';
import IDeparture from '../types/IDeparture';
import IDepartureItinerary from 'departures/types/IDepartureItinerary';
import LocationsDialog from './LocationsDialog';

interface IDispatchProps {
  actions?: any;
}

interface IOwnProps {
  selectedDeparture: IDeparture;
}

interface IProps {
  selectedDepartureItinerary: IDepartureItinerary;
}

type Props = IProps & IDispatchProps & IOwnProps;

class LocationsDialogContainer extends React.Component<Props> {
  public render() {
    return (
      <LocationsDialog
        departure={this.props.selectedDeparture}
        itinerary={this.props.selectedDepartureItinerary}
        actions={this.props.actions}
      />
    );
  }
}

const mapStateToProps = state => ({
  selectedDepartureItinerary: selectors.selectedDepartureItinerary.getSelectedDepartureItinerary(
    state
  )
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        getDepartureItinerary: actions.departureItinerary.getByDeparture
      },
      dispatch
    )
  }
});

export default connect<IProps, {}, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(LocationsDialogContainer);
