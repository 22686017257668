import * as actionTypes from '../actionTypes/departurePaxChanges';
import IPaxChanges from '../types/IPaxChanges';
import operationsPortalService from 'common/services/OperationsPortalService';

export const departurePaxChangesGetRequest = departureId => ({
  type: actionTypes.DEPARTURE_PAX_CHANGES_GET_REQUEST,
  payload: {
    departureId
  }
});

export const departurePaxChangesGetSuccess = selectedDeparturePaxChanges => ({
  type: actionTypes.DEPARTURE_PAX_CHANGES_GET_SUCCESS,
  payload: {
    selectedDeparturePaxChanges: selectedDeparturePaxChanges
  }
});

export const departurePaxChangesGetByDepartureFailure = () => ({
  type: actionTypes.DEPARTURE_PAX_CHANGES_GET_FAILURE
});

export const getDeparturePaxChanges = id => async dispatch => {
  try {
    dispatch(departurePaxChangesGetRequest(id));

    const data = await operationsPortalService.DeparturePaxChanges.getByDeparture(
      id
    );
    dispatch(departurePaxChangesGetSuccess(data as IPaxChanges[]));
  } catch (e) {
    dispatch(departurePaxChangesGetByDepartureFailure());
    throw e;
  }
};
