import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CustomSelectField from '../../common/components/CustomSelectField';
import Button from '@material-ui/core/Button';
import DateRangeFilter from 'common/forms/DateRangeFilter';
import IPlanningTransferSearchCriteria from 'common/types/IPlanningTransferSearchCriteria';
import styles from './PlanningTransferSearch.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import IPlanningTransfer from 'planning/types/IPlanningTransfer';
import avoidEnterOnKeyPress from '../../common/helpers/avoidEnterOnKeyPress';

interface IProps extends WithStyles<typeof styles> {
  initialSearchCriteria: IPlanningTransferSearchCriteria;
  selectedDmcs: number[];
  getTransfers: (
    searchCriteria: IPlanningTransferSearchCriteria
  ) => IPlanningTransfer[];
}

const statuses: Array<{ value: string; text: string }> = [
  { value: 'Confirmed', text: 'Confirmed' },
  { value: 'Tentative', text: 'Tentative' },
  { value: 'Cancelled', text: 'Cancelled' },
  { value: 'OnRequest', text: 'On Request' }
];

class PlanningTransferSearch extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const classes = this.props.classes;

    return (
      <Card>
        <CardContent>
          <Form
            onSubmit={this.onSubmit}
            initialValues={this.props.initialSearchCriteria}
            render={({
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
              errors
            }) => (
                <form
                  onSubmit={handleSubmit}
                  noValidate={true}
                  onKeyPress={avoidEnterOnKeyPress}
                >
                  <div className={classes.searchContainer}>
                    <DateRangeFilter id="transferDate" />
                    <div className={classes.otherFiltersContainer}>
                      <div className={classes.inputsContainer}>
                        <Field name={'statuses'} allowNull={true}>
                          {({ input, meta }) => {
                            const { value, onChange } = input;

                            return (
                              <CustomSelectField
                                label="Current Status"
                                value={value}
                                onChange={onChange}
                                options={statuses}
                                clearable={true}
                                onClear={onChange}
                                aggregateMultiSelectionsOnDisplay={true}
                                selectClass={classes.select}
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.actionButtonsContainer}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        id="btn.search"
                        disabled={submitting}
                      >
                        Search
                    </Button>
                    </div>
                  </div>
                </form>
              )}
          />
        </CardContent>
      </Card>
    );
  }

  private onSubmit = async values => {
    try {
      const cleansedSearchCriteria: IPlanningTransferSearchCriteria = {
        transferDateFrom: values.transferDateFrom,
        transferDateTo: values.transferDateTo,
        statuses:
          values.statuses.length > 0
            ? values.statuses
            : statuses.map(v => v.value)
      };

      await this.props.getTransfers(cleansedSearchCriteria);
    } catch (e) {
      throw e;
    }
  };
}

export default withStyles(styles)(PlanningTransferSearch);
