import * as actionTypes from '../actionTypes/departureTransfer';
import IDepartureTransfer from '../types/IDepartureTransfer';
import operationsPortalService from 'common/services/OperationsPortalService';

export const departureTransfersGetRequest = departureId => ({
  type: actionTypes.DEPARTURE_TRANSFERS_GET_REQUEST,
  payload: {
    departureId
  }
});

export const departureTransfersGetSuccess = selectedDepartureTransfers => ({
  type: actionTypes.DEPARTURE_TRANSFERS_GET_SUCCESS,
  payload: {
    selectedDepartureTransfers
  }
});

export const departureTransfersGetByDepartureFailure = () => ({
  type: actionTypes.DEPARTURE_TRANSFERS_GET_FAILURE
});

export const getDepartureTransfers = id => async dispatch => {
  try {
    dispatch(departureTransfersGetRequest(id));

    const data = await operationsPortalService.DepartureTransfers.getByDeparture(
      id
    );

    dispatch(departureTransfersGetSuccess(data as IDepartureTransfer[]));
  } catch (e) {
    dispatch(departureTransfersGetByDepartureFailure());
    throw e;
  }
};
