export default class ApiError {
    public name: string;
    public message: string;
    public params: any;
    public stack: string | undefined;

    constructor(name: string, message: string, params: any, stack: string | undefined)
    {
        this.name = name;
        this.message = message;
        this.params = params;
        this.stack = stack;
    }

    public toString(){
        return this.message;
    }
}