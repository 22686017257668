export default class Operators {
  public service: any;

  constructor(service) {
    this.service = service;
  }

  // This is to get the Operators for a DMC
  public async getOperators(dmcId: number) {
    const resourcePath = `/dmc/${dmcId}/operators`;

    const data = await this.service.get(resourcePath);

    return data.body;
  }
}
