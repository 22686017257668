import * as departureSearch from './departureSearch';
import * as selectedDeparture from './selectedDeparture';
import * as pax from './pax';
import * as crew from './crew';
import * as departureItinerary from './departureItinerary';
import * as departureAccommodation from './departureAccommodation';
import * as departureSightseeing from './departureSightseeing';
import * as departurePaxChanges from './departurePaxChanges';
import * as departureTransfers from './departureTransfers';
import * as departureCovidQuestionnaire from './departureCovidQuestionnaire';
import * as reporting from './reporting';
import * as departureHealthScreening from './departureHealthScreening'

export default {
  selectedDeparture,
  departureSearch,
  pax,
  crew,
  departureItinerary,
  departureAccommodation,
  departureSightseeing,
  departureTransfers,
  departurePaxChanges,
  departureCovidQuestionnaire,
  reporting,
  departureHealthScreening
};
