import * as actionTypes from '../actionTypes/departureHealthScreening';
import ICovidQuestionnaireResponses from '../types/ICovidQuestionnaireResponses';
import operationsPortalService from 'common/services/OperationsPortalService';

export const departureHealthScreeningGetRequest = departureId => ({
  type: actionTypes.COVID_HEALTH_SCREENING_RESULTS_GET,
  payload: {
    departureId
  }
});

export const departureHealthScreeningGetSuccess = departureHealthScreening => ({
  type: actionTypes.HEALTH_SCREENING_RESULTS_GET_SUCCESS,
  payload: {
    departureHealthScreening: departureHealthScreening
  }
});

export const departureHealthScreeningGetFailure = () => ({
  type: actionTypes.HEALTH_SCREENING_RESULTS_GET_FAILURE
});

export const getHealthScreeningResponses = (id, type) => async dispatch => {

  try {
    dispatch(departureHealthScreeningGetRequest(id));

    const data = await operationsPortalService.DepartureCovidQuestionnaire.getByDeparture(
      id, type
    );
    dispatch(departureHealthScreeningGetSuccess(data as ICovidQuestionnaireResponses));
  } catch (e) {
    dispatch(departureHealthScreeningGetFailure());
    throw e;
  }
};
