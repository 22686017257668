import * as actionTypes from '../actionTypes/selectedDMCs';

type State = number[] | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_DMC_SET:
      return action.payload.selectedDMCs;
    default:
      return state;
  }
};
