import * as React from 'react';
import createLink from '../helpers/createLink';
import { Link } from 'react-router-dom';
import styles from './LinkWrapper.styles';
import { withStyles } from '@material-ui/core/styles';

const LinkWrapper = ({ classes, path, parameters, children }) => {
  const route = createLink(path, parameters);

  return (
    <Link to={route} className={classes.linkStyle}>
      {children}
    </Link>
  );
};

export default withStyles(styles)(LinkWrapper);
