import * as React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import styles from './CustomButton.styles';
import { withStyles } from '@material-ui/core/styles';

const CustomButton = ({
  onClick,
  id,
  label,
  disabled = false,
  fontIcon,
  classes
}) => (
  <Button
    variant="outlined"
    color="primary"
    onClick={onClick}
    id={id}
    disabled={disabled}
    className={classes.container}
  >
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12}>
        <Icon color="primary">{fontIcon}</Icon>
      </Grid>
      <Grid item xs={12} sm={12}>
        {label}
      </Grid>
    </Grid>
  </Button>
);

export default withStyles(styles)(CustomButton);
