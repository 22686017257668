import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  icon: {
    float: 'left',
    marginRight: '5px'
  },
  iconContainer: {
    padding: '5px 10px 5px 10px'
  },
  defaultTypography: {
    display: 'inline'
  }
});
