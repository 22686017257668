import * as actionTypes from '../actionTypes/globalMessages';

export const addMessage = (
  content: string,
  type: string,
  redirect: boolean | null,
  closable: boolean | null,
  autoHideDuration?: number | null,
  customAction?: JSX.Element
) => ({
  type: actionTypes.GLOBAL_MESSAGES_ADD_MESSAGE,
  payload: {
    content,
    type,
    redirect,
    closable,
    customAction,
    autoHideDuration
  }
});

export const removeMessage = () => ({
  type: actionTypes.GLOBAL_MESSAGES_REMOVE_MESSAGE
});

export const messageTypes = {
  success: 'success',
  info: 'info',
  error: 'error'
};
