import * as React from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from './CustomSelectField.styles';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Pluralize from 'pluralize';

interface IProps extends SelectProps, WithStyles<typeof styles> {
  value: any[];
  label: string;
  options: Array<{ value: any; text: string }>;
  classes: Record<'textField', string> | any;
  clearable?: boolean;
  onClear?: ((event: React.MouseEvent<HTMLElement>) => void) | undefined;
  labelClass?: string;
  selectClass?: string;
  multiSelect?: boolean;
  selectAllOption?: boolean;
  selectAllText?: string;
  aggregateMultiSelectionsOnDisplay?: boolean;
  onChange?: any;
}

class CustomSelectField extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const {
      value,
      classes,
      options,
      onChange,
      defaultValue,
      labelClass,
      selectClass,
      multiSelect = true,
      selectAllOption = false,
      selectAllText = 'Select all',
      aggregateMultiSelectionsOnDisplay,
      ...rest
    } = this.props;
    const selectAllValue = 'allItems';

    if (selectAllOption && !multiSelect) {
      throw new Error(
        'multiSelect must be set to true when selectAllOption is set to true'
      );
    }

    const renderValue = (selected: any[]) => {
      const selectedItems = options.filter(o => selected.indexOf(o.value) > -1);

      if (selectedItems.length === 0) {
        return '';
      }

      if (aggregateMultiSelectionsOnDisplay) {
        return selectedItems.length > 1
          ? `${selectedItems.length} ${Pluralize(
            this.props.label,
            selectedItems.length
          )} selected`
          : selectedItems[0].text;
      }

      return selectedItems.map(s => s.text).join(', ');
    };

    return (
      <FormControl className={classes.formControl}>
        <InputLabel className={labelClass}>{rest.label}</InputLabel>
        <br />
        <Select
          multiple={multiSelect}
          value={value}
          renderValue={renderValue}
          className={selectClass}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            }
          }}
          onChange={(e, child: any) => {
            onChange!(
              this.handleSelect(e.target.value, child.key, selectAllValue)
            );
          }}
        >
          {value && selectAllOption && (
            <MenuItem key={selectAllValue} value={selectAllValue}>
              <Checkbox checked={options.length <= value.length} />
              <ListItemText
                primary={selectAllText}
                className={classes.selectAllText}
              />
            </MenuItem>
          )}
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {multiSelect && (
                <Checkbox checked={value.indexOf(option.value) > -1} />
              )}
              <ListItemText primary={option.text} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  public handleSelect(values, childValue, selectAllValue) {
    values = Array.isArray(values) ? values : [values];

    if (childValue !== selectAllValue) {
      return values;
    }
    const filteredValues = values.filter(v => v !== selectAllValue);

    if (filteredValues.length === this.props.options.length) {
      return [];
    } else {
      return this.props.options.map(o => o.value);
    }
  }
}

export default withStyles(styles)(CustomSelectField);
