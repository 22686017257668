import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  titleText: {
    paddingLeft: '15px',
    paddingRight: '15px',
    textDecoration: 'underline',
  },
  reportWrapper: {
    padding: '15px 0 15px 20px',
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%,',
  },
  reportColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
  },
  inputsContainer: {
    paddingRight: '15px',
    width: '200px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonContainer: {
    paddingTop: '16px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spinnerContainer: {
    marginLeft: '10px',
  },
});
