export default class Dmc {
  public service: any;
  public baseResourcePath: string;

  constructor(service) {
    this.service = service;
    this.baseResourcePath = '/dmc';
  }

  public async getByUser() {
    const resourcePath = `${this.baseResourcePath}`;

    const data = await this.service.get(resourcePath);
    return data.body;
  }
}
