import * as React from 'react';
import NoResultsMessage from 'common/components/NoResultsMessage';
import IPlanningAccommodation from '../types/IPlanningAccommodation';
import KeyValueItem, {
  KeyValueItemOrientation
} from '../../common/components/KeyValueItem';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import styles from './PlanningAccommodationList.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import formatDate from 'common/helpers/formatDate';

interface IProps extends WithStyles<typeof styles> {
  accommodation?: IPlanningAccommodation[];
}

class PlanningAccommodationList extends React.Component<IProps> {
  public render() {
    const { accommodation, classes } = this.props;

    if (!!accommodation && accommodation.length === 0) {
      return <NoResultsMessage message="No results to display." />;
    }

    return <Card>{this.renderAccommodation(accommodation, classes)}</Card>;
  }

  private renderAccommodation(accommodationList, classes) {
    return (
      accommodationList &&
      accommodationList.map((accommodation, i) => (
        <div className={classes.accommodationContainer}>
          <Grid container={true} spacing={1}>
            <Grid item xs={2} sm={2}>
              <KeyValueItem
                label="Booking ID:"
                value={accommodation.bookingId}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Title:"
                value={accommodation.title || emptyFieldText}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="First Name:"
                value={accommodation.firstName || emptyFieldText}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Surname:"
                value={accommodation.surname || emptyFieldText}
                orientation={KeyValueItemOrientation.horizontal}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <KeyValueItem
                label="Accommodation Name:"
                value={accommodation.serviceName}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Accommodation Start Date:"
                value={formatDate(accommodation.accommodationStartDate)}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Accommodation Finish Date:"
                value={formatDate(accommodation.accommodationEndDate)}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Number of nights:"
                value={accommodation.numberOfNights}
                orientation={KeyValueItemOrientation.horizontal}
              />
              <KeyValueItem
                label="Room Type:"
                value={accommodation.roomType}
                orientation={KeyValueItemOrientation.horizontal}
              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <KeyValueItem
                label="City:"
                value={accommodation.city || emptyFieldText}
                orientation={KeyValueItemOrientation.horizontal}
              />
              <KeyValueItem
                label="Country:"
                value={accommodation.country}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Status:"
                value={accommodation.status}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Linked Departure Code:"
                value={accommodation.linkedDepartureCode || emptyFieldText}
                orientation={KeyValueItemOrientation.horizontal}
              />
            </Grid>
          </Grid>
          <Divider />
        </div>
      ))
    );
  }
}

const emptyFieldText = ' - ';

export default withStyles(styles)(PlanningAccommodationList);
