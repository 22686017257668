import * as React from 'react';

interface IProps {
  stringToCopy: string;
  showConfirmationMessage: () => void;
  children: React.ReactNode;
}

class CopyStringToClipboard extends React.Component<IProps> {
  public render() {
    const { children } = this.props;
    return <div onClick={this.clickHandler}>{children}</div>;
  }

  private clickHandler = () => {
    this.copyStringToClipboard(this.props.stringToCopy);
    this.props.showConfirmationMessage();
  };

  private copyStringToClipboard(str) {
    // Create new element
    const el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.setAttribute('style', 'position:absolute; left:-9999px;');
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  }
}

export default CopyStringToClipboard;
