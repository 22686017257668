import * as actionTypes from '../actionTypes/systemConfigs';
import operationsPortalService from 'common/services/OperationsPortalService';

export const systemConfigsGetSuccess = systemConfigs => ({
  type: actionTypes.SYSTEM_CONFIGS_GET_SUCCESS,
  payload: {
    systemConfigs
  }
});

export const getSystemConfigsByCodes = configCodes => async dispatch => {
  const data = await operationsPortalService.SystemConfig.get(configCodes);

  dispatch(systemConfigsGetSuccess(data));
};
