import * as React from 'react';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import { messageTypes } from '../actions/globalMessages';
import IconButton from '@material-ui/core/IconButton';
import styles from './GlobalMessage.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Slide } from '@material-ui/core';

const icons = {
  [messageTypes.success]: 'done',
  [messageTypes.info]: 'info_outline',
  [messageTypes.error]: 'error_outline'
};

interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  autoHideDuration: number;
  message: string;
  closable?: boolean;
  redirect?: boolean;
  type: any;
  onClose: () => any;
  action: [() => any];
}

interface IDispatchProps {
  actions?: any;
}

type Props = IProps & IDispatchProps;

class GlobalMessage extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      open,
      autoHideDuration,
      message,
      type,
      action,
      classes
    } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          onClose={this.onClose}
          message={
            <div className={classes.messageContainer} id="message-id">
              <Icon className={classes[type]}>{icons[type]}</Icon>
              <span className={classes.message}>{message}</span>
            </div>
          }
          autoHideDuration={autoHideDuration}
          action={[action, this.renderCloseButton()]}
          TransitionComponent={props => <Slide {...props} direction="up" />}
        />
      </div>
    );
  }

  private onClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.onClose();
  };

  private renderCloseButton = () =>
    this.props.closable &&
    this.props.message &&
    !this.props.redirect && (
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => this.props.onClose()}
      >
        <Icon>close</Icon>
      </IconButton>
    );
}

export default withStyles(styles)(GlobalMessage);
