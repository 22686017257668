import * as actionTypes from '../../actionTypes/departureCovidQuestionnaire';
import ICovidQuestionnaireResponses from '../../types/ICovidQuestionnaireResponses';

type State = ICovidQuestionnaireResponses | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.COVID_QUESTIONNAIRE_RESULTS_GET_SUCCESS:
      return action.payload.departureCovidQuestionnaire;
    default:
      return state;
  }
};
