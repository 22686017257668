import * as React from 'react';
import IDeparture from '../types/IDeparture';
import styles from './Departure.styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import formatDate from 'common/helpers/formatDate';

interface IProps extends WithStyles<typeof styles> {
  departure: IDeparture;
}

class DepartureGeneralDetails extends React.Component<IProps> {
  public render() {
    const { departure, classes } = this.props;

    return (
      <React.Fragment>
        {this.renderInfo(
          'Dates',
          <span>
            {formatDate(departure.startDate)}{' '}
            <Icon className={classes.durationIcon} fontSize="small">
              arrow_right_alt
            </Icon>{' '}
            {formatDate(departure.endDate)}
          </span>
        )}
        {this.renderInfo(
          'Locations',
          <span>
            {departure.startLocation}{' '}
            <Icon className={classes.durationIcon} fontSize="small">
              arrow_right_alt
            </Icon>
            {departure.endLocation}
          </span>
        )}
        {this.renderInfo(
          'Leader',
          <span>
            {departure.crew.filter(m => m.role === 'LEADER').length === 0
              ? 'Unassigned'
              : departure.crew
                  .filter(m => m.role === 'LEADER')
                  .map(a => a.name)
                  .join(', ')}
          </span>
        )}
        {departure.crew.filter(m => m.role !== 'LEADER').length !== 0 &&
          this.renderInfo(
            'Crew',
            <span>
              {departure.crew
                .filter(m => m.role !== 'LEADER')
                .map(a => `${a.name} - ${a.roleDescription}`)
                .join(', ')}
            </span>
          )}
      </React.Fragment>
    );
  }

  private renderInfo(label: string, content: JSX.Element) {
    const { classes } = this.props;
    return (
      <div className={classes.infoContainer}>
        <Typography variant="caption">
          <span className={classes.label}>{label}</span>
        </Typography>
        <Typography variant="caption">{content}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(DepartureGeneralDetails);
