import convertToAPIDate from 'common/helpers/convertToAPIDate';
import BaseService, { IOptions } from './BaseService';
import Departures from './Departures';
import DepartureItinerary from './DepartureItinerary';
import DepartureAccommodation from './DepartureAccommodation';
import DepartureSightseeing from './DepartureSightseeing';
import DeparturePaxChanges from './DeparturePaxChanges';
import DepartureTransfers from './DepartureTransfers';
import PlanningTransfers from './PlanningTransfers';
import PlanningAccomodation from './PlanningAccommodation';
import Dmc from './Dmc';
import Crew from './Crew';
import Reporting from './Reporting';
import adalFetch from '../helpers/adalFetch';
import env from '../../common/constants/env';
import displayFile from 'common/helpers/displayFile';
import Pax from './Pax';
import PlanningDepartures from './PlanningDepartures';
import { store } from 'common/store';
import { getSelectedDmcs } from '../../common/selectors/selectedDmcs';
import PlanningProducts from './PlanningProducts';
import DepartureCovidQuestionnaire from './DepartureCovidQuestionnaire';
import SystemConfig from './SystemConfig';

class OperationsPortalService extends BaseService {
  public Departures: Departures;
  public DepartureItinerary: DepartureItinerary;
  public DepartureAccommodation: DepartureAccommodation;
  public DepartureSightseeing: DepartureSightseeing;
  public DepartureTransfers: DepartureTransfers;
  public PlanningTransfers: PlanningTransfers;
  public PlanningAccommodation: PlanningAccomodation;
  public PlanningDepartures: PlanningDepartures;
  public PlanningProducts: PlanningProducts;
  public DeparturePaxChanges: DeparturePaxChanges;
  public Pax: Pax;
  public Dmc: Dmc;
  public Crew: Crew;
  public Reporting: Reporting;
  public DepartureCovidQuestionnaire: DepartureCovidQuestionnaire;
  public SystemConfig: SystemConfig;
  public baseUrl: string;

  constructor() {
    super();

    this.Departures = new Departures(this);
    this.DepartureItinerary = new DepartureItinerary(this);
    this.DepartureAccommodation = new DepartureAccommodation(this);
    this.DepartureSightseeing = new DepartureSightseeing(this);
    this.DepartureTransfers = new DepartureTransfers(this);
    this.DeparturePaxChanges = new DeparturePaxChanges(this);
    this.PlanningTransfers = new PlanningTransfers(this);
    this.PlanningAccommodation = new PlanningAccomodation(this);
    this.PlanningDepartures = new PlanningDepartures(this);
    this.PlanningProducts = new PlanningProducts(this);
    this.Pax = new Pax(this);
    this.Dmc = new Dmc(this);
    this.Crew = new Crew(this);
    this.Reporting = new Reporting(this);
    this.DepartureCovidQuestionnaire = new DepartureCovidQuestionnaire(this);
    this.SystemConfig = new SystemConfig(this);
    this.baseUrl = env.REACT_APP_OPERATIONS_PORTAL_SERVICE_URL;
    this.fetch = adalFetch(super.fetch).bind(this);
  }

  public get(href) {
    const headers = new Headers();
    headers.append('requestDate', convertToAPIDate(new Date()));

    const options: IOptions = { method: 'GET', body: null, headers: headers };

    const request = this.createRequest(this.baseUrl, href, options);

    return this.fetch(request)
      .then(this.extract);
  }

  public post(href, data) {
    const headers = new Headers();

    headers.append(
      'selectedDMC',
      getSelectedDmcs(store.getState())
        .map(id => id)
        .join(',')
    );
    headers.append('Content-Type', 'application/json;charset=utf-8');
    headers.append('requestDate', convertToAPIDate(new Date()));

    const request = this.createRequest(this.baseUrl, href, {
      body: JSON.stringify(data),
      method: 'POST',
      headers: headers
    });

    return this.fetch(request)
      .then(this.extract);
  }

  public getAndDisplayFile(href) {
    const headers = new Headers();
    headers.append('requestDate', convertToAPIDate(new Date()));

    const options: IOptions = { method: 'GET', body: null, headers: headers };

    const request = this.createRequest(this.baseUrl, href, options);

    return this.fetch(request)
      .then(response => response.blob())
      .then(blob => displayFile(blob));
  }
}

const operationsPortalService: OperationsPortalService = new OperationsPortalService();

export default operationsPortalService;
