import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import apiCatch from './middleware/apiCatch';
import persistReducer from './reducers/reducer';
import loadingBarMiddleware from './middleware/loadingBarMiddleware';
import { persistStore } from 'redux-persist';

export const history = createBrowserHistory();

declare const window: Window & {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?(a: any): void;
};

const initialState = {};
const enhancers = [];

const middleware = [
  apiCatch,
  thunk,
  loadingBarMiddleware({
    promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE']
  }),
  routerMiddleware(history)
];

const composeEnhancers: any =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const composedEnhancers = composeEnhancers(
  applyMiddleware(...middleware),
  ...enhancers
);

export const store = createStore(
  persistReducer,
  initialState,
  composedEnhancers
);
export const persistor = persistStore(store);

export default { store, persistor };
