import * as React from 'react';
import { Paper, Tab, Tabs, withStyles } from '@material-ui/core';
import styles from './PlanningDeparturePaxDetailsContainer.styles';
import { WithStyles } from '@material-ui/styles';
import IPax from 'departures/types/IPax';
import PlanningDeparturePaxDetailsContainer from './PlanningDeparturePaxDetailsContainer';
import PlanningDepartureContactDetailsContainer from './PlanningDepartureContactDetailsContainer';
import PlanningDepartureBookingDetailsContainer from './PlanningDepartureBookingDetailsContainer';
import PlanningDepartureExtraServiceDetailsContainer from './PlanningDepartureExtraServiceDetailsContainer';
import PlanningDepartureNoteDetailsContainer from './PlanningDepartureNoteDetailsContainer';

interface IState {
  selectedTabIndex?: number;
  selectedChildTabIndex?: number;
}

interface IProps extends WithStyles<typeof styles> {
  pax: IPax[];
}

class PlanningDepartureTabContainer extends React.Component<
  IProps,
  IState
  > {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: 0,
      selectedChildTabIndex: 0
    };
  }

  public render() {
    const { pax, classes } = this.props;

    const { selectedTabIndex } = this.state;

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Tabs
            value={this.state.selectedTabIndex}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              id={'btn.passengers'}
              label={'Passengers'}
            />
            <Tab
              id={'btn.bookingDetails'}
              label={'Booking Details'}
            />
            <Tab
              id={'btn.extraServices'}
              label={'Extra Services'}
            />
            <Tab
              id={'btn.contactInfo'}
              label={'Contact Info'}
            />
            <Tab
              id={'btn.notes'}
              label={'Notes'}
            />
          </Tabs>
        </Paper>
        {selectedTabIndex === 0 &&
          <PlanningDeparturePaxDetailsContainer pax={pax} />
        }
        {selectedTabIndex === 1 &&
          <PlanningDepartureBookingDetailsContainer pax={pax} />
        }
        {selectedTabIndex === 2 &&
          <PlanningDepartureExtraServiceDetailsContainer pax={pax} />
        }
        {selectedTabIndex === 3 &&
          <PlanningDepartureContactDetailsContainer pax={pax} />
        }
        {selectedTabIndex === 4 &&
          <PlanningDepartureNoteDetailsContainer pax={pax} />}
      </React.Fragment>
    );
  }

  private handleTabChange = (event, selectedTabIndex) => {
    this.setState({ selectedTabIndex });
    this.setState({ selectedChildTabIndex: 0 });
  };
}

export default withStyles(styles)(PlanningDepartureTabContainer);
