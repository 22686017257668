import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  bar: {
    backgroundColor: '#df6d27 !important',
    height: '5px'
  },
  container: {
    position: 'fixed',
    top: 0,
    zIndex: 1400,
    width: '100%'
  }
});
