import * as React from 'react';
import DepartureList from './DepartureList';
import DepartureSearchContainer from '../containers/DepartureSearchContainer';
import IDeparture from '../types/IDeparture';
import ICrew from '../types/ICrew';

interface IDispatchProps {
  actions?: any;
}

interface IProps {
  departures?: IDeparture[];
  selectedDepartureCrew?: ICrew[];
  showLoading?: any;
  hideLoading?: any;
  getAndDisplayFile?: any;
  isOperationsDashboardCovidSurveyEnabled: boolean;
}

type Props = IProps & IDispatchProps;

class Departures extends React.Component<Props> {
  public render() {
    return (
      <div>
        <DepartureSearchContainer />
        <DepartureList
          departures={this.props.departures}
          selectedDepartureCrew={this.props.selectedDepartureCrew}
          actions={this.props.actions}
          showLoading={this.props.showLoading}
          hideLoading={this.props.hideLoading}
          getAndDisplayFile={this.props.getAndDisplayFile}
          isOperationsDashboardCovidSurveyEnabled={this.props.isOperationsDashboardCovidSurveyEnabled}
        />
      </div>
    );
  }
}

export default Departures;
