import * as React from 'react';
import { connect } from 'react-redux';
import PlanningTransferSearch from './PlanningTransferSearch';
import IPlanningTransferSearchCriteria from 'common/types/IPlanningTransferSearchCriteria';
import { getSelectedDmcs } from '../../common/selectors/selectedDmcs';
import { bindActionCreators } from 'redux';
import actions from '../actions';

interface IDispatchProps {
  actions?: any;
}

export interface IOwnProps {
  initialSearchCriteria: IPlanningTransferSearchCriteria;
}

interface IProps {
  selectedDmcs: number[];
}

type Props = IProps & IDispatchProps & IOwnProps;

class PlanningTransferSearchContainer extends React.Component<Props> {
  public render() {
    return (
      <PlanningTransferSearch
        initialSearchCriteria={this.props.initialSearchCriteria}
        selectedDmcs={this.props.selectedDmcs}
        getTransfers={this.props.actions.getTransfers}
      />
    );
  }
}

const mapStateToProps = state => ({
  selectedDmcs: getSelectedDmcs(state)
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        getTransfers: actions.planningTransfers.getTransfersBySearchCriteria
      },
      dispatch
    )
  }
});

export default connect<IProps, {}, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PlanningTransferSearchContainer);
