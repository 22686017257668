import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  icon: {
    float: 'right'
  },
  typography: {
    display: 'block'
  },
  subHeaderText: {
    marginTop: '4px',
    'font-weight': '500',
    display: 'block'
  }
});
