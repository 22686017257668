export const ROUTE_INDEX = '/';

export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_PAX = '/departures/:id/pax';

export const ROUTE_NOTFOUND = '/NotFound';
export const ROUTE_ACCESSDENIED = '/AccessDenied';
export const ROUTE_REPORTING_TRIPREPORT =
  '/reporting/departure/:departureId/crewMember/:crewId/tripReport';

// Planning
export const ROUTE_PLANNING_TRANSFERS = '/planning/transfers';
export const ROUTE_PLANNING_DEPARTURES = '/planning/departures';

export const ROUTE_PLANNING_DEPARTURE_DETAILS = '/planning/departures/:id';
export const ROUTE_PLANNING_ACCOMMODATION = '/planning/accommodation';
export const ROUTE_COVID_QUESTIONNAIRE = '/departures/:id/CovidHub';

// Planning Dashboard
export const ROUTE_PLANNING_DASHBOARD = '/planning/dashboard';
