import * as React from 'react';
import { connect } from 'react-redux';
import PlanningAccommodationSearch from '../components/PlanningAccommodationSearch';
import IPlanningAccommodationSearchCriteria from 'common/types/IPlanningAccommodationSearchCriteria';
import { getSelectedDmcs } from '../../common/selectors/selectedDmcs';
import { bindActionCreators } from 'redux';
import actions from '../actions';

interface IDispatchProps {
  actions?: any;
}

export interface IOwnProps {
  initialSearchCriteria: IPlanningAccommodationSearchCriteria;
}

interface IProps {
  selectedDmcs: string[];
}

type Props = IProps & IDispatchProps & IOwnProps;

class PlanningAccommodationSearchContainer extends React.Component<Props> {
  public render() {
    return (
      <PlanningAccommodationSearch
        initialSearchCriteria={this.props.initialSearchCriteria}
        selectedDmcs={this.props.selectedDmcs}
        getAccommodation={this.props.actions.getAccommodation}
      />
    );
  }
}

const mapStateToProps = state => ({
  selectedDmcs: getSelectedDmcs(state)
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        getAccommodation:
          actions.planningAccommodation.getAccommodationBySearchCriteria
      },
      dispatch
    )
  }
});

export default connect<IProps, {}, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PlanningAccommodationSearchContainer);
