import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  dateFilterContainer: {
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  dateFilterVerticalAlignment: {
    display: 'flex',
    'flex-direction': 'column'
  },
  dateFilterHorizontalAlignment: {
    display: 'flex',
    'flex-direction': 'row'
  }
});
