import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import * as routes from 'common/constants/routes';
import AuthorizedWrapper from './AuthorizedWrapper';

interface IProtectedRouteProps extends RouteProps {
  enabled: boolean;
  component;
}

const ProtectedRoute = ({
  enabled,
  component: Component,
  ...rest
}: IProtectedRouteProps) => (
  <Route
    {...rest}
    render={props =>
      enabled ? (
        <AuthorizedWrapper>
          <Component {...props} />
        </AuthorizedWrapper>
      ) : (
        <Redirect
          to={{
            pathname: routes.ROUTE_NOTFOUND
          }}
        />
      )
    }
  />
);

export default ProtectedRoute;
