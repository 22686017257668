export default function displayFile(blob) {
  const anchor = document.createElement('a');
  const objectUrl = window.URL.createObjectURL(blob);

  anchor.href = objectUrl;

  anchor.target = '_blank';
  document.body.appendChild(anchor);
  anchor.click();

  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(objectUrl);
}
