import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  runWithReactAdal,
  authContext
} from './common/authentication/adalConfig';
import Unauthorized401 from 'common/components/Unauthorized401';
import App from './core/components/App';
import { store, persistor } from 'common/store';
import trackjs from 'common/helpers/trackjs';
import './index.css';
import { PersistGate } from 'redux-persist/integration/react';

// Access denied
if (window.location.href.includes('error=access_denied')) {
  ReactDOM.render(<Unauthorized401 />, document.getElementById('root'));
} else {
  runWithReactAdal(() => {
    trackjs(authContext);
    ReactDOM.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>,
      document.getElementById('root') as HTMLElement
    );
  });
}
