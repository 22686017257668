import * as actionTypes from '../../actionTypes/crew';
import ICrew from '../../types/ICrew';

type State = ICrew[] | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.CREW_GET_SUCCESS:
      return action.payload.selectedDepartureCrew;
    default:
      return state;
  }
};
