import * as actionTypes from '../actionTypes/dmc';
import IDmc from '../types/IDmc';

type State = IDmc[] | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.DMC_GET_SUCCESS:
      return action.payload.dmcs;
    default:
      return state;
  }
};
