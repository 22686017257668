import * as actionTypes from '../../actionTypes/planningDepartures/search';
import IPlanningDepartureSearchCriteria from 'common/types/IPlanningDepartureSearchCriteria';

type State = IPlanningDepartureSearchCriteria;

const currentDate: Date = new Date();
const defaultSearchCriteria: IPlanningDepartureSearchCriteria = {
  startDateFrom: currentDate,
  startDateTo: null,
  updatedSince: null,
  departureCode: '',
  statuses: [] // Active, Inactive, Canceled
};

export default (state: State = defaultSearchCriteria, action) => {
  switch (action.type) {
    case actionTypes.PLANNING_DEPARTURES_SEARCH_CRITERIA_SET:
      return action.payload.searchCriteria;
    default:
      return state;
  }
};
