import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  root: {
    width: '100%',
    backgroundColor: '#fff',
    paddingBottom: 0
  },
  secondaryContainer: {
    paddingLeft: '10px'
  },
  subHeader: {
    backgroundColor: '#eee',
    color: '#000'
  },
  listContainer: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  paxName: {
    display: 'block',
    'line-height': '18px'
  },
  flightLabel: {
    'font-weight': '500'
  }
});
