import { format, parseISO } from 'date-fns';
import env from 'common/constants/env';

export default function formatDate(
  date: number | Date | string,
  formatStr: string = env.REACT_APP_DATE_FORMAT
) {
  if (typeof date === 'string') {
    date = parseISO(date);
  }

  return format(date, formatStr, {
    useAdditionalDayOfYearTokens: true,
    useAdditionalWeekYearTokens: true
  });
}
