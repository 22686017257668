import { combineReducers } from 'redux';
import searchResults from './searchResults';
import searchCriteria from './searchCriteria';
import selectedDeparture from './selectedDeparture';

export default combineReducers({
  searchCriteria: searchCriteria,
  searchResults: searchResults,
  selectedDeparture: selectedDeparture
});
