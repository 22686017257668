import { AppState } from 'common/reducers/reducer';
import operationsPortalService from 'common/services/OperationsPortalService';
import * as actionTypes from '../../actionTypes/planningDepartures/selectedDeparture';
import * as selectors from '../../selectors/planningDepartures/selectedDeparture';
import IPlanningDeparture from 'planning/types/IPlanningDeparture';

function shouldFetch(state: AppState, departureId: number) {
  const stateDeparture = selectors.getSelectedDeparture(state);

  return !stateDeparture || stateDeparture.id !== departureId;
}

const departuresGetByIdRequest = (departureId: number) => ({
  type: actionTypes.PLANNING_DEPARTURE_GET_REQUEST,
  payload: {
    departureId: departureId
  }
});

const departureByIdGetSuccess = departure => ({
  type: actionTypes.PLANNING_DEPARTURE_GET_SUCCESS,
  payload: {
    selectedDeparture: departure
  }
});

const departureClear = () => ({
  type: actionTypes.PLANNING_DEPARTURE_CLEAR
});

const departureGetFailure = () => ({
  type: actionTypes.PLANNING_DEPARTURE_GET_FAILURE
});

export const getDepartureById = (departureId: number) => async (
  dispatch,
  getState
) => {
  try {
    if (!shouldFetch(getState(), departureId)) {
      return null;
    }
    dispatch(departureClear());

    dispatch(departuresGetByIdRequest(departureId));

    const data = await operationsPortalService.PlanningDepartures.getById(departureId);

    dispatch(departureByIdGetSuccess(data as IPlanningDeparture));
    return;
  } catch (e) {
    dispatch(departureGetFailure());
    throw e;
  }
};
