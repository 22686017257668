import { Button, Card, CardContent, CircularProgress, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import CustomSelectField from 'common/components/CustomSelectField';
import DateRangeFilter from 'common/forms/DateRangeFilter';
import { store } from 'common/store';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import planningPortalService from 'reports/services/PlanningPortalServices';
import IDepartureStatusCriteria from 'reports/types/IDepartureStatusCriteria';
import avoidEnterOnKeyPress from '../../../common/helpers/avoidEnterOnKeyPress';
import { addWeeks } from 'date-fns';
import styles from './DepartureStatusReport.styles'
import { getSelectedOperator } from 'reports/selectors/selectedOperator';
import { TripCodes } from 'reports/types/ICommon';

interface IProps extends WithStyles<typeof styles> {
  tripCodes: TripCodes
}


class DepartureStatusReport extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

  }

  public render() {
    const classes = this.props.classes;
    // Initial Values for the form
    const intialValues = {
      tripCode: [],
      departureStatusFrom: new Date(),
      departureStatusTo: addWeeks(new Date(), 2),
    };

    const onSubmit = async (values) => {
      try {
        const selectedOperator = getSelectedOperator(store.getState());
        const apiBodyCriteria: IDepartureStatusCriteria = {
          tripCodes: values.tripCode,
          departureDateFrom: values.departureStatusFrom,
          departureDateTo: values.departureStatusTo,
          operatorId: selectedOperator,
        }
        await planningPortalService.DepartureStatus.getDepartureStatusReport(apiBodyCriteria, 'csv')

      } catch (e) {
        throw e;
      }
    };

    return (
      <Card>
        <CardContent>
          <Typography variant='h6' className={classes.titleText}>Departure Status Report</Typography>
          <Form
            onSubmit={onSubmit}
            initialValues={intialValues}

            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit} noValidate={true} onKeyPress={avoidEnterOnKeyPress}>
                <div className={classes.reportWrapper}>
                  <div className={classes.reportContainer}>
                    <div className={classes.inputsContainer}>
                      <Field
                        name={'tripCode'}
                        parse={(value) => {
                          return !value ? '' : value;
                        }}
                      >
                        {({ input }) => {
                          const { value, onChange } = input;

                          return (
                            <CustomSelectField
                              label="Trip Code"
                              value={value}
                              onChange={onChange}
                              options={this.props.tripCodes ? this.props.tripCodes : []}
                              clearable={true}
                              onClear={onChange}

                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div>
                      <DateRangeFilter
                        id="departureStatus"
                      />
                    </div>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      id="btn.get"
                      disabled={submitting}
                    >
                      Get Report
                    </Button>
                    {submitting && <div className={classes.spinnerContainer}>
                      <CircularProgress size={25} />
                    </div>}
                  </div>
                </div>
              </form>
            )}
          />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(DepartureStatusReport);