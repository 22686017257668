import { getSelectedDmcs } from 'common/selectors/selectedDmcs';
import { store } from 'common/store';
import planningPortalService from 'reports/services/PlanningPortalServices';
import { Operators } from 'reports/types/ICommon';
import * as actionTypes from '../actionTypes/operators';
import { selectedOperator } from './selectedOperator';

// This is the Operator List Get Action
export const operatorListGetRequest = (operatorList: Operators) => ({
  type: actionTypes.GET_OPERATOR_LIST,
  payload: {
    operatorList,
  },
});

// This is the async method to get the Operator List
export const getOperatorListByUser = () => async (dispatch, getstate) => {
  try {
    dispatch(operatorListGetRequest([]));
    const selectedDMC = getSelectedDmcs(store.getState())[0];

    const data = await planningPortalService.Operators.getOperators(
      selectedDMC
    );

    dispatch(
      operatorListGetRequest(
        data.map(({ id, name }) => ({ value: id, text: name }))
      )
    );

    const defaultOperator: string = data && data.length > 0 ? data[0].id : '';

    dispatch(selectedOperator(defaultOperator));
  } catch (e) {
    throw e;
  }
};
