import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  durationIcon: {
    height: '15px'
  },
  label: {
    'font-weight': 'bold',
    display: 'inline-block'
  },
  infoContainer: {
    display: 'grid',
    gridTemplateColumns: '100px auto'
  }
});
