import * as actionTypes from '../actionTypes/selectedOperator';

export const selectedOperator = (selectedOperator: string) => ({
  type: actionTypes.SET_SELECTED_OPERATOR,
  payload: {
    selectedOperator,
  },
});

export const setSelectedOperator = (values: string) => (dispatch) => {
  dispatch(selectedOperator(values));
};
