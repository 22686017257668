import * as React from 'react';
import IPlanningDeparture from '../types/IPlanningDeparture';
import styles from './PlanningDepartureExtendedDetails.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import convertBooleanToText from 'common/helpers/convertBooleanToText';
import KeyValueItem, {
  KeyValueItemOrientation
} from '../../common/components/KeyValueItem';
import Grid from '@material-ui/core/Grid';
import { EMPTY_FIELD_TEXT } from 'common/constants/constants';
import formatDate from 'common/helpers/formatDate';
import { Card, CardContent, Typography, ListItem, ListItemText } from '@material-ui/core';
import convertToPascalCase from 'common/helpers/convertToPascalCase';

interface IProps extends WithStyles<typeof styles> {
  departure: IPlanningDeparture;
}


class PlanningDepartureExtendedDetails extends React.Component<IProps> {
  public render() {
    const { departure, classes } = this.props;

    return (
      <div key={departure.id} className={classes.departureContainer}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Departure
            </Typography>

                <KeyValueItem
                  label="Status:"
                  value={departure.status}
                  orientation={KeyValueItemOrientation.horizontal}
                />
                <KeyValueItem
                  label="Status updated:"
                  value={
                    departure.departureStatusLastUpdated
                      ? formatDate(departure.departureStatusLastUpdated)
                      : EMPTY_FIELD_TEXT
                  }
                  orientation={KeyValueItemOrientation.horizontal}
                />

                <KeyValueItem
                  label="Stop Sell:"
                  value={convertBooleanToText(departure.stopSell)}
                  orientation={KeyValueItemOrientation.horizontal}
                />

                <KeyValueItem
                  label="Departure Guaranteed:"
                  value={convertBooleanToText(departure.guaranteedToDepart)}
                  orientation={KeyValueItemOrientation.horizontal}
                />

                <KeyValueItem
                  label="Departure Created:"
                  value={formatDate(departure.createdDate)}
                  orientation={KeyValueItemOrientation.horizontal}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Availability
            </Typography>

                <KeyValueItem
                  label="Capacity:"
                  value={departure.paxMaxCapacity || EMPTY_FIELD_TEXT}
                  orientation={KeyValueItemOrientation.horizontal}
                />
                <KeyValueItem
                  label="Available:"
                  value={departure.paxAvailable}
                  orientation={KeyValueItemOrientation.horizontal}
                />
                <KeyValueItem
                  label="Confirmed:"
                  value={departure.paxConfirmed}
                  orientation={KeyValueItemOrientation.horizontal}
                />
                <KeyValueItem
                  label="Tentative:"
                  value={departure.paxTentative}
                  orientation={KeyValueItemOrientation.horizontal}
                />
                <KeyValueItem
                  label="On Request:"
                  value={departure.paxOnRequest}
                  orientation={KeyValueItemOrientation.horizontal}
                />
                <KeyValueItem
                  label="Super:"
                  value={departure.paxOnPackage}
                  orientation={KeyValueItemOrientation.horizontal}
                />
                <KeyValueItem
                  label="Availability updated:"
                  value={
                    departure.departureAvailabilityLastUpdated
                      ? formatDate(departure.departureAvailabilityLastUpdated)
                      : EMPTY_FIELD_TEXT
                  }
                  orientation={KeyValueItemOrientation.horizontal}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Single Supps
            </Typography>
                <KeyValueItem
                  label="Confirmed:"
                  value={departure.paxCountWithSingleSupplementConfirmed}
                  orientation={KeyValueItemOrientation.horizontal}
                />
                <KeyValueItem
                  label="On Request:"
                  value={departure.paxCountWithSingleSupplementOnRequest}
                  orientation={KeyValueItemOrientation.horizontal}
                />
                <KeyValueItem
                  label="Last Updated:"
                  value={
                    departure.singleSuppLastUpdated
                      ? formatDate(departure.singleSuppLastUpdated)
                      : EMPTY_FIELD_TEXT
                  }
                  orientation={KeyValueItemOrientation.horizontal}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Crew
            </Typography>
                {
                  this.CrewDetails(departure)
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }

  private CrewDetails(departure: IPlanningDeparture): React.ReactNode {
    return departure.crewList.length !== 0 ?
      departure.crewList.map((item) => <ListItem key={item.id}>
        <ListItemText
          primary={`${item.firstName} ${item.surname} - ${convertToPascalCase(item.role.toLowerCase())}`} />
      </ListItem>
      ) :
      <ListItem>
        <ListItemText>
          Unassigned
        </ListItemText>
      </ListItem>
      ;
  }
}

export default withStyles(styles)(PlanningDepartureExtendedDetails);
