import ApiError from 'common/errors/ApiError';

class NotFoundError extends ApiError {
  constructor(message?: string, ...params: any) {
    const calculatedMessage =
      message === undefined ? 'Unable to find resource' : '';
    super('Not Found Error', calculatedMessage, params, new Error().stack);
  }
}

export default NotFoundError;
