import * as actionTypes from '../../actionTypes/planningDepartures/search';
import IPlanningDepartureSearchCriteria from 'common/types/IPlanningDepartureSearchCriteria';
import operationsPortalService from 'common/services/OperationsPortalService';
import statuses from 'planning/enums/statuses';

const departuresGetBySearchCriteriaRequest = () => ({
  type: actionTypes.PLANNING_DEPARTURES_GET_REQUEST
});

const departuresGetSuccess = departures => ({
  type: actionTypes.PLANNING_DEPARTURES_GET_SUCCESS,
  payload: {
    departures: departures
  }
});

const departuresGetFailure = () => ({
  type: actionTypes.PLANNING_DEPARTURES_GET_FAILURE
});

export const getDeparturesBySearchCriteria = (
  searchCriteria: IPlanningDepartureSearchCriteria
) => async dispatch => {
  try {
    dispatch(departuresGetBySearchCriteriaRequest());

    const data = await operationsPortalService.PlanningDepartures.getBySearchCriteria(
      {
        ...searchCriteria,
        // Make sure all statuses are sent to the API when none are selected
        // this is done here so that it doesn't affect the state/ui
        statuses:
          searchCriteria.statuses.length > 0
            ? searchCriteria.statuses
            : statuses.map(v => v.value)
      }
    );

    dispatch(departuresGetSuccess(data));
  } catch (e) {
    dispatch(departuresGetFailure());
    throw e;
  }
};

const searchCriteriaSetRequest = (
  searchCriteria: IPlanningDepartureSearchCriteria
) => ({
  type: actionTypes.PLANNING_DEPARTURES_SEARCH_CRITERIA_SET,
  payload: {
    searchCriteria
  }
});

export const setSearchCriteria = (
  values: IPlanningDepartureSearchCriteria
) => dispatch => {
  dispatch(searchCriteriaSetRequest(values));
};
