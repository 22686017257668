import * as actionTypes from '../actionTypes/departureSearch';
import IDeparture from '../types/IDeparture';

type State = IDeparture[] | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.DEPARTURES_GET_SUCCESS:
      return action.payload.departures;
    default:
      return state;
  }
};
