import IDepartureSearchCriteria from '../types/IDepartureSearchCriteria';
import convertToAPIDate from 'common/helpers/convertToAPIDate';
import { startOfDay, endOfDay } from 'date-fns';

export default class Departures {
  public service: any;

  constructor(service) {
    this.service = service;
  }

  public async getBySearchCriteria(searchCriteria: IDepartureSearchCriteria) {
    const resourcePath = `/departures`;

    // Replace dates to send strings
    const searchCriteriaModified = {
      ...searchCriteria,
      startDateFrom: convertToAPIDate(startOfDay(searchCriteria.startDateFrom)),
      startDateTo: convertToAPIDate(endOfDay(searchCriteria.startDateTo))
    };

    const data = await this.service.post(resourcePath, searchCriteriaModified);

    return data.body;
  }

  public async getById(departureId: number) {
    const resourcePath = `/departure/${encodeURIComponent(
      departureId.toString()
    )}`;

    const data = await this.service.get(resourcePath);
    return data.body;
  }
}
