import { Button, Card, CardContent, CircularProgress, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import CustomTextField from 'common/components/CustomTextField';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import avoidEnterOnKeyPress from '../../../common/helpers/avoidEnterOnKeyPress';

import styles from './DepartureManifestReport.styles';
import planningPortalService from 'reports/services/PlanningPortalServices';
import { store } from 'common/store';
import IDepartureManifestCriteria from 'reports/types/IDepartureManifestCriteria';
import { getSelectedOperator } from 'reports/selectors/selectedOperator';


interface IState {
  emptyReport: boolean,
}

type IProps = WithStyles<typeof styles>;

class DepartureManifestReport extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { emptyReport: false }
  }

  public render() {
    const classes = this.props.classes;
    const intialValues = {
      departureCode: '',
    };

    const onSubmit = async (values) => {
      try {
        this.setState({ emptyReport: false })
        const selectedOperator = getSelectedOperator(store.getState());
        const apiBodyCriteria: IDepartureManifestCriteria = {
          departureCode: values.departureCode.trim().toUpperCase(),
          operatorId: selectedOperator,
        }
        const response = await planningPortalService.DepartureManifest.getDepartureManifestReport(apiBodyCriteria, 'xls')
        if (!response) {
          this.setState({ emptyReport: true })
        }
      } catch (e) {
        throw e;
      }
    };

    return (
      <Card>
        <CardContent>
          <Typography variant='h6' className={classes.titleText}>Departure Manifest Report</Typography>
          <Form
            onSubmit={onSubmit}
            initialValues={intialValues}

            render={({ handleSubmit,
              submitting,
            }) => (

              <form onSubmit={handleSubmit} noValidate={true} onKeyPress={avoidEnterOnKeyPress}>

                <div className={classes.reportWrapper}>
                  <div className={classes.reportContainer}>
                    <div className={classes.inputsContainer}>
                      <Field
                        name={'departureCode'}
                        validate={(value) =>
                          (!value || value.length < 8)
                        }

                      >
                        {({ input, meta }) => {
                          const { value, onChange } = input;
                          return (
                            <>
                              <CustomTextField
                                label="Departure Code"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  this.setState({ emptyReport: false })
                                }}
                                clearable={true}
                                onClear={onChange}
                                error={meta.touched && meta.submitFailed && meta.error !== undefined}
                                helperText={meta.touched && meta.submitFailed &&
                                  meta.error && 'Departure Code should contain atleast 8 Characters'}
                              />

                            </>
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      id="btn.get"
                      disabled={submitting}
                    >
                      Get Report
                    </Button>
                    {submitting && <div className={classes.spinnerContainer}>
                      <CircularProgress size={25} />
                    </div>}
                  </div>
                  <div className={classes.errorContainer}>
                    {this.state.emptyReport && <Typography className={classes.errorText}>Could not Generate Report for the provided Departure Code</Typography>}
                  </div>

                </div>
              </form>
            )}
          />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(DepartureManifestReport);