import { MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import muiTheme from '../themes/muiTheme';
import { BrowserRouter } from 'react-router-dom';
import {
  createGenerateClassName,
  jssPreset,
  StylesProvider
} from '@material-ui/styles';
import { create } from 'jss';
import extend from 'jss-extend';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './App.css';
import ProgressIndicator from 'common/components/ProgressIndicator';
import CustomToolbar from 'common/components/CustomToolbar';
import BrowserNotSupportedMessage from 'common/components/BrowserNotSupportedMessage';
import GlobalMessagesContainer from 'common/containers/GlobalMessagesContainer';
import env from '../../common/constants/env';
import RouterContainer from './RouterContainer';
import actions from 'systemConfigs/actions';
import selectors from 'systemConfigs/selectors';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IS_OPERATIONS_DASHBOARD_COVID_SURVEY_ENABLED } from 'common/constants/systemConfigCode';

const generateClassName = createGenerateClassName();
const jss = create({
  plugins: [...jssPreset().plugins, extend()],
  insertionPoint: document.getElementById('jss-insertion-point') as HTMLElement
});

interface IAppProps {
  actions?: any;
}

interface IProps {
  isOperationsDashboardCovidSurveyEnabled: boolean;
}

type Props = IAppProps & IProps;

class App extends React.Component<Props> {
  public componentDidMount() {
    document.title = env.REACT_APP_NAME;
    this.loadData();
  }

  public loadData = async () => {
    await this.props.actions.getSystemConfigsByCodes(
      IS_OPERATIONS_DASHBOARD_COVID_SURVEY_ENABLED
    );
  };

  public render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <StylesProvider jss={jss} generateClassName={generateClassName}>
            <MuiThemeProvider theme={muiTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <BrowserNotSupportedMessage />
                <ProgressIndicator />
                <CustomToolbar />
                <RouterContainer />
              </MuiPickersUtilsProvider>
              <GlobalMessagesContainer />
            </MuiThemeProvider>
          </StylesProvider>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isOperationsDashboardCovidSurveyEnabled: selectors.systemConfigs.getIsOperationsDashboardCovidSurveyEnabled(
    state,
    IS_OPERATIONS_DASHBOARD_COVID_SURVEY_ENABLED
  )
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSystemConfigsByCodes: actions.systemConfigs.getSystemConfigsByCodes
    },
    dispatch
  )
});

export default connect<IProps>(
  mapStateToProps,
  mapDispatchToProps
)(App);
