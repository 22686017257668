import { Button, Card, CardContent, CircularProgress, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import CustomSelectField from 'common/components/CustomSelectField';
import CustomTextField from 'common/components/CustomTextField';
import DateRangeFilter from 'common/forms/DateRangeFilter';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import avoidEnterOnKeyPress from '../../../common/helpers/avoidEnterOnKeyPress';

import styles from './ExtraServicesReport.styles';
import { CITY } from 'reports/constants/common';
import planningPortalService from 'reports/services/PlanningPortalServices';
import { store } from 'common/store';
import IExtraServicesCriteria from 'reports/types/IExtraServicesCriteria';
import productType from 'reports/enums/productType';
import productSubType from 'reports/enums/productSubType';
import { addWeeks } from 'date-fns';
import { getSelectedOperator } from 'reports/selectors/selectedOperator';
import { Countries } from 'reports/types/ICommon';
interface IProps extends WithStyles<typeof styles> {
  heading: string;
  productType: productType;
  productSubType: productSubType;
  countryList: Countries
}

class ExtraServices extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const classes = this.props.classes;
    const intialValues = {
      departureCode: '',
      startDateFrom: new Date(),
      startDateTo: addWeeks(new Date(), 2),
      country: [],
      city: [],
    };

    const onSubmit = async (values) => {
      try {
        const selectedOperator = getSelectedOperator(store.getState());
        const apiBodyCriteria: IExtraServicesCriteria = {
          product: this.props.productType,
          productSubType: this.props.productSubType,
          departureCode: values.departureCode.trim(),
          dateFrom: values.startDateFrom,
          dateTo: values.startDateTo,
          countries: values.country,
          cities: values.city,
          operatorId: selectedOperator,
        }
        await planningPortalService.ExtraServices.getExtraServicesReport(apiBodyCriteria, 'csv')

      } catch (e) {
        throw e;
      }
    };

    return (
      <Card>
        <CardContent>
          <Typography variant='h6' className={classes.titleText}>{this.props.heading}</Typography>
          <Form
            onSubmit={onSubmit}
            initialValues={intialValues}

            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit} noValidate={true} onKeyPress={avoidEnterOnKeyPress}>
                <div className={classes.reportWrapper}>
                  <div className={classes.reportContainer}>
                    <div className={classes.inputsContainer}>
                      <Field
                        name={'departureCode'}
                        parse={(value) => {
                          return !value ? '' : value;
                        }}
                      >
                        {({ input }) => {
                          const { value, onChange } = input;

                          return (
                            <CustomTextField
                              label="Departure Code"
                              value={value}
                              onChange={onChange}
                              clearable={true}
                              onClear={onChange}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div>
                      <DateRangeFilter
                        id="startDate"

                      />
                    </div>
                    <div className={classes.inputsContainer}>
                      <Field
                        name={'country'}
                        parse={(value) => {
                          return !value ? '' : value;
                        }}
                      >
                        {({ input }) => {
                          const { value, onChange } = input;

                          return (
                            <CustomSelectField
                              label="Country"
                              value={value}
                              onChange={onChange}
                              options={this.props.countryList}
                              clearable={true}
                              onClear={onChange}

                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className={classes.inputsContainer}>
                      <Field
                        name={'city'}
                        parse={(value) => {
                          return !value ? '' : value;
                        }}
                      >
                        {({ input }) => {
                          const { value, onChange } = input;

                          return (
                            <CustomSelectField
                              label="City"
                              value={value}
                              onChange={onChange}
                              options={CITY.map(({ text }) => ({ value: text, text: text }))}
                              clearable={true}
                              onClear={onChange}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      id="btn.get"
                      disabled={submitting}
                    >
                      Get Report
                    </Button>
                    {submitting && <div className={classes.spinnerContainer}>
                      <CircularProgress size={25} />
                    </div>}


                  </div>

                </div>

              </form>
            )}
          />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(ExtraServices);