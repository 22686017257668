import { createStyles } from '@material-ui/styles';

export default createStyles({
  container: {
    backgroundColor: 'red'
  },
  content: {
    textAlign: 'center',
    color: 'white',
    display: 'block'
  }
});
