import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import * as routes from 'common/constants/routes';
import NotFound404 from 'common/components/NotFound404';
import Forbidden403 from 'common/components/Forbidden403';
import Analytics from './Analytics';

import DeparturesContainer from 'departures/containers/DeparturesContainer';
import PaxDetailsContainer from 'departures/components/PaxDetailsContainer';
import PlanningTransferContainer from 'planning/components/PlanningTransferContainer';
import PlanningAccommodationContainer from 'planning/components/PlanningAccommodationContainer';
import PlanningDepartureContainer from 'planning/components/PlanningDepartureContainer';
import PlanningDepartureDetailsContainer from 'planning/components/PlanningDepartureDetailsContainer';
import { authContext } from 'common/authentication/adalConfig';
import ProtectedRoute from './ProtectedRoute';
import env from 'common/constants/env';
import { withRouter, RouteComponentProps } from 'react-router';
import { messageTypes } from 'common/actions/globalMessages';
import versionService from 'common/services/VersionService';
import CovidQuestionnaireResponsesContainer from '../../departures/components/CovidQuestionnaireResponsesContainer';
// New Planning Dashboard Container Page
import PlanningDashboardContainer from 'reports/containers/PlanningDashboardContainer';
import AuthorizedWrapper from './AuthorizedWrapper';


const user = authContext.getCachedUser();

interface IDispatchProps {
  actions?: any;
}

type Props = IDispatchProps & RouteComponentProps;

class Router extends React.Component<Props> {
  public componentDidMount() {
    // check the version on every navigation
    this.props.history.listen((location, action) => {
      versionService.getVersion().then(data => {
        if (data.version !== env.REACT_APP_VERSION) {
          this.props.actions.addMessage(
            'A new version of this app is available.',
            messageTypes.info,
            true,
            true
          );
        }
      });
    });
  }

  public render() {
    return (
      <Analytics userId={user.profile.oid}>
        <Switch>
          <Route exact={true} path={routes.ROUTE_INDEX}>
            <Redirect to={routes.ROUTE_DASHBOARD} />
          </Route>

          <Route
            exact={true}
            path={routes.ROUTE_DASHBOARD}
            render={() => <AuthorizedWrapper>< DeparturesContainer /></AuthorizedWrapper>}
          />


          <Route
            exact={true}
            path={routes.ROUTE_PLANNING_DASHBOARD}
            render={() => <AuthorizedWrapper> <PlanningDashboardContainer /></AuthorizedWrapper>}
          />
          <Route
            exact={true}
            path={routes.ROUTE_PAX}
            render={props => <AuthorizedWrapper><PaxDetailsContainer {...props} /></AuthorizedWrapper>}
          />
          <Route
            exact={true}
            path={routes.ROUTE_COVID_QUESTIONNAIRE}
            render={props => <AuthorizedWrapper> <CovidQuestionnaireResponsesContainer {...props} /></AuthorizedWrapper>}
          />
          <ProtectedRoute
            enabled={env.REACT_APP_OPERATIONS_PORTAL_FEATURE_TRANSFERS}
            exact={true}
            path={routes.ROUTE_PLANNING_TRANSFERS}
            component={PlanningTransferContainer}
          />
          <ProtectedRoute
            enabled={env.REACT_APP_OPERATIONS_PORTAL_FEATURE_ACCOMMODATION}
            exact={true}
            path={routes.ROUTE_PLANNING_ACCOMMODATION}
            component={PlanningAccommodationContainer}
          />
          <ProtectedRoute
            enabled={
              env.REACT_APP_OPERATIONS_PORTAL_FEATURE_PLANNING_DEPARTURES
            }
            exact={true}
            path={routes.ROUTE_PLANNING_DEPARTURES}
            component={PlanningDepartureContainer}
          />
          <ProtectedRoute
            enabled={
              env.REACT_APP_OPERATIONS_PORTAL_FEATURE_PLANNING_DEPARTURES
            }
            exact={true}
            path={routes.ROUTE_PLANNING_DEPARTURE_DETAILS}
            component={PlanningDepartureDetailsContainer}
          />
          <Route
            path={routes.ROUTE_NOTFOUND}
            exact={true}
            component={NotFound404}
          />
          <Route
            path={routes.ROUTE_ACCESSDENIED}
            exact={true}
            component={Forbidden403}
          />
          <Route path="*" exact={true} component={NotFound404} />
        </Switch>
      </Analytics>
    );
  }
}

export default withRouter(Router);
