import * as React from 'react';
import { Field } from 'react-final-form';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from './DateRangeFilter.styles';
import isValidDateFormat from './validators/isValidDateFormat';
import InlineDatePicker from './InlineDatePicker';
import isPopulated from './validators/isPopulated';
import { compareDesc } from 'date-fns';
import isAfterDate from './validators/isAfterDate';

interface IProps extends WithStyles<typeof styles> {
  id: string;
  labelFrom?: string;
  labelTo?: string;
  isToRequired?: boolean;
  isDisabled?: boolean;
  minDateFrom?: Date;
  alignment?: string;
}

const DateRangeFilter: React.SFC<IProps> = ({
  id,
  labelFrom = 'From',
  labelTo = 'To',
  isToRequired = true,
  isDisabled = false,
  minDateFrom,
  alignment = 'vertical',
  classes
}) => {
  const fromFieldId = id + 'From';
  const toFieldId = id + 'To';

  return (
    <div
      className={
        alignment === 'vertical'
          ? classes.dateFilterVerticalAlignment
          : classes.dateFilterHorizontalAlignment
      }
    >
      <div className={classes.dateFilterContainer}>
        <Field
          name={fromFieldId}
          allowNull={true}
          validate={(value, allValues) =>
            isPopulated(value) ||
            isValidDateFormat(value) ||
            isAfterDate(value, minDateFrom)
          }
        >
          {({ input, meta }) => {
            const { value, onChange, ...restInput } = input;

            return (
              <InlineDatePicker
                id={fromFieldId}
                label={labelFrom}
                value={value}
                {...restInput}
                onChange={onChange}
                disabled={isDisabled}
                minDate={minDateFrom}
                error={meta.touched && meta.error !== undefined}
                helperText={
                  meta.touched &&
                  meta.error && <React.Fragment>{meta.error}</React.Fragment>
                }
              />
            );
          }}
        </Field>
      </div>
      <div className={classes.dateFilterContainer}>
        <Field
          name={toFieldId}
          allowNull={true}
          validate={(value, allValues) =>
            (isToRequired && isPopulated(value)) ||
            isValidDateFormat(value) ||
            isAfterDate(
              value,
              getMostRecentDate(allValues[fromFieldId], minDateFrom)
            )
          }
        >
          {({ input, meta }) => {
            const { value, onChange, ...restInput } = input;

            return (
              // create reference to fromDate field for minDate restriction
              <Field name={fromFieldId}>
                {({ input: fromInput }) => (
                  <InlineDatePicker
                    id={toFieldId}
                    label={labelTo}
                    value={value}
                    {...restInput}
                    onChange={onChange}
                    disabled={isDisabled}
                    minDate={getMostRecentDate(fromInput.value, minDateFrom)}
                    error={meta.touched && meta.error !== undefined}
                    helperText={
                      meta.touched &&
                      meta.error && (
                        <React.Fragment>{meta.error}</React.Fragment>
                      )
                    }
                  />
                )}
              </Field>
            );
          }}
        </Field>
      </div>
    </div>
  );
};

function getMostRecentDate(
  ...dates: Array<Date | undefined>
): Date | undefined {
  const validDates = dates.filter(x => x && !isNaN(x.getTime())) as Date[];

  if (validDates.length > 0) {
    const sortedDates = validDates.sort(compareDesc);
    return sortedDates[0];
  }

  return undefined;
}

export default withStyles(styles)(DateRangeFilter);
