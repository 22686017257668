import IPlanningAccommodationSearchCriteria from '../types/IPlanningAccommodationSearchCriteria';
import convertToAPIDate from 'common/helpers/convertToAPIDate';
import { startOfDay, endOfDay } from 'date-fns';

export default class PlanningAccomodation {
  public service: any;

  constructor(service) {
    this.service = service;
  }

  public async getBySearchCriteria(
    searchCriteria: IPlanningAccommodationSearchCriteria
  ) {
    const resourcePath = `/accommodation`;

    // Replace dates to send strings
    const searchCriteriaModified = {
      ...searchCriteria,
      accommodationDateFrom: convertToAPIDate(
        startOfDay(searchCriteria.accommodationDateFrom)
      ),
      accommodationDateTo: searchCriteria.accommodationDateTo
        ? convertToAPIDate(endOfDay(searchCriteria.accommodationDateTo))
        : null
    };

    const data = await this.service.post(resourcePath, searchCriteriaModified);
    return data.body;
  }
}
