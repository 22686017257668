import { Component } from 'react';
import * as ReactGA from 'react-ga';
import { withRouter, RouteComponentProps } from 'react-router';
import env from '../../common/constants/env';

ReactGA.initialize(env.REACT_APP_GOOGLE_ANALYTICS_APP_ID);

interface IMatchParams {
  id: string;
}

interface IRouteComponentProps extends RouteComponentProps<IMatchParams> {}

interface IProps {
  userId: string;
}

type Props = IProps & IRouteComponentProps;

class Analytics extends Component<Props> {
  public componentDidMount() {
    const page = this.props.location.pathname;
    const departureId = this.props.match.params.id;
    this.trackPage(page, departureId);
  }

  public componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    const departureId = this.props.match.params.id;

    if (currentPage !== prevPage) {
      this.trackPage(currentPage, departureId);
    }
  }

  public render() {
    return this.props.children;
  }

  private trackPage = (page, departureId) => {
    ReactGA.set({ dimension1: departureId });
    ReactGA.set({ dimension2: this.props.userId });
    ReactGA.pageview(page);
  };
}

export default withRouter(Analytics);
