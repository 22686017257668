import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import AvatarLogo from 'common/components/AvatarLogo';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import DepartureGeneralDetails from './DepartureGeneralDetails';
import IDeparture from '../types/IDeparture';

interface IProps {
  departure: IDeparture;
}

class DepartureCard extends React.Component<IProps> {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  public render() {
    const { departure } = this.props;

    return (
      departure && (
        <React.Fragment>
          <Paper>
            <CardHeader
              avatar={
                <AvatarLogo
                  brand={departure.brand}
                  imgId={'img.header_' + departure.code}
                />
              }
              title={
                <Typography variant="body1" color="inherit">
                  <span>{`${departure.code} - ${departure.name}`}</span>
                </Typography>
              }
              subheader={<DepartureGeneralDetails departure={departure} />}
            />
          </Paper>
        </React.Fragment>
      )
    );
  }
}

export default DepartureCard;
