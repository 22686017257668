import convertStringToBoolean from 'common/helpers/convertStringToBoolean';

const env = {
  REACT_APP_VERSION: process.env.REACT_APP_VERSION!,
  REACT_APP_DATE_FORMAT: process.env.REACT_APP_DATE_FORMAT!,
  REACT_APP_TIME_FORMAT: process.env.REACT_APP_TIME_FORMAT!,
  REACT_APP_DATETIME_FORMAT: process.env.REACT_APP_DATETIME_FORMAT!,
  REACT_APP_DATETIME_API_FORMAT: process.env.REACT_APP_DATETIME_API_FORMAT!,
  REACT_APP_OPERATIONS_PORTAL_SERVICE_URL: process.env
    .REACT_APP_OPERATIONS_PORTAL_SERVICE_URL!,
  REACT_APP_OPERATIONS_PORTAL_API_AAD_GUID: process.env
    .REACT_APP_OPERATIONS_PORTAL_API_AAD_GUID!,
  REACT_APP_OPERATIONS_PORTAL_API_TENANT_URI: process.env
    .REACT_APP_OPERATIONS_PORTAL_API_TENANT_URI!,
  REACT_APP_OPERATIONS_PORTAL_API_REDIRECT_URI: process.env
    .REACT_APP_OPERATIONS_PORTAL_API_REDIRECT_URI!,
  REACT_APP_TRACKJS_TOKEN: process.env.REACT_APP_TRACKJS_TOKEN!,
  REACT_APP_TRACKJS_APPLICATION: process.env.REACT_APP_TRACKJS_APPLICATION!,
  REACT_APP_NAME: process.env.REACT_APP_NAME!,
  REACT_APP_SEARCH_LIMIT: process.env.REACT_APP_SEARCH_LIMIT!,
  REACT_APP_BROWSER_MIN_VERSION_CHROME: process.env
    .REACT_APP_BROWSER_MIN_VERSION_CHROME!,
  REACT_APP_GOOGLE_ANALYTICS_APP_ID: process.env
    .REACT_APP_GOOGLE_ANALYTICS_APP_ID!,
  REACT_APP_OPERATIONS_PORTAL_FEATURE_TRANSFERS: convertStringToBoolean(
    process.env.REACT_APP_OPERATIONS_PORTAL_FEATURE_TRANSFERS!
  ),
  REACT_APP_OPERATIONS_PORTAL_FEATURE_ACCOMMODATION: convertStringToBoolean(
    process.env.REACT_APP_OPERATIONS_PORTAL_FEATURE_ACCOMMODATION!
  ),
  REACT_APP_OPERATIONS_PORTAL_FEATURE_PLANNING_DEPARTURES: convertStringToBoolean(
    process.env.REACT_APP_OPERATIONS_PORTAL_FEATURE_PLANNING_DEPARTURES!
  )
};

export default env;

export type Env = typeof env;
