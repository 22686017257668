import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  titleText: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)"
  },
  textAlign: {
    textAlign: "center",
  },
  tabBackground: { backgroundColor: "white" }
});
