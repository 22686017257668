import * as React from 'react';
import { Paper, Tab, Tabs, withStyles } from '@material-ui/core';
import styles from './PlanningDeparturePaxDetailsContainer.styles';
import { WithStyles } from '@material-ui/styles';
import IPax from 'departures/types/IPax';
import PlanningDeparturePaxDetailsTable from './PlanningDeparturePaxDetailsTable';

interface IState {
  selectedChildTabIndex?: number;
}

interface IProps extends WithStyles<typeof styles> {
  pax: IPax[];
}

class PlanningDepartureExtraServiceDetailsContainer extends React.Component<
  IProps,
  IState
  > {
  constructor(props) {
    super(props);

    this.state = {
      selectedChildTabIndex: 0
    };
  }

  public render() {
    const { pax, classes } = this.props;

    const { selectedChildTabIndex } = this.state;

    return (
      <Paper className={classes.paper}>
        <Tabs
          value={this.state.selectedChildTabIndex}
          onChange={this.handleChildTabChange}
          className={classes.childTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            id={'btn.transfers'}
            label={'Transfers'}
          />
          <Tab
            id={'btn.prePostAccom'}
            label={'Pre/Post Accom'}
          />
          <Tab
            id={'btn.sightseeing'}
            label={'Sightseeing'}
          />
        </Tabs>
        {selectedChildTabIndex === 0 &&
          (
            <PlanningDeparturePaxDetailsTable pax={pax} />
          )}
        {selectedChildTabIndex === 1 &&
          (
            <PlanningDeparturePaxDetailsTable pax={pax} />
          )}
        {selectedChildTabIndex === 2 &&
          (
            <PlanningDeparturePaxDetailsTable pax={pax} />
          )}
      </Paper>
    );
  }
  private handleChildTabChange = (event, selectedChildTabIndex) => {
    this.setState({ selectedChildTabIndex });
  };
}

export default withStyles(styles)(PlanningDepartureExtraServiceDetailsContainer);
