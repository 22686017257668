import { combineReducers } from 'redux';
// Global Filtering
import dmcs from 'common/reducers/dmc';
import selectedDmcs from 'common/reducers/selectedDmcs';
// Operations - Departures
import departures from 'departures/reducers';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Planning
import planning from 'planning/reducers';

// Application
import globalMessages from 'common/reducers/globalMessages';
import { loadingBarReducer } from 'react-redux-loading-bar';

// SystemConfigs
import systemConfigs from 'systemConfigs/reducers';
import tripCodes from 'reports/reducers/tripCodes';
import operators from 'reports/reducers/operators';
import selectedOperator from 'reports/reducers/selectedOperator';

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: ['selectedDmcs'],
};

const rootReducer = combineReducers({
  departures: departures,
  dmcs: dmcs,
  selectedDmcs: selectedDmcs,
  globalMessages: globalMessages,
  loadingBar: loadingBarReducer,
  planning: planning,
  systemConfigs: systemConfigs,
  tripCodes: tripCodes,
  operators: operators,
  selectedOperator: selectedOperator,
});

export default persistReducer(persistConfig, rootReducer);

export type AppState = ReturnType<typeof rootReducer>;
