import ApiError from 'common/errors/ApiError';

class Unauthorized extends ApiError {
  constructor(message?: string, ...params: any) {
    const calculatedMessage = message === undefined ? 'Session time out' : '';
    super('Unauthorized', calculatedMessage, params, new Error().stack);
  }
}

export default Unauthorized;
