import * as React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core/';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from './PlanningDepartureChildComponentTable.styles';
import IPax from 'departures/types/IPax';
import formatDate from 'common/helpers/formatDate';

interface IProps extends WithStyles<typeof styles> {
  pax: IPax[] | null;
}

class PlanningDepartureContactInfoInsuranceDetailsTable extends React.Component<IProps> {
  constructor(props) {
    super(props);
  }

  public render() {

    const { pax, classes } = this.props;

    return (pax &&
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCol}>Booking Number</TableCell>
              <TableCell className={classes.headerCol}>Status</TableCell>
              <TableCell className={classes.headerCol}>Title</TableCell>
              <TableCell className={classes.headerCol}>First Name</TableCell>
              <TableCell className={classes.headerCol}>Middle Name</TableCell>
              <TableCell className={classes.headerCol}>Surname</TableCell>
              <TableCell className={classes.headerCol}>Insurance Company</TableCell>
              <TableCell className={classes.headerCol}>Insurance Policy Number</TableCell>
              <TableCell className={classes.headerCol}>Insurance Company Phone No.</TableCell>
              <TableCell className={classes.headerCol}>Policy Expiry</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pax.map(passenger => (
              <TableRow>
                <TableCell className={classes.tableCol}>{passenger.bookingId}</TableCell>
                <TableCell className={classes.tableCol}>{passenger.status}</TableCell>
                <TableCell className={classes.tableCol}>{passenger.title}</TableCell>
                <TableCell className={classes.tableCol}>{passenger.firstName}</TableCell>
                <TableCell className={classes.tableCol}>{passenger.middleName}</TableCell>
                <TableCell className={classes.tableCol}>{passenger.surname}</TableCell>
                <TableCell className={classes.tableCol}>{passenger.insurance.companyName}</TableCell>
                <TableCell className={classes.tableCol}>{passenger.insurance.policyNumber}</TableCell>
                <TableCell className={classes.tableCol}>{passenger.insurance.companyPhoneNumber}</TableCell>
                <TableCell className={classes.tableCol}>{passenger.insurance.policyExpiry ? formatDate(passenger.insurance.policyExpiry) : ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(PlanningDepartureContactInfoInsuranceDetailsTable);
