export const downloadReport = (
  base64Text: string,
  fileNameText: string,
  fileType: string = 'csv'
) => {
  const sourceLink =
    fileType === 'xls'
      ? `data:application/vnd.ms-excel;base64,${base64Text}`
      : `data:text/csv;base64,${base64Text}`;

  const downloadLink = document.createElement('a');

  const fileName = `${fileNameText}.${fileType}`;

  downloadLink.href = sourceLink;

  downloadLink.download = fileName;

  downloadLink.click();
};
