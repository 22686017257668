import CardActions from '@material-ui/core/CardActions';
import * as React from 'react';
import IDeparture from '../types/IDeparture';
import ICrew from '../types/ICrew';
import TripReportDialog from './TripReportDialog';
import CustomButton from 'common/components/CustomButton';
import LinkWrapper from 'common/components/LinkWrapper';
import { ROUTE_PAX } from 'common/constants/routes';
import LocationsDialogContainer from './LocationsDialogContainer';
import { ROUTE_COVID_QUESTIONNAIRE } from '../../common/constants/routes';

interface IProps {
  departure: IDeparture;
  selectedDepartureCrew?: ICrew[];
  actions: any;
  showLoading?: any;
  hideLoading?: any;
  getAndDisplayFile?: any;
  isOperationsDashboardCovidSurveyEnabled: boolean;
}

class DepartureActions extends React.Component<IProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { departure, isOperationsDashboardCovidSurveyEnabled } = this.props;

    return (
      <div>
        <CardActions>
          <LinkWrapper
            path={ROUTE_PAX}
            parameters={{ id: this.props.departure!.id }}
          >
            <CustomButton
              onClick={() => {
                return;
              }}
              id={'btn.passengers' + departure!.code}
              label="Passengers"
              fontIcon="person"
            />
          </LinkWrapper>
          <LocationsDialogContainer
            actions={this.props.actions}
            selectedDeparture={departure}
          />

          <TripReportDialog
            departure={departure}
            selectedDepartureCrew={this.props.selectedDepartureCrew}
            showLoading={this.props.showLoading}
            hideLoading={this.props.hideLoading}
            getAndDisplayFile={this.props.getAndDisplayFile}
            actions={this.props.actions}
          />
          {isOperationsDashboardCovidSurveyEnabled && (
            <LinkWrapper
              path={ROUTE_COVID_QUESTIONNAIRE}
              parameters={{ id: this.props.departure!.id }}
            >
              <CustomButton
                onClick={() => {
                  return;
                }}
                id={'btn.covidHub' + departure!.code}
                label="Covid Hub"
                fontIcon="assignment"
              />
            </LinkWrapper>
          )}
        </CardActions>
      </div>
    );
  }
}

export default DepartureActions;
