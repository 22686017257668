import { ISO_STRING_DATE_REGEX } from './constants';

export default function isoStringtoDateReviver(value) {
  const isString = typeof value === 'string';
  if (isString && value && value.match(ISO_STRING_DATE_REGEX)) {
    return new Date(value);
  }

  return value;
}
