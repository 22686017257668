import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  departureContainer: {
    padding: '5px 15px 5px 15px'
  },
  subHeader: {
    backgroundColor: '#eee',
    paddingTop: '10px',
    paddingBottom: '10px'
  }
});
