import formatDate from 'common/helpers/formatDate';

/**
 * Detects if value isn't a date, OR has NaN as the time aka 'Invalid Date'
 * Null and undefined values are allowed
 * @param value
 */
export default function isValidDateFormat(value: Date | null | undefined) {
  if (value && (!(value instanceof Date) || isNaN(value.getTime()))) {
    return `Invalid. Valid format is ${formatDate(new Date())}`;
  }

  return undefined;
}
