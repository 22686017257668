import styles from './TripReportDialog.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import NoResultsMessage from 'common/components/NoResultsMessage';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IDeparture from '../types/IDeparture';
import IDepartureItinerary from '../types/IDepartureItinerary';
import CustomButton from 'common/components/CustomButton';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import formatDate from 'common/helpers/formatDate';

interface IState {
  // Required property to trigger re-render on the material ui component to update the display text
  locationsDialogOpen: boolean;
}

interface IProps extends WithStyles<typeof styles> {
  departure: IDeparture;
  itinerary: IDepartureItinerary;
  open: boolean;
  close: () => any;
  actions: any;
}

class LocationsDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      locationsDialogOpen: false
    };
  }

  public handleLocations() {
    this.setState({
      locationsDialogOpen: true
    });
  }

  public openLocationsDialog = event => {
    this.props.actions
      .getDepartureItinerary(this.props.departure!.id)
      .then(itinerary => {
        this.handleLocations();
      });
  };

  public closeLocationDialog = () => {
    this.setState({
      locationsDialogOpen: false
    });
  };

  public render() {
    const { departure, itinerary } = this.props;

    const title = 'Locations for Departure - ' + departure!.code;

    return (
      <div>
        <CustomButton
          onClick={this.openLocationsDialog}
          id={'btn.locations_' + departure!.code}
          label="Locations"
          fontIcon="location_on"
        />
        <Dialog
          open={this.state.locationsDialogOpen}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            {itinerary && itinerary.days.length === 0 ? (
              <NoResultsMessage message="No itinerary assigned to the departure" />
            ) : (
              itinerary &&
              itinerary.days.map(item => (
                <React.Fragment>
                  <Grid container={true} spacing={1}>
                    <Grid item xs={2} sm={2}>
                      <Typography variant="body1" color="inherit">
                        {`Day ${item.day}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <Typography variant="body1" color="inherit">
                        {formatDate(item.date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      {item.locations.map(location => (
                        <Typography variant="body1" color="inherit">
                          {location}
                          <br />
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              ))
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeLocationDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(LocationsDialog);
