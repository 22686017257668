import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  durationIcon: {
    height: '15px'
  },
  completedIcon: {
    color: '#28dc14',
    fontSize: 'large'
  },
  pendingIcon: {
    color: '#D3D3D3',
    fontSize: 'large'
  },
  thumbsLabel: {
    whiteSpace: 'nowrap'
  },
  label: {
    'font-weight': 'bold',
    display: 'inline-block'
  },
  gridItemCentered: {
    'text-align': 'center'
  },
  infoContainer: {
    display: 'grid',
    gridTemplateColumns: '100px auto'
  },
  indentLeft: {
    marginLeft: '5px'
  },
  priorityHighIcon: {
    color: '#CC0033',
    fontSize: 'large'
  }
});
