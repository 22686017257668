import * as React from 'react';
import { Paper, Tab, Tabs, withStyles } from '@material-ui/core';
import styles from './PlanningDeparturePaxDetailsContainer.styles';
import { WithStyles } from '@material-ui/styles';
import IPax from 'departures/types/IPax';
import PlanningDeparturePaxDetailsTable from './PlanningDeparturePaxDetailsTable';
import PlanningDepartureContactDetailsCustomerContactInfoTable from './PlanningDepartureContactDetailsCustomerContactInfoTable';
import PlanningDeparturePaxEmergencyContactDetailsTable from './PlanningDeparturePaxEmergencyContactDetailsTable';
import PlanningDepartureContactInfoInsuranceDetailsTable from './PlanningDepartureContactInfoInsuranceDetailsTable';


interface IState {
  selectedChildTabIndex?: number;
}

interface IProps extends WithStyles<typeof styles> {
  pax: IPax[];
}

class PlanningDepartureContactDetailsContainer extends React.Component<
  IProps,
  IState
  > {
  constructor(props) {
    super(props);

    this.state = {
      selectedChildTabIndex: 0
    };
  }

  public render() {
    const { pax, classes } = this.props;

    const { selectedChildTabIndex } = this.state;

    return (
      <Paper className={classes.paper}>
        <Tabs
          value={this.state.selectedChildTabIndex}
          onChange={this.handleChildTabChange}
          className={classes.childTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            id={'btn.customerContact'}
            label={'Customer Contact'}
          />
          <Tab
            id={'btn.insurance'}
            label={'Insurance'}
          />
          <Tab
            id={'btn.emergencyContact'}
            label={'Emergency Contact'}
          />
          <Tab
            id={'btn.sellingAgentContact'}
            label={'Selling Agent Contact'}
          />
        </Tabs>
        {selectedChildTabIndex === 0 &&
          (
            <PlanningDepartureContactDetailsCustomerContactInfoTable pax={pax} />
          )}
        {selectedChildTabIndex === 1 &&
          (
            <PlanningDepartureContactInfoInsuranceDetailsTable pax={pax} />
          )}
        {selectedChildTabIndex === 2 &&
          (
            <PlanningDeparturePaxEmergencyContactDetailsTable pax={pax} />
          )}
        {selectedChildTabIndex === 3 &&
          (
            <PlanningDeparturePaxDetailsTable pax={pax} />
          )}
      </Paper>


    );
  }
  private handleChildTabChange = (event, selectedChildTabIndex) => {
    this.setState({ selectedChildTabIndex });
  };
}

export default withStyles(styles)(PlanningDepartureContactDetailsContainer);
