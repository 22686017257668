import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

export default createMuiTheme({
  overrides: {
    MuiCard: {
      root: {
        margin: '5px'
      }
    },
    MuiCardHeader: {
      root: {
        [defaultTheme.breakpoints.up('sm')]: {
          'padding-left': '24px',
          'padding-right': '24px'
        }
      }
    },
    MuiListSubheader: {
      root: {
        'line-height': '14px',
        'padding-top': '10px',
        'padding-bottom': '7px'
      }
    },
    MuiTypography: {
      root: {
        display: 'inline'
      },
      body1: {
        fontSize: '0.875rem',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      caption: {
        color: 'rgba(0, 0, 0, 0.87)'
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginBottom: '0px',
        marginTop: '0px'
      }
    },
    MuiIconButton: {
      root: {
        paddingTop: '5px',
        paddingBottom: '5px'
      }
    }
  },
  palette: {
    primary: { main: '#1666B0' },
    secondary: { main: '#231764' }
  }
});
