import * as React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ActionIcon from 'common/components/ActionIcon';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from './PaxDetails.styles';
import IPax from '../types/IPax';
import IDepartureAccommodation from '../types/IDepartureAccommodation';
import DepartureAccommodation from 'departures/components/DepartureAccommodation';
import IDepartureSightseeing from '../types/IDepartureSightSeeing';
import DepartureSightseeing from 'departures/components/DepartureSightseeing';
import NoResultsMessage from 'common/components/NoResultsMessage';
import PaxCard from 'departures/components/PaxCard';
import IDepartureTransfer from 'departures/types/IDepartureTransfer';
import DepartureTransfers from 'departures/components/DepartureTransfers';
import DeparturePaxChanges from 'departures/components/DeparturePaxChanges';
import IPaxChanges from 'departures/types/IPaxChanges';
import IDeparture from '../types/IDeparture';

interface IState {
  selectedTabIndex?: number;
}

interface IProps extends WithStyles<typeof styles> {
  pax: IPax[];
  departure: IDeparture;
  departureTransfers: IDepartureTransfer[];
  departureAccommodation: IDepartureAccommodation[];
  departureSightseeing: IDepartureSightseeing[];
  departurePaxChanges: IPaxChanges[];
  showPaxChangesCopiedMessage: () => void;
}

class PaxDetails extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: 0
    };
  }

  public render() {
    const {
      pax,
      departure,
      departureTransfers,
      departureAccommodation,
      departurePaxChanges,
      departureSightseeing,
      showPaxChangesCopiedMessage,
      classes
    } = this.props;
    const { selectedTabIndex } = this.state;

    const paxSorted = pax.sort((a, b) =>
      a.bookingId < b.bookingId ? -1 : a.bookingId > b.bookingId ? 1 : 0
    );

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Tabs
            value={this.state.selectedTabIndex}
            onChange={this.handleTabChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              id={'btn.passengers'}
              classes={{ root: classes.tab }}
              icon={
                <ActionIcon
                  iconType="people"
                  label="Pax Info"
                  color="secondary"
                  className={classes.icon}
                />
              }
            />
            <Tab
              id={'btn.transfers'}
              classes={{ root: classes.tab }}
              icon={
                <ActionIcon
                  iconType="local_taxi"
                  label="Transfers"
                  color="secondary"
                  className={classes.icon}
                />
              }
            />
            <Tab
              id={'btn.accommodation'}
              classes={{ root: classes.tab }}
              icon={
                <ActionIcon
                  iconType="hotel"
                  label="Accom."
                  color="secondary"
                  className={classes.icon}
                />
              }
            />
            <Tab
              id={'btn.sightseeing'}
              classes={{ root: classes.tab }}
              icon={
                <ActionIcon
                  iconType="add_shopping_cart"
                  label="Sightseeing"
                  color="secondary"
                  className={classes.icon}
                />
              }
            />
            <Tab
              id={'btn.paxUpdates'}
              classes={{ root: classes.tab }}
              icon={
                <ActionIcon
                  iconType="update"
                  label="Changes"
                  color="secondary"
                  className={classes.icon}
                />
              }
            />
          </Tabs>
        </Paper>
        {selectedTabIndex === 0 &&
          (paxSorted.length ? (
            paxSorted.map((pax, i) => (
              <PaxCard
                key={pax.id}
                pax={pax}
                departureStartDate={departure.startDate}
                departureEndDate={departure.endDate}
              />
            ))
          ) : (
            <NoResultsMessage message="No pax on departure" />
          ))}
        {selectedTabIndex === 1 &&
          (departureTransfers.length ? (
            <DepartureTransfers transfers={departureTransfers} />
          ) : (
            <NoResultsMessage message="No transfers booked" />
          ))}
        {selectedTabIndex === 2 &&
          (departureAccommodation.length ? (
            <DepartureAccommodation accommodations={departureAccommodation} />
          ) : (
            <NoResultsMessage message="No accommodation booked" />
          ))}
        {selectedTabIndex === 3 &&
          departureSightseeing &&
          (departureSightseeing.length ? (
            <DepartureSightseeing sightseeings={departureSightseeing} />
          ) : (
            <NoResultsMessage message="No sightseeing booked" />
          ))}
        {selectedTabIndex === 4 &&
          departurePaxChanges &&
          (departurePaxChanges.length ? (
            <DeparturePaxChanges
              paxChanges={departurePaxChanges}
              showPaxChangesCopiedMessage={showPaxChangesCopiedMessage}
              paxList={pax}
            />
          ) : (
            <NoResultsMessage message="No changes" />
          ))}
      </React.Fragment>
    );
  }

  private handleTabChange = (event, selectedTabIndex) => {
    this.setState({ selectedTabIndex });
  };
}

export default withStyles(styles)(PaxDetails);
