import NetworkError from 'common/errors/NetworkError';
import BadRequest from 'common/errors/BadRequest';
import Unauthorized from 'common/errors/Unauthorized';
import NotFoundError from 'common/errors/NotFoundError';
import ForbiddenError from 'common/errors/ForbiddenError';
import isoStringtoDateReviver from 'common/helpers/isoStringtoDateReviver';

export interface IOptions {
  method: string;
  body?: any;
  headers: Headers;
}

export default class BaseService {
  public createRequest(baseUrl, href, options: IOptions) {
    const { method, body, headers } = options;
    const url = this.constructUrl(baseUrl, href);

    return new Request(url, {
      headers: new Headers(headers),
      method,
      body,
      credentials: 'same-origin'
    });
  }

  public validate(resp) {
    if (resp.ok) {
      return resp;
    }

    if (resp.status === 401) {
      return Promise.reject(new Unauthorized());
    }

    if (resp.status === 403) {
      return Promise.reject(new ForbiddenError());
    }

    if (resp.status === 404) {
      return Promise.reject(new NotFoundError());
    }

    // Handle client errors
    if (resp.status >= 400 && resp.status < 499) {
      return Promise.reject(new BadRequest());
    }

    if (resp.status === 302) {
      return Promise.reject(new Unauthorized());
    }

    // Handle server errors
    if (resp.status >= 500 && resp.status < 600) {
      return Promise.reject(new NetworkError());
    }

    return resp;
  }

  public extract(resp) {
    return resp.text().then(
      text =>
        text &&
        JSON.parse(text, (key, value) => {
          return isoStringtoDateReviver(value);
        })
    );
  }

  public async fetch(request) {
    const maxRetries = 3;
    let retries = 0;

    while (retries < maxRetries) {
      try {
        const response = await fetch(request);
        if (response.ok) {
          return response;
        } else {
          return this.validate(response);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        retries++;
        if (retries >= maxRetries) {
          throw new NetworkError('Maximum retries reached, Unable to connect to server');
        }

        // add a delay between retries(Wait for 5 second before retrying)
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    }
  }

  private constructUrl(baseUrl, path) {
    return `${baseUrl}${path}`;
  }
}