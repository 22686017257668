import * as React from 'react';
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from './CustomTextField.styles';

interface IProps extends StandardTextFieldProps, WithStyles<typeof styles> {
  classes: Record<'textField', string> | any;
  clearable?: boolean;
  onClear?: ((event: React.MouseEvent<HTMLElement>) => void) | undefined;
  label: string;
  error?: boolean;
  helperText?: string;
}

class CustomTextField extends React.Component<IProps> {
  public render() {
    const { label, value, onChange, clearable, onClear, classes, error, helperText } = this.props;

    const endAdornment = clearable
      ? {
        endAdornment: (
          <InputAdornment position="end">
            {value && (
              <IconButton aria-label={label} onClick={onClear}>
                <Icon fontSize="small">clear</Icon>
              </IconButton>
            )}
          </InputAdornment>
        )
      }
      : {};

    return (
      <TextField
        label={label}
        value={value}
        onChange={onChange}
        className={classes.textField}
        InputProps={endAdornment}
        error={error}
        helperText={helperText}
      />
    );
  }
}

export default withStyles(styles)(CustomTextField);
