import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  actionButtonsContainer: {
    width: '95%',
    textAlign: 'right'
  },
  searchContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  inputsContainer: {
    paddingLeft: '15px',
    paddingRight: '15px',
    width: '200px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  buttonsContainer: {
    textAlign: 'right'
  }
});
