import IPlanningSearchCriteria from '../types/IPlanningTransferSearchCriteria';
import convertToAPIDate from 'common/helpers/convertToAPIDate';
import { startOfDay, endOfDay } from 'date-fns';

export default class PlanningTransfers {
  public service: any;

  constructor(service) {
    this.service = service;
  }

  public async getBySearchCriteria(searchCriteria: IPlanningSearchCriteria) {
    const resourcePath = `/transfers`;

    // Replace dates to send strings
    const searchCriteriaModified = {
      ...searchCriteria,
      transferDateFrom: convertToAPIDate(
        startOfDay(searchCriteria.transferDateFrom)
      ),
      transferDateTo: searchCriteria.transferDateTo
        ? convertToAPIDate(endOfDay(searchCriteria.transferDateTo))
        : null
    };

    const data = await this.service.post(resourcePath, searchCriteriaModified);
    return data.body;
  }
}
