import * as actionTypes from '../actionTypes/departureSearch';
import IDepartureSearchCriteria from 'common/types/IDepartureSearchCriteria';
import { addWeeks } from 'date-fns';

type State = IDepartureSearchCriteria;

const currentDate: Date = new Date();
const defaultSearchCriteria: IDepartureSearchCriteria = {
  tripTypes: ['GroupAdventures'], // 'GroupAdventures', 'SBA', 'VRTT'
  departureCode: '',
  leaderFullName: '', // text contains
  brands: [], // Peregrine, Intrepid, 'blank'
  microRegions: [],
  outstandingAdmin: [],
  startDateFrom: currentDate,
  startDateTo: addWeeks(currentDate, 1),
  dateFilterType: 'Current_Departures',
  anyPaxDeclarationAlerts: false,
  anyCrewDeclarationAlerts: false,
  anyPaxCovidHealthScreeningAlerts: false
};

export default (state: State = defaultSearchCriteria, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_CRITERIA_SET:
      return action.payload.searchCriteria;
    default:
      return state;
  }
};
