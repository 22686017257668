import * as actionTypes from '../actionTypes/planningAccommodation';
import IPlanningAccommodationSearchCriteria from 'common/types/IPlanningAccommodationSearchCriteria';
import operationsPortalService from 'common/services/OperationsPortalService';

export const accommodationGetBySearchCriteriaRequest = () => ({
  type: actionTypes.PLANNING_ACCOMMODATION_GET_REQUEST
});

export const accommodationGetSuccess = accommodation => ({
  type: actionTypes.PLANNING_ACCOMMODATION_GET_SUCCESS,
  payload: {
    accommodation: accommodation
  }
});

export const accommodationGetFailure = () => ({
  type: actionTypes.PLANNING_ACCOMMODATION_GET_FAILURE
});

export const getAccommodationBySearchCriteria = (
  searchCriteria: IPlanningAccommodationSearchCriteria
) => async dispatch => {
  try {
    dispatch(accommodationGetBySearchCriteriaRequest());
    const data = await operationsPortalService.PlanningAccommodation.getBySearchCriteria(
      searchCriteria
    );

    dispatch(accommodationGetSuccess(data));
  } catch (e) {
    dispatch(accommodationGetFailure());
    throw e;
  }
};
