import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import selectors from '../selectors';
import actions from '../actions';
import Departures from '../components/Departures';
import IDeparture from '../types/IDeparture';
import ICrew from '../types/ICrew';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import operationsPortalService from 'common/services/OperationsPortalService';
import Unauthorized from 'common/errors/Unauthorized';
import NetworkError from 'common/errors/NetworkError';
import { addMessage, messageTypes } from 'common/actions/globalMessages';
import systemConfigSelectors from 'systemConfigs/selectors';
import { IS_OPERATIONS_DASHBOARD_COVID_SURVEY_ENABLED } from 'common/constants/systemConfigCode';

interface IDispatchProps {
  actions?: any;
}

interface IProps {
  departures: IDeparture[];
  selectedDepartureCrew: ICrew[];
  isOperationsDashboardCovidSurveyEnabled: boolean;
}

type Props = IProps & IDispatchProps;

class DeparturesContainer extends React.Component<Props> {
  public render() {
    return (
      <Departures
        departures={this.props.departures}
        selectedDepartureCrew={this.props.selectedDepartureCrew}
        actions={this.props.actions}
        showLoading={this.props.actions.showLoading}
        hideLoading={this.props.actions.hideLoading}
        getAndDisplayFile={this.props.actions.getAndDisplayFile}
        isOperationsDashboardCovidSurveyEnabled={this.props.isOperationsDashboardCovidSurveyEnabled}
      />
    );
  }
}

const mapStateToProps = state => ({
  departures: selectors.searchResults.getSearchResults(state),
  selectedDepartureCrew: selectors.selectedDepartureCrew.getSelectedDepartureCrew(
    state
  ),
  isOperationsDashboardCovidSurveyEnabled: systemConfigSelectors.systemConfigs.getIsOperationsDashboardCovidSurveyEnabled(
    state,
    IS_OPERATIONS_DASHBOARD_COVID_SURVEY_ENABLED
  )
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        getCrew: actions.crew.getByDeparture,
        unlockTripReport: actions.reporting.unlockTripReport,
        getAndDisplayFile: url => async dispatch => {
          try {
            await operationsPortalService.getAndDisplayFile(url);
          } catch (e) {
            if (e instanceof Unauthorized) {
              dispatch(
                addMessage(e.toString(), messageTypes.error, true, false)
              );
            }

            if (e instanceof NetworkError) {
              dispatch(
                addMessage(e.toString(), messageTypes.error, false, false)
              );
            }
          }
        }
      },
      dispatch
    ),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
  }
});

export default connect<IProps>(
  mapStateToProps,
  mapDispatchToProps
)(DeparturesContainer);
