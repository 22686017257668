import * as actionTypes from '../actionTypes/planningTransfers';
import IPlanningTransferSearchCriteria from 'common/types/IPlanningTransferSearchCriteria';
import operationsPortalService from 'common/services/OperationsPortalService';

export const transfersGetBySearchCriteriaRequest = () => ({
  type: actionTypes.PLANNING_TRANSFERS_GET_REQUEST
});

export const transfersGetSuccess = transfers => ({
  type: actionTypes.PLANNING_TRANSFERS_GET_SUCCESS,
  payload: {
    transfers: transfers
  }
});

export const transfersGetFailure = () => ({
  type: actionTypes.PLANNING_TRANSFERS_GET_FAILURE
});

export const getTransfersBySearchCriteria = (
  searchCriteria: IPlanningTransferSearchCriteria
) => async dispatch => {
  try {
    dispatch(transfersGetBySearchCriteriaRequest());
    const data = await operationsPortalService.PlanningTransfers.getBySearchCriteria(
      searchCriteria
    );

    dispatch(transfersGetSuccess(data));
  } catch (e) {
    dispatch(transfersGetFailure());
    throw e;
  }
};
