import Card from '@material-ui/core/Card';
import NoResultsMessage from 'common/components/NoResultsMessage';
import * as React from 'react';
import IDeparture from '../types/IDeparture';
import ICrew from '../types/ICrew';
import { sortBy } from 'lodash';
import Departure from 'departures/components/Departure';

interface IProps {
  departures?: IDeparture[];
  selectedDepartureCrew?: ICrew[];
  actions: any;
  showLoading?: any;
  hideLoading?: any;
  getAndDisplayFile?: any;
  isOperationsDashboardCovidSurveyEnabled: boolean;
}

class DepartureList extends React.Component<IProps> {
  public render() {
    const { departures, selectedDepartureCrew } = this.props;

    const departuresSorted: IDeparture[] =
      departures && sortBy(departures, ['startDate', 'endDate']);

    if (!!departures && departures.length === 0) {
      return <NoResultsMessage message="No results to display." />;
    }

    return (
      !!departures &&
      departuresSorted.map((departure, i) => (
        <Card key={i}>
          <Departure
            departure={departure}
            selectedDepartureCrew={selectedDepartureCrew}
            actions={this.props.actions}
            showLoading={this.props.showLoading}
            hideLoading={this.props.hideLoading}
            getAndDisplayFile={this.props.getAndDisplayFile}
            isOperationsDashboardCovidSurveyEnabled={this.props.isOperationsDashboardCovidSurveyEnabled}
          />
        </Card>
      ))
    );
  }
}

export default DepartureList;
