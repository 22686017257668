import ApiError from 'common/errors/ApiError';

// Manually extending prototype to get instanceof working for Errors
class ForbiddenError extends ApiError {
  constructor(message?: string, ...params: any) {
    const calculatedMessage =
      message === undefined ? 'Forbidden access to resource' : '';
    super('Forbidden Error', calculatedMessage, params, new Error().stack);
  }
}

export default ForbiddenError;
