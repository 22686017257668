import ApiError from 'common/errors/ApiError';

class BadRequest extends ApiError {
  constructor(message?: string, ...params: any) {
    const calculatedMessage =
      message === undefined ? 'Unable to complete the request' : '';
    super('Bad Request', calculatedMessage, params, new Error().stack);
  }
}

export default BadRequest;
