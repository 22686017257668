import { isAfter, endOfDay } from 'date-fns';
import formatDate from 'common/helpers/formatDate';

export default function isBeforeDate(value: Date, maxDate: Date | undefined) {
  if (value) {
    if (maxDate && isAfter(value, endOfDay(maxDate))) {
      return `Invalid. Date must be on or before ${formatDate(maxDate)}`;
    }
  }

  return undefined;
}
