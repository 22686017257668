import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  inputContainer: {
    width: '330px',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px 10px 5px 10px',
    margin: '5px 0px 5px 5px'
  },
  label: {
    // Added !important to override the focused specificity
    color: 'white !important'
  },
  select: {
    background: 'white',
    padding: '0 5px',
    width: '310px'
  }
});
