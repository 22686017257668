import { addMessage, messageTypes } from 'common/actions/globalMessages';
import env from '../../common/constants/env';
import * as actionTypes from '../actionTypes/departureSearch';
import tripTypes from 'departures/enums/tripTypes';
import IDepartureSearchCriteria from 'common/types/IDepartureSearchCriteria';
import IDeparture from '../types/IDeparture';
import operationsPortalService from 'common/services/OperationsPortalService';

export const departuresGetBySearchCriteriaRequest = () => ({
  type: actionTypes.DEPARTURES_GET_REQUEST
});

export const departuresGetSuccess = departures => ({
  type: actionTypes.DEPARTURES_GET_SUCCESS,
  payload: {
    departures
  }
});

export const departuresGetFailure = () => ({
  type: actionTypes.DEPARTURES_GET_FAILURE
});

export const searchCriteriaSetRequest = (
  searchCriteria: IDepartureSearchCriteria
) => ({
  type: actionTypes.SEARCH_CRITERIA_SET,
  payload: {
    searchCriteria
  }
});

export const setSearchCriteria = (
  values: IDepartureSearchCriteria
) => dispatch => {
  dispatch(searchCriteriaSetRequest(values));
};

export const getDeparturesBySearchCriteria = (
  searchCriteria: IDepartureSearchCriteria
) => async dispatch => {
  try {
    dispatch(departuresGetBySearchCriteriaRequest());
    const data = await operationsPortalService.Departures.getBySearchCriteria({
      ...searchCriteria,
      // Make sure all trip stypes are sent to the API when none are selected
      // this is done here so that it doesn't affect the state/ui
      tripTypes:
        searchCriteria.tripTypes.length > 0
          ? searchCriteria.tripTypes
          : tripTypes.map(v => v.value)
    });

    const departures = data as IDeparture[];
    if (departures.length > +env.REACT_APP_SEARCH_LIMIT) {
      dispatch(
        addMessage(
          'First ' +
            env.REACT_APP_SEARCH_LIMIT +
            ' departures displayed; please refine your search criteria.',
          messageTypes.info,
          false,
          true,
          10000
        )
      );

      departures.splice(+env.REACT_APP_SEARCH_LIMIT);
    }

    dispatch(departuresGetSuccess(departures));
  } catch (e) {
    dispatch(departuresGetFailure());
    throw e;
  }
};
