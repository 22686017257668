import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  paper: {
    margin: '0px 0px 5px 0px',
    paddingBottom: '1px'
  },
  icon: {
    float: 'right'
  },
  childTab: {
    paddingLeft: '30px'
  }
});
