import IUnlockTripReportCriteria from '../types/IUnlockTripReportCriteria';

export default class Reporting {
  public service: any;
  public baseResourcePath: string;

  constructor(service) {
    this.service = service;
    this.baseResourcePath = '/reporting';
  }

  public async unlockTripReportByCrewId(
    criteria: IUnlockTripReportCriteria
  ) {
    const resourcePath =
      `${this.baseResourcePath}/departure/${encodeURIComponent(
        criteria.departureId.toString())}/unlockSummary/crewMember/${encodeURIComponent(
          criteria.crewId.toString())}`;

    const data = await this.service.get(resourcePath);
    return data.body;
  }
}