import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import { CardContent, Grid } from '@material-ui/core';
import CovidQuestionnaireResponsesTable from './CovidQuestionnaireResponsesTable';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from './CovidQuestionnaireResponsesCard.styles';
import ICovidQuestionnaireDisplayResponse from "../types/ICovidQuestionnaireDisplayResponse";
import ICovidQuestionnaireQuestion from 'departures/types/ICovidQuestionnaireQuestion';
import CovidQuestionnaireQuestionsTable from './CovidQuestionnaireQuestionsTable';
import CovidHealthScreeningQuestionsTable from './CovidHealthScreeningQuestionsTable';
import CovidHealthScreeningTable from './CovidHealthScreeningTable';
import { Tab, Tabs } from '@material-ui/core';

interface IState {
  selectedTabIndex?: number;
  selectedChildTabIndex?: number;
}

interface IProps extends WithStyles<typeof styles> {
  paxResponses: ICovidQuestionnaireDisplayResponse[];
  crewResponses: ICovidQuestionnaireDisplayResponse[];
  paxQuestions: ICovidQuestionnaireQuestion[];
  crewQuestions: ICovidQuestionnaireQuestion[];
  paxHealthScreeningResponses: ICovidQuestionnaireDisplayResponse[];
  PaxHealthScreeningQuestions: ICovidQuestionnaireQuestion[];
}

class CovidQuestionnaireResponsesCard extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: 0,
      selectedChildTabIndex: 0
    };
  }

  public render() {
    const { crewResponses, paxResponses, paxQuestions, crewQuestions, classes, paxHealthScreeningResponses, PaxHealthScreeningQuestions } = this.props;
    const { selectedTabIndex } = this.state;
    return (

      (crewResponses || paxResponses) && (
        <React.Fragment>
          <Paper>
            <CardHeader
              title={
                <Typography variant="h5" align="center" className={classes.titleText}>
                  <span>Covid19 Self Declaration</span>
                </Typography>
              }
              className={classes.textAlign}
            />
            <Tabs
              value={this.state.selectedTabIndex}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab id={'tab.CovidDeclaration'} label={'Covid Declaration'} />
              <Tab id={'tab.CustomerHealthScreening'} label={'Covid Health Screening'} />
            </Tabs>

          </Paper>
          {selectedTabIndex === 0 &&
            <CardContent className={classes.tabBackground}>
              <React.Fragment>
                <Grid item xs>
                  <CovidQuestionnaireResponsesTable
                    covidQuestionnaireDisplayResponses={paxResponses}
                    isCrew={false}
                    title={"Passengers"}
                  />
                </Grid>
                <br /><br />
                <Grid item xs>
                  <CovidQuestionnaireResponsesTable
                    covidQuestionnaireDisplayResponses={crewResponses}
                    isCrew={true}
                    title={"Leaders & Crew"}
                  />
                </Grid>
                <br /><br />
                <Grid item xs>
                  <CovidQuestionnaireQuestionsTable
                    covidQuestionnaireQuestions={paxQuestions}
                    title={"Pax Questions"}
                  />
                </Grid>
                <br /><br />
                <Grid item xs>
                  <CovidQuestionnaireQuestionsTable
                    covidQuestionnaireQuestions={crewQuestions}
                    title={"Crew Questions"}
                  />
                </Grid>
              </React.Fragment>
            </CardContent>
          }
          {selectedTabIndex === 1 &&
            <CardContent className={classes.tabBackground}>
              <React.Fragment>
                <Grid item xs>
                  <CovidHealthScreeningTable
                    covidQuestionnaireDisplayResponses={paxHealthScreeningResponses}
                    isCrew={false}
                    title={"Passengers"}
                  />
                </Grid>
                <br /><br />
                <Grid item xs>
                  <CovidHealthScreeningQuestionsTable
                    covidHealthScreeningQuestions={PaxHealthScreeningQuestions}
                    title={"Pax Questions"}
                  />
                </Grid>
              </React.Fragment>
            </CardContent>
          }
        </React.Fragment>
      )
    );
  }
  private handleTabChange = (event, selectedTabIndex) => {
    this.setState({ selectedTabIndex });
    this.setState({ selectedChildTabIndex: 0 });
  };
}

export default withStyles(styles)(CovidQuestionnaireResponsesCard);
