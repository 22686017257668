import * as React from 'react';
import { connect } from 'react-redux';
import selectors from '../selectors';
import IPlanningAccommodation from '../types/IPlanningAccommodation';
import PlanningAccommodationSearchContainer from './PlanningAccommodationSearchContainer';
import PlanningAccommodationList from './PlanningAccommodationList';
import IPlanningAccommodationSearchCriteria from 'common/types/IPlanningAccommodationSearchCriteria';

interface IDispatchProps {
  actions?: any;
}

interface IProps {
  accommodation: IPlanningAccommodation[];
}

type Props = IProps & IDispatchProps;

class PlanningAccommodationContainer extends React.Component<Props> {
  public render() {
    return (
      <React.Fragment>
        <PlanningAccommodationSearchContainer
          initialSearchCriteria={initialSearchCriteria}
        />
        <PlanningAccommodationList accommodation={this.props.accommodation} />
      </React.Fragment>
    );
  }
}

const initialSearchCriteria: IPlanningAccommodationSearchCriteria = {
  accommodationDateFrom: new Date(),
  accommodationDateTo: null
};

const mapStateToProps = state => ({
  accommodation: selectors.planningAccommodation.getAccommodation(state)
});

export default connect<IProps>(mapStateToProps)(PlanningAccommodationContainer);
