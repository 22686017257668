import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GlobalMessage from '../components/GlobalMessage';
import { getMessage } from '../selectors/globalMessages';
import { removeMessage } from '../actions/globalMessages';
import IGlobalMessage from 'departures/types/IGlobalMessage';
import Button from '@material-ui/core/Button';

interface IProps {
  message: IGlobalMessage;
}

interface IDispatchProps {
  actions?: any;
}

type Props = IProps & IDispatchProps;

class GlobalMessagesContainer extends React.Component<Props> {
  public render() {
    const { message, actions } = this.props;

    return (
      <GlobalMessage
        open={message !== null}
        message={message !== null ? message.content : ''}
        type={message !== null ? message.type : null}
        autoHideDuration={this.getAutoHideDuration()}
        closable={(message && message.closable) || undefined}
        redirect={(message && message.redirect) || undefined}
        action={this.buildAction(message)}
        onClose={actions.removeMessage}
      />
    );
  }

  private getAutoHideDuration() {
    const { message } = this.props;

    if (message !== null) {
      if (message.autoHideDuration || message.autoHideDuration === 0) {
        return message.autoHideDuration;
      }

      if (message.closable || message.redirect) {
        return 3000000;
      }
    }

    return 2000;
  }

  private buildAction = (message: IGlobalMessage) => {
    const action: JSX.Element[] = [];

    if (message !== null) {
      if (message.customAction) {
        action.push(message.customAction);
      }
      if (message.redirect) {
        action.push(
          <Button
            key="refresh"
            color="primary"
            size="small"
            variant="contained"
            onClick={() => window.location.reload()}
          >
            REFRESH
          </Button>
        );
      }
    }

    return action;
  };
}

const mapStateToProps = state => ({
  message: getMessage(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ removeMessage }, dispatch)
});

export default connect<IProps>(
  mapStateToProps,
  mapDispatchToProps
)(GlobalMessagesContainer);
