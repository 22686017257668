import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../actions';
import DepartureSearch from '../components/DepartureSearch';
import IDepartureSearchCriteria from 'common/types/IDepartureSearchCriteria';
import { getSelectedDmcs } from '../../common/selectors/selectedDmcs';
import selectors from 'departures/selectors';
import systemConfigSelectors from 'systemConfigs/selectors';
import { IS_OPERATIONS_DASHBOARD_COVID_SURVEY_ENABLED } from 'common/constants/systemConfigCode';

interface IDispatchProps {
  actions?: any;
}

interface IProps {
  selectedDmcs: number[];
  searchCriteria: IDepartureSearchCriteria;
  isOperationsDashboardCovidSurveyEnabled: boolean;
}

type Props = IProps & IDispatchProps;

class DeparturesContainer extends React.Component<Props> {
  public render() {
    return (
      <DepartureSearch
        searchCriteria={this.props.searchCriteria}
        selectedDmcs={this.props.selectedDmcs}
        actions={this.props.actions}
        isOperationsDashboardCovidSurveyEnabled={this.props.isOperationsDashboardCovidSurveyEnabled}
      />
    );
  }
}

const mapStateToProps = state => ({
  selectedDmcs: getSelectedDmcs(state),
  searchCriteria: selectors.searchCriteria.getSearchCriteria(state),
  isOperationsDashboardCovidSurveyEnabled: systemConfigSelectors.systemConfigs.getIsOperationsDashboardCovidSurveyEnabled(
    state,
    IS_OPERATIONS_DASHBOARD_COVID_SURVEY_ENABLED
  )
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDepartures: actions.departureSearch.getDeparturesBySearchCriteria,
      setSearchCriteria: actions.departureSearch.setSearchCriteria
    },
    dispatch
  )
});

export default connect<IProps>(
  mapStateToProps,
  mapDispatchToProps
)(DeparturesContainer);
