import * as React from 'react';
import { connect } from 'react-redux';
import PlanningDepartureSearch from '../components/PlanningDepartureSearch';
import IPlanningDepartureSearchCriteria from 'common/types/IPlanningDepartureSearchCriteria';
import { bindActionCreators } from 'redux';
import actions from '../actions/planningDepartures';
import selectors from '../selectors/planningDepartures';

interface IDispatchProps {
  actions?: any;
}

export interface IOwnProps {
  searchCriteria: IPlanningDepartureSearchCriteria;
}

type Props = IDispatchProps & IOwnProps;

class PlanningDepartureSearchContainer extends React.Component<Props> {
  public render() {
    return (
      <PlanningDepartureSearch
        searchCriteria={this.props.searchCriteria}
        getDepartures={this.props.actions.getDepartures}
        setSearchCriteria={this.props.actions.setSearchCriteria}
      />
    );
  }
}

const mapStateToProps = state => ({
  searchCriteria: selectors.searchCriteria.getSearchCriteria(state)
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        getDepartures: actions.getDeparturesBySearchCriteria,
        setSearchCriteria: actions.setSearchCriteria
      },
      dispatch
    )
  }
});

export default connect<IOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PlanningDepartureSearchContainer);
