export const CITY: Array<{ value: string; text: string }> = [
  { value: '3697', text: 'Accra' },
  { value: '3656', text: 'Addis Ababa' },
  { value: '7244', text: 'Adrasan' },
  { value: '3295', text: 'Agadir' },
  { value: '3665', text: 'Ajaccio' },
  { value: '3646', text: 'Alexandria' },
  { value: '3588', text: 'Algiers' },
  { value: '13237', text: 'Alicante' },
  { value: '3264', text: 'Alice Springs' },
  { value: '3228', text: 'Almaty' },
  { value: '6996', text: 'Almeria' },
  { value: '3168', text: 'Amalfi' },
  { value: '3222', text: 'Amman' },
  { value: '3886', text: 'Amritsar' },
  { value: '3312', text: 'Amsterdam' },
  { value: '3479', text: 'Anchorage' },
  { value: '3445', text: 'Antalya' },
  { value: '3241', text: 'Antananarivo' },
  { value: '7534', text: 'Antarctic Peninsula' },
  { value: '3714', text: 'Antigua' },
  { value: '3223', text: 'Aqaba' },
  { value: '3401', text: 'Arusha' },
  { value: '3457', text: 'Ashgabat' },
  { value: '4344', text: 'Aswan' },
  { value: '3698', text: 'Athens' },
  { value: '3313', text: 'Auckland' },
  { value: '3669', text: 'Avignon' },
  { value: '7303', text: 'Ax-les-Thermes' },
  { value: '9270', text: 'Bagamoyo' },
  { value: '3258', text: 'Bamako' },
  { value: '3093', text: 'Bangalore' },
  { value: '216', text: 'Bangkok' },
  { value: '3367', text: 'Barcelona' },
  { value: '7876', text: 'Bari' },
  { value: '3213', text: 'Bariloche' },
  { value: '3596', text: 'Beijing' },
  { value: '3922', text: 'Beirut' },
  { value: '3475', text: 'Belize City' },
  { value: '6796', text: 'Bergen' },
  { value: '3695', text: 'Berlin' },
  { value: '3307', text: 'Bhaktapur' },
  { value: '8630', text: 'Bilbao' },
  { value: '3235', text: 'Bishkek' },
  { value: '12913', text: 'Bodrum' },
  { value: '3966', text: 'Bogota' },
  { value: '6789', text: 'Bratislava' },
  { value: '3345', text: 'Bucharest' },
  { value: '3722', text: 'Budapest' },
  { value: '6490', text: 'Buenos Aires' },
  { value: '5648', text: 'Cadiar' },
  { value: '8921', text: 'Cagliari' },
  { value: '407', text: 'Cairo' },
  { value: '3576', text: 'Calgary' },
  { value: '3287', text: 'Cancun' },
  { value: '3140', text: 'Candidasa' },
  { value: '3360', text: 'Cape Town' },
  { value: '3446', text: 'Cappadocia' },
  { value: '3505', text: 'Caracas' },
  { value: '3882', text: 'Cartagena' },
  { value: '3296', text: 'Casablanca' },
  { value: '13207', text: 'Castellina in Chianti' },
  { value: '3174', text: 'Catania' },
  { value: '8148', text: 'Cavtat' },
  { value: '3476', text: 'Caye Caulker' },
  { value: '3598', text: 'Chengdu' },
  { value: '3095', text: 'Chennai' },
  { value: '3416', text: 'Chiang Mai' },
  { value: '3417', text: 'Chiang Rai' },
  { value: '3314', text: 'Christchurch' },
  { value: '6695', text: 'Cochin' },
  { value: '3381', text: 'Colombo' },
  { value: '3699', text: 'Corfu' },
  { value: '3677', text: 'Corsica' },
  { value: '13338', text: 'Cuiaba' },
  { value: '3330', text: 'Cuzco' },
  { value: '3649', text: 'Dahab' },
  { value: '3354', text: 'Dakar' },
  { value: '3447', text: 'Dalaman' },
  { value: '8151', text: 'Dalyan' },
  { value: '3400', text: 'Damascus' },
  { value: '7570', text: 'Dambulla' },
  { value: '3529', text: 'Danang' },
  { value: '3402', text: 'Dar es Salaam' },
  { value: '8158', text: 'Dead Sea' },
  { value: '3141', text: 'Denpasar' },
  { value: '3441', text: 'Dili' },
  { value: '7602', text: 'Djerba' },
  { value: '7071', text: 'Dordogne' },
  { value: '3464', text: 'Dubai' },
  { value: '3630', text: 'Dubrovnik' },
  { value: '3361', text: 'Durban' },
  { value: '3589', text: 'Easter Island' },
  { value: '6486', text: 'El Calafate' },
  { value: '3458', text: 'Entebbe' },
  { value: '3298', text: 'Fes' },
  { value: '6767', text: 'Fethiye' },
  { value: '3561', text: 'Foz do Iguazu' },
  { value: '3394', text: 'Geneva' },
  { value: '3719', text: 'Georgetown' },
  { value: '3101', text: 'Goa' },
  { value: '3451', text: 'Goreme' },
  { value: '3260', text: 'Gozo' },
  { value: '7310', text: 'Granada' },
  { value: '8125', text: 'Graskop' },
  { value: '3274', text: 'GTA' },
  { value: '8196', text: 'Guanacaste' },
  { value: '3716', text: 'Guatemala City' },
  { value: '3643', text: 'Guayaquil' },
  { value: '3604', text: 'Guilin' },
  { value: '3530', text: 'Hai Phong' },
  { value: '5799', text: 'Hammamet' },
  { value: '3590', text: 'Hanga Roa' },
  { value: '3532', text: 'Hanoi' },
  { value: '3511', text: 'Harare' },
  { value: '3636', text: 'Havana' },
  { value: '3659', text: 'Helsinki' },
  { value: '3533', text: 'Ho Chi Minh City' },
  { value: '3534', text: 'Hoi An' },
  { value: '3606', text: 'Hong Kong' },
  { value: '3277', text: 'Hotel Beds' },
  { value: '3535', text: 'Hue' },
  { value: '5130', text: 'Iguazu Falls' },
  { value: '3242', text: 'Ile Sainte-Marie' },
  { value: '3660', text: 'Inari' },
  { value: '3331', text: 'Iquitos' },
  { value: '3452', text: 'Istanbul' },
  { value: '3661', text: 'Ivalo' },
  { value: '3142', text: 'Jakarta' },
  { value: '3362', text: 'Johannesburg' },
  { value: '3332', text: 'Juliaca' },
  { value: '3941', text: 'Kairouan' },
  { value: '5653', text: 'Kalami' },
  { value: '3459', text: 'Kampala' },
  { value: '3572', text: 'Kampot' },
  { value: '3385', text: 'Kandalama' },
  { value: '8035', text: 'Karaagac' },
  { value: '5263', text: 'Kas' },
  { value: '3607', text: 'Kashgar' },
  { value: '3310', text: 'Kathmandu' },
  { value: '7046', text: 'Kayseri' },
  { value: '13377', text: 'Kemer' },
  { value: '3462', text: 'Kiev' },
  { value: '3349', text: 'Kigali' },
  { value: '3403', text: 'Kijangwani' },
  { value: '1924', text: 'Kilimanjaro National Park' },
  { value: '12901', text: 'Kiruna' },
  { value: '3425', text: 'Ko Samui' },
  { value: '3110', text: 'Kochi' },
  { value: '3739', text: 'Kolkata' },
  { value: '4164', text: 'Kompong Cham' },
  { value: '6843', text: 'Konavle' },
  { value: '3248', text: 'Kota Kinabalu' },
  { value: '5835', text: 'Kotor' },
  { value: '3112', text: 'Kovalam' },
  { value: '3430', text: 'Krabi' },
  { value: '3339', text: 'Krakow' },
  { value: '5288', text: 'Kruger National Park' },
  { value: '3249', text: 'Kuala Lumpur' },
  { value: '3250', text: 'Kuching' },
  { value: '3609', text: 'Kunming' },
  { value: '10330', text: 'Kuusamo' },
  { value: '3210', text: 'Kyoto' },
  { value: '12968', text: 'Kyrenia' },
  { value: '3554', text: 'La Paz' },
  { value: '3780', text: 'Lamu' },
  { value: '5644', text: 'Lanjaron' },
  { value: '13571', text: 'Larnaca' },
  { value: '3516', text: 'Las Vegas' },
  { value: '3113', text: 'Leh' },
  { value: '9097', text: 'Liberia' },
  { value: '3333', text: 'Lima' },
  { value: '3509', text: 'Livingstone' },
  { value: '12900', text: 'Longyearbyen' },
  { value: '3517', text: 'Los Angeles' },
  { value: '5868', text: 'Luang Prabang' },
  { value: '9295', text: 'Lucignano' },
  { value: '8939', text: 'Lusaka' },
  { value: '3651', text: 'Luxor' },
  { value: '5304', text: 'Lviv' },
  { value: '3226', text: 'Madaba' },
  { value: '13253', text: 'Madeira' },
  { value: '3373', text: 'Madrid' },
  { value: '3999', text: 'Mairena' },
  { value: '3374', text: 'Malaga' },
  { value: '13327', text: 'Male' },
  { value: '3320', text: 'Managua' },
  { value: '3563', text: 'Manaus' },
  { value: '3338', text: 'Manila' },
  { value: '3251', text: 'Manukan Island' },
  { value: '6981', text: 'Maputo' },
  { value: '3407', text: 'Marangu' },
  { value: '12699', text: 'Marawila' },
  { value: '3299', text: 'Marrakech' },
  { value: '3652', text: 'Marsa Alam' },
  { value: '3683', text: 'Marseille' },
  { value: '3558', text: 'Maun' },
  { value: '3148', text: 'Medan' },
  { value: '3279', text: 'Melbourne' },
  { value: '3219', text: 'Mendoza' },
  { value: '3291', text: 'Mexico City' },
  { value: '9091', text: 'Minuwangoda' },
  { value: '3252', text: 'Miri' },
  { value: '3633', text: 'Mlini' },
  { value: '3231', text: 'Mombasa' },
  { value: '7530', text: 'Monteverde' },
  { value: '3346', text: 'Moscow' },
  { value: '3408', text: 'Moshi' },
  { value: '3253', text: 'Mulu' },
  { value: '3116', text: 'Mumbai' },
  { value: '3696', text: 'Munich' },
  { value: '3324', text: 'Muscat' },
  { value: '3233', text: 'Nairobi' },
  { value: '3188', text: 'Naples' },
  { value: '3334', text: 'Nazca' },
  { value: '3388', text: 'Negombo' },
  { value: '3316', text: 'Nelson' },
  { value: '3741', text: 'New Delhi' },
  { value: '3491', text: 'New Orleans' },
  { value: '3492', text: 'New York' },
  { value: '3848', text: 'Nice' },
  { value: '3653', text: 'Nuweiba' },
  { value: '3211', text: 'Osaka' },
  { value: '3322', text: 'Oslo' },
  { value: '6646', text: 'Ouagadougou' },
  { value: '3300', text: 'Ouarzazate' },
  { value: '5634', text: 'Oukaimeden' },
  { value: '3317', text: 'Paihia' },
  { value: '4645', text: 'Pakse' },
  { value: '3191', text: 'Palermo' },
  { value: '9277', text: 'Palma' },
  { value: '3328', text: 'Panama City' },
  { value: '3687', text: 'Paris' },
  { value: '3553', text: 'Paro' },
  { value: '3538', text: 'Phan Thiet' },
  { value: '3571', text: 'Phnom Penh' },
  { value: '3436', text: 'Phuket' },
  { value: '3959', text: 'Pisa' },
  { value: '3292', text: 'Playa del Carmen' },
  { value: '3311', text: 'Pokhara' },
  { value: '3121', text: 'Port Blair' },
  { value: '8954', text: 'Port Elizabeth' },
  { value: '3329', text: 'Port Moresby' },
  { value: '3638', text: 'Prague' },
  { value: '3365', text: 'Pretoria' },
  { value: '3218', text: 'Puerto Iguazu' },
  { value: '3335', text: 'Puerto Maldonado' },
  { value: '8476', text: 'Puerto Natales' },
  { value: '5550', text: 'Pulau Langkawi' },
  { value: '3254', text: 'Pulau Penang' },
  { value: '3336', text: 'Puno' },
  { value: '3592', text: 'Punta Arenas' },
  { value: '6934', text: 'Queenstown' },
  { value: '3644', text: 'Quito' },
  { value: '3725', text: 'Reykjavik' },
  { value: '3709', text: 'Rhodes' },
  { value: '3566', text: 'Rio de Janeiro' },
  { value: '3198', text: 'Rome' },
  { value: '3520', text: 'San Francisco' },
  { value: '3628', text: 'San Jose' },
  { value: '3255', text: 'Sandakan' },
  { value: '5656', text: 'Santa Barbara' },
  { value: '4884', text: 'Santa Monica' },
  { value: '3593', text: 'Santiago' },
  { value: '3377', text: 'Santiago de Compostela' },
  { value: '3711', text: 'Santorini' },
  { value: '3744', text: 'Sanur' },
  { value: '3353', text: 'Saudi' },
  { value: '3498', text: 'Seattle' },
  { value: '13451', text: 'Seeduwa' },
  { value: '3154', text: 'Senggigi' },
  { value: '3825', text: 'Seoul' },
  { value: '5676', text: 'Seville' },
  { value: '3613', text: 'Shanghai' },
  { value: '3654', text: 'Sharm el-Sheikh' },
  { value: '5521', text: 'Sibu' },
  { value: '3573', text: 'Siem Reap' },
  { value: '7573', text: 'Sigiriya' },
  { value: '3356', text: 'Singapore' },
  { value: '12815', text: 'Smokovec' },
  { value: '5757', text: 'Sofia' },
  { value: '3640', text: 'Soldeu' },
  { value: '3203', text: 'Sorrento' },
  { value: '9239', text: 'Sousse' },
  { value: '3634', text: 'Split' },
  { value: '3347', text: 'St Petersburg' },
  { value: '4431', text: 'Stellenbosch' },
  { value: '3410', text: 'Stone Town' },
  { value: '3438', text: 'Surat Thani' },
  { value: '4842', text: 'Suzdal' },
  { value: '3206', text: 'Syracuse' },
  { value: '3337', text: 'Tambopata' },
  { value: '3301', text: 'Tangier' },
  { value: '3503', text: 'Tashkent' },
  { value: '3547', text: 'Tehran' },
  { value: '3166', text: 'Tel Aviv' },
  { value: '7565', text: 'Thimphu' },
  { value: '3286', text: 'To Be Confirmed' },
  { value: '3212', text: 'Tokyo' },
  { value: '12748', text: 'Toulouse' },
  { value: '3239', text: 'Tripoli' },
  { value: '3133', text: 'Trivandrum' },
  { value: '5867', text: 'Tsumeb' },
  { value: '7705', text: 'Tulum' },
  { value: '3443', text: 'Tunis' },
  { value: '3157', text: 'Ubud' },
  { value: '3134', text: 'Udaipur' },
  { value: '3294', text: 'Ulaanbaatar' },
  { value: '8031', text: 'Umabian' },
  { value: '3621', text: 'Urumqi' },
  { value: '3221', text: 'Ushuaia' },
  { value: '8141', text: 'Valladolid' },
  { value: '12744', text: 'Valletta' },
  { value: '3585', text: 'Vancouver' },
  { value: '3637', text: 'Varadero' },
  { value: '3135', text: 'Varanasi' },
  { value: '3208', text: 'Venice' },
  { value: '3513', text: 'Victoria Falls' },
  { value: '3306', text: 'Vienna' },
  { value: '3238', text: 'Vientiane' },
  { value: '3340', text: 'Warsaw' },
  { value: '3303', text: 'Windhoek' },
  { value: '3587', text: 'Winnipeg' },
  { value: '12742', text: 'Xlendi' },
  { value: '3302', text: 'Yangon' },
  { value: '3625', text: 'Yangshuo' },
  { value: '6290', text: 'Yogyakarta' },
  { value: '4306', text: 'Zadar' },
  { value: '3234', text: 'Zanzibar' },
  { value: '3411', text: 'Zanzibar North Coast' },
  { value: '5627', text: 'Zarnesti' },
  { value: '9280', text: 'Zuberec' },
];
