export default class PlanningProducts {
  public service: any;

  constructor(service: any) {
    this.service = service;
  }

  public async get() {
    const resourcePath = `/dmcProducts`;

    const data = await this.service.get(resourcePath);
    return data.body;
  }
}
