import * as planningTransfers from './planningTransfers';
import planningDepartures from './planningDepartures';
import * as planningAccommodation from './planningAccommodation';
import * as planningProducts from './planningProducts';

export default {
  planningTransfers,
  planningAccommodation,
  planningDepartures,
  planningProducts
};
