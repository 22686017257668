import * as actionTypes from '../actionTypes/departureItinerary';
import IDepartureItinerary from '../types/IDepartureItinerary';
import operationsPortalService from 'common/services/OperationsPortalService';

export const itineraryGetByDepartureRequest = departureId => ({
  type: actionTypes.DEPARTURE_ITINERARY_GET_REQUEST,
  payload: {
    departureId
  }
});

export const itineraryGetSuccess = selectedDepartureItinerary => ({
  type: actionTypes.DEPARTURE_ITINERARY_GET_SUCCESS,
  payload: {
    selectedDepartureItinerary: selectedDepartureItinerary
  }
});

export const itineraryGetByDepartureFailure = () => ({
  type: actionTypes.DEPARTURE_ITINERARY_GET_FAILURE
});

export const getByDeparture = (departureId: number) => async dispatch => {
  try {
    dispatch(itineraryGetByDepartureRequest(departureId));

    const data = await operationsPortalService.DepartureItinerary.getByDeparture(
      departureId
    );

    dispatch(itineraryGetSuccess(data as IDepartureItinerary[]));
  } catch (e) {
    dispatch(itineraryGetByDepartureFailure());
    throw e;
  }
};
