import * as actionTypes from '../../../actionTypes/planningDepartures/selectedDeparture';
import IPlanningDeparture from 'planning/types/IPlanningDeparture';

type State = IPlanningDeparture | null;

export default (state: State = null, action): State => {
  switch (action.type) {
    case actionTypes.PLANNING_DEPARTURE_GET_SUCCESS:
      return action.payload.selectedDeparture;
    case actionTypes.PLANNING_DEPARTURE_CLEAR:
      return null;
    default:
      return state;
  }
};
