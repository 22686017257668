import * as React from 'react';
import { Paper, Tab, Tabs, withStyles } from '@material-ui/core';
import styles from './PlanningDeparturePaxDetailsContainer.styles';
import { WithStyles } from '@material-ui/styles';
import IPax from 'departures/types/IPax';
import PlanningDeparturePaxDetailsTable from './PlanningDeparturePaxDetailsTable';
import PlanningDeparturePaxExtraInfoDetailsTable from './PlanningDeparturePaxExtraInfoDetailsTable';
import PlanningDeparturePaxSummaryDetailsTable from './PlanningDeparturePaxSummaryDetailsTable';
import PlanningDeparturePaxPassportDetailsTable from './PlanningDeparturePaxPassportDetailsTable';

interface IState {
  selectedChildTabIndex?: number;
}

interface IProps extends WithStyles<typeof styles> {
  pax: IPax[];
}

class PlanningDeparturePaxDetailsContainer extends React.Component<
  IProps,
  IState
  > {
  constructor(props) {
    super(props);

    this.state = {
      selectedChildTabIndex: 0
    };
  }

  public render() {
    const { pax, classes } = this.props;

    const { selectedChildTabIndex } = this.state;

    return (
      <Paper className={classes.paper}>
        <Tabs
          value={this.state.selectedChildTabIndex}
          onChange={this.handleChildTabChange}
          className={classes.childTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            id={'btn.summary'}
            label={'Summary'}
          />
          <Tab
            id={'btn.passport'}
            label={'Passport'}
          />
          <Tab
            id={'btn.extraInfo'}
            label={'Extra Info.'}
          />
          <Tab
            id={'btn.additionalTrips'}
            label={'Additional Trips'}
          />
        </Tabs>
        {selectedChildTabIndex === 0 &&
          (
            <PlanningDeparturePaxSummaryDetailsTable pax={pax} />
          )}
        {selectedChildTabIndex === 1 &&
          (
            <PlanningDeparturePaxPassportDetailsTable pax={pax} />
          )}
        {selectedChildTabIndex === 2 &&
          (
            <PlanningDeparturePaxExtraInfoDetailsTable pax={pax} />
          )}
        {selectedChildTabIndex === 3 &&
          (
            <PlanningDeparturePaxDetailsTable pax={pax} />
          )}
      </Paper>
    );
  }
  private handleChildTabChange = (event, selectedChildTabIndex) => {
    this.setState({ selectedChildTabIndex });
  };
}

export default withStyles(styles)(PlanningDeparturePaxDetailsContainer);
