import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import styles from './KeyValueItem.styles';
import { withStyles } from '@material-ui/core/styles';
import { ThemeStyle } from '@material-ui/core/styles/createTypography';
import classNames from 'classnames';

const defaultLabelTypographyStyle: ThemeStyle = 'caption';
const defaultValueTypographyStyle: ThemeStyle = 'body1';

const KeyValueItem = ({
  label = '',
  labelType = defaultLabelTypographyStyle,
  value,
  valueType = defaultValueTypographyStyle,
  icon = <React.Fragment />,
  orientation = KeyValueItemOrientation.vertical,
  valueId = '',
  classes
}) => {
  const iconElement = icon && (
    <div className={classes.icon}>
      {React.cloneElement(icon, { color: 'secondary' })}
    </div>
  );

  const keyValueClasses = classNames({
    [classes.iconContainer]: icon,
    [classes.container]: !icon
  });

  const keyValue = (
    <div className={keyValueClasses}>
      {icon ? iconElement : null}
      <div>
        <Typography
          color="textSecondary"
          variant={labelType}
          className={classes.defaultTypography}
        >
          {label}
        </Typography>{' '}
        {orientation === KeyValueItemOrientation.vertical ? <br /> : null}
        <Typography
          variant={valueType}
          className={classes.defaultTypography}
          id={valueId}
        >
          {value}
        </Typography>
      </div>
    </div>
  );

  return keyValue;
};

export const KeyValueItemOrientation = {
  vertical: 'vertical',
  horizontal: 'horizontal'
};

export default withStyles(styles)(KeyValueItem);
