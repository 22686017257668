import * as React from 'react';
import { connect } from 'react-redux';
import DepartureManifestReport from 'reports/components/departureManifest/DepartureManifestReport';
import DepartureStatusReport from 'reports/components/departureStatus/DepartureStatusReport';
import ExtraServicesReport from 'reports/components/extraServices/ExtraServicesReport';
import { getTripCodeList } from 'reports/selectors/tripCodes';
import productSubType from 'reports/enums/productSubType';
import productType from 'reports/enums/productType';
import planningPortalService from 'reports/services/PlanningPortalServices';
import { Countries, TripCodes } from 'reports/types/ICommon';
import RoleType from 'reports/enums/roleType';
import { CircularProgress } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from './PlanningDashboradContainer.styles'
import { getUserRoles } from 'reports/utils/userRolesUtil';


interface IState {
  countryList: Countries
  roles: string[]
  loading: boolean
}

interface IProps extends WithStyles<typeof styles> {
  tripCodes: TripCodes
}

class PlanningDashboardContainer extends React.Component<IProps, IState>{
  constructor(props) {
    super(props);
    this.state = { countryList: [], roles: [], loading: true };
  }

  public async componentDidMount(): Promise<any> {
    const countries = await planningPortalService.ExtraServices.getCountryList();
    if (countries.length > 0) {
      this.setState({ loading: false, countryList: countries.map(({ countryName }) => ({ value: countryName, text: countryName })) })
    } else {
      this.setState({ loading: false, countryList: [] })
    }
  }

  public render() {
    const classes = this.props.classes;
    const roles = getUserRoles();


    return (
      <>
        {this.shouldDisplayLoading() && <div className={classes.loaderContainer}><CircularProgress /></div>}

        {this.shouldDisplayReports(roles) && roles.map((role) => {
          if (role.toLowerCase() === RoleType.Departure_Status) {
            return <div key='departure-status'> <DepartureStatusReport tripCodes={this.props.tripCodes} /> </div>
          }

          if (role.toLowerCase() === RoleType.Departure_manifest) {
            return <div key='departure-manifest'><DepartureManifestReport /> </div>
          }

          if (role.toLowerCase() === RoleType.Accommodation) {
            return <div key={productType.ACCOMMODATION}> <ExtraServicesReport heading={'Pre-Post Accommodation Report'} productType={productType.ACCOMMODATION} productSubType={productSubType.ACCOMMODATION_PRE_POST} countryList={this.state.countryList} /></div>
          }

          if (role.toLowerCase() === RoleType.Transfer) {
            return <div key={productType.TRANSFER}><ExtraServicesReport heading={'Transfer Report'} productType={productType.TRANSFER} productSubType={productSubType.TRANSFER} countryList={this.state.countryList} /></div>
          }

          return null
        })}

      </>
    );


  }
  private shouldDisplayLoading = (): boolean => {
    return this.state.loading
  }

  private shouldDisplayReports = (roles: string[]): boolean => {
    return !this.state.loading && roles && roles.length > 0
  }
}

const mapStateToProps = state => ({
  tripCodes: getTripCodeList(state),
})

export default connect(mapStateToProps)(withStyles(styles)(PlanningDashboardContainer))