import { TrackJS } from 'trackjs';
import env from 'common/constants/env';

const trackjs = authContext =>
  TrackJS.install({
    token: env.REACT_APP_TRACKJS_TOKEN,
    userId: authContext.getCachedUser().profile.oid,
    application: env.REACT_APP_TRACKJS_APPLICATION,
    version: env.REACT_APP_VERSION,
    onError: payload => {
      payload.network = payload.network.filter(item => {
        return item.url.indexOf('ipv4.icanhazip.com/') === -1;
      });
      return true;
    }
  });

export default trackjs;
