import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  root: {
    width: '80%'
  },
  paper: {
    width: '80%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700,
    width: 1250,
    border: '1px solid gray'
  },
  titleText: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)"
  },
  typography: {
    whiteSpace: "pre-line"
  },
  nameTableCol: {
    width: 150,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)"
  },
  tableDateCol: {
    width: 400,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)"
  },
  contextTableCol: {
    width: 65,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)"
  },
  priorityHighIcon: {
    color: '#CC0033',
    fontSize: 'large',
    verticalAlign: 'sub',
    paddingRight: '2px'
  }
});
