import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import KeyValueItem from 'common/components/KeyValueItem';
import { withStyles } from '@material-ui/core/styles';
import { sortBy, groupBy } from 'lodash';
import styles from './DepartureTransfers.styles';
import formatDate from 'common/helpers/formatDate';

const DepartureTransfers = ({ transfers, classes }) => {
  const sortedTransfers = sortBy(transfers, ['flightDateTime']);

  const transferGroups = groupBy(sortedTransfers, val => {
    return formatDate(val.flightDateTime);
  });

  return (
    <List className={classes.root}>
      {Object.keys(transferGroups).map(transferGroupKey => {
        const transfers = transferGroups[transferGroupKey];

        const transferProductGroups = groupBy(transfers, val => {
          return `${val.startingPoint.toLowerCase()}|${val.finishingPoint.toLowerCase()}`;
        });

        const transferProductListItems = getTransferProductList(
          transferProductGroups,
          classes
        );

        return (
          <div key={transferGroupKey}>
            <ListSubheader
              disableSticky={true}
              id={'pax.transfers.transferGroupKey_' + transferGroupKey}
              className={classes.subHeader}
            >
              {transferGroupKey}
            </ListSubheader>
            {transferProductListItems}
            <Divider />
          </div>
        );
      })}
    </List>
  );
};

function getTransferProductList(transferProductGroups, classes) {
  return Object.keys(transferProductGroups).map(transferProductGroupKey => {
    const transferProducts = transferProductGroups[transferProductGroupKey];

    const location = getLocation(
      transferProducts[0].startingPoint,
      transferProducts[0].finishingPoint,
      classes
    );
    const transferFlightGroups = groupBy(transferProducts, val => {
      const flightTime = formatDate(val.flightDateTime, 'HH:mm');
      return `${val.flightNumber.trim().toUpperCase()} • ${flightTime}`;
    });

    const transferProductsList = getTransferList(transferFlightGroups, classes);

    return (
      <List key={transferProductGroupKey}>
        <ListSubheader className={classes.listContainer} disableSticky={true}>
          {location}
        </ListSubheader>
        {transferProductsList}
      </List>
    );
  });
}

function getLocation(startingPoint, finishingPoint, classes) {
  return (
    <div className={classes.listContainer}>
      <KeyValueItem
        label={'From'}
        value={startingPoint}
        orientation={'horizontal'}
      />
      <KeyValueItem
        label={'To'}
        value={finishingPoint}
        orientation={'horizontal'}
      />
    </div>
  );
}

function getTransferList(transferProductFlightsGroups, classes) {
  return Object.keys(transferProductFlightsGroups).map(
    transferProductFlightsGroupsKey => {
      const transferProductFlights =
        transferProductFlightsGroups[transferProductFlightsGroupsKey];

      const transferProductFlightsList = transferProductFlights.map(
        transferProductFlight => {
          return (
            <div
              key={`${transferProductFlightsGroupsKey} ${
                transferProductFlight.fullNameWithTitle
              }`}
            >
              {transferProductFlight.fullNameWithTitle}
            </div>
          );
        }
      );

      return (
        <List key={'TransferList'} className={classes.secondaryContainer}>
          <ListItem
            key={transferProductFlightsGroupsKey}
            className={classes.listContainer}
          >
            <ListItemText
              primary={
                <Typography variant="body2" className={classes.flightLabel}>
                  {transferProductFlightsGroupsKey}
                </Typography>
              }
              secondary={
                <Typography component={'span'} variant="body1">
                  {transferProductFlightsList}
                </Typography>
              }
            />
          </ListItem>
        </List>
      );
    }
  );
}

export default withStyles(styles)(DepartureTransfers);
