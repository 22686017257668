import IPlanningDepartureSearchCriteria from '../types/IPlanningDepartureSearchCriteria';
import convertToAPIDate from 'common/helpers/convertToAPIDate';
import { startOfDay, endOfDay } from 'date-fns';

export default class PlanningDepartures {
  public service: any;

  constructor(service) {
    this.service = service;
  }

  public async getBySearchCriteria(
    searchCriteria: IPlanningDepartureSearchCriteria
  ) {
    const resourcePath = `/planningDepartures`;

    // Replace dates to send strings
    const searchCriteriaModified = {
      ...searchCriteria,
      startDateFrom: convertToAPIDate(startOfDay(searchCriteria.startDateFrom)),
      startDateTo: searchCriteria.startDateTo
        ? convertToAPIDate(endOfDay(searchCriteria.startDateTo))
        : null,
      updatedSince: searchCriteria.updatedSince
        ? startOfDay(searchCriteria.updatedSince)
        : null
    };

    const data = await this.service.post(resourcePath, searchCriteriaModified);
    return data.body;
  }

  public async getById(departureId: number) {
    const resourcePath = `/planning/departure/${encodeURIComponent(
      departureId.toString()
    )}`;

    const data = await this.service.get(resourcePath);
    return data.body;
  }
}
