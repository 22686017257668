import * as actionTypes from '../../actionTypes/departureTransfer';
import IDepartureTransfer from '../../types/IDepartureTransfer';

type State = IDepartureTransfer | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.DEPARTURE_TRANSFERS_GET_SUCCESS:
      return action.payload.selectedDepartureTransfers;
    default:
      return state;
  }
};
