import * as React from 'react';
import NoResultsMessage from 'common/components/NoResultsMessage';
import IPlanningTransfer from '../types/IPlanningTransfer';
import KeyValueItem, {
  KeyValueItemOrientation
} from '../../common/components/KeyValueItem';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import styles from './PlanningTransferList.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import formatDate from 'common/helpers/formatDate';

interface IProps extends WithStyles<typeof styles> {
  transfers?: IPlanningTransfer[];
}

class PlanningTransferList extends React.Component<IProps> {
  public render() {
    const { transfers, classes } = this.props;

    if (!!transfers && transfers.length === 0) {
      return <NoResultsMessage message="No results to display." />;
    }

    return <Card>{this.renderTransfers(transfers, classes)}</Card>;
  }

  private renderTransfers(transfers, classes) {
    return (
      transfers &&
      transfers.map((transfer, i) => (
        <div className={classes.transferContainer}>
          <Grid container={true} spacing={1}>
            <Grid item xs={2} sm={2}>
              <KeyValueItem
                label="Booking ID:"
                value={transfer.bookingId}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Title:"
                value={transfer.title || emptyFieldText}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="First Name:"
                value={transfer.firstName || emptyFieldText}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Surname:"
                value={transfer.surname || emptyFieldText}
                orientation={KeyValueItemOrientation.horizontal}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <KeyValueItem
                label="Transfer Name:"
                value={transfer.serviceName}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Transfer Date:"
                value={formatDate(transfer.transferDateTime)}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Flight Time:"
                value={formatDate(transfer.transferDateTime)}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Flight Number:"
                value={transfer.flightNumber}
                orientation={KeyValueItemOrientation.horizontal}
              />
              <KeyValueItem
                label="Linked Departure Code:"
                value={transfer.linkedDepartureCode || emptyFieldText}
                orientation={KeyValueItemOrientation.horizontal}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <KeyValueItem
                label="Starting Point:"
                value={transfer.startingPoint}
                orientation={KeyValueItemOrientation.horizontal}
              />
              <KeyValueItem
                label="Finishing Point:"
                value={transfer.finishingPoint}
                orientation={KeyValueItemOrientation.horizontal}
              />
              <KeyValueItem
                label="City:"
                value={transfer.city || emptyFieldText}
                orientation={KeyValueItemOrientation.horizontal}
              />
              <KeyValueItem
                label="Country:"
                value={transfer.country}
                orientation={KeyValueItemOrientation.horizontal}
              />

              <KeyValueItem
                label="Current Status:"
                value={transfer.status}
                orientation={KeyValueItemOrientation.horizontal}
              />
            </Grid>
          </Grid>
          <Divider />
        </div>
      ))
    );
  }
}

const emptyFieldText = ' - ';

export default withStyles(styles)(PlanningTransferList);
