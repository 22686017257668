import operationsPortalService from 'common/services/OperationsPortalService';
import IDeparture from '../types/IDeparture';
import selectors from 'departures/selectors';
import * as actionTypes from '../actionTypes/selectedDeparture';

export const departuresGetByIdRequest = (departureId: number) => ({
  type: actionTypes.DEPARTURE_GET_REQUEST,
  payload: {
    departureId: departureId
  }
});

export const departureByIdGetSuccess = departure => ({
  type: actionTypes.DEPARTURE_GET_SUCCESS,
  payload: {
    selectedDeparture: departure
  }
});

export const departureClear = () => ({
  type: actionTypes.DEPARTURE_CLEAR
});

export const departureGetFailure = () => ({
  type: actionTypes.DEPARTURE_GET_FAILURE
});

function shouldFetch(state, departureId) {
  const stateDeparture = selectors.selectedDeparture.getDepartureById(state);

  return !stateDeparture || stateDeparture.id !== departureId;
}

export const getDepartureById = (departureId: number) => async (
  dispatch,
  getState
) => {
  try {
    if (!shouldFetch(getState(), departureId)) {
      return null;
    }
    dispatch(departureClear());

    dispatch(departuresGetByIdRequest(departureId));

    const data = await operationsPortalService.Departures.getById(departureId);

    dispatch(departureByIdGetSuccess(data as IDeparture));
    return;
  } catch (e) {
    dispatch(departureGetFailure());
    throw e;
  }
};
