import * as React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from '@material-ui/core/';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from './CovidQuestionnaireResponsesTable.styles';
import ICovidQuestionnaireDisplayResponse from '../types/ICovidQuestionnaireDisplayResponse';
import ICovidQuestionnaireResponse from '../types/ICovidQuestionnaireResponse';
import Icon from '@material-ui/core/Icon';

interface IProps extends WithStyles<typeof styles> {
  covidQuestionnaireDisplayResponses: ICovidQuestionnaireDisplayResponse[];
  isCrew: boolean;
  title: string
}

class CovidHealthScreeningTable extends React.Component<IProps> {
  constructor(props) {
    super(props);
  }

  public render() {

    const { covidQuestionnaireDisplayResponses, isCrew, title, classes } = this.props;

    if (
      covidQuestionnaireDisplayResponses === undefined ||
      covidQuestionnaireDisplayResponses.length === 0
    ) {
      return '';
    }

    const questionIds: number[] = [];
    covidQuestionnaireDisplayResponses[0].covidQuestionnaireResponses.map(q => questionIds.push(q.covidQuestionnaireQuestionId));

    return (
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item xs className={classes.titleText}>
            {title}
          </Grid>
          <Grid item xs>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.contextTableCol}>{isCrew ? "Crew ID" : "Booking ID"}</TableCell>
                  <TableCell className={classes.nameTableCol}>Name</TableCell>
                  <TableCell className={classes.titleText}>Consent</TableCell>
                  {this.getQuestions(questionIds, classes)}
                  <TableCell className={classes.titleText}>Submitted Date/Time (Local)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {covidQuestionnaireDisplayResponses.map(covidQuestionnaireDisplayResponse => (
                  <TableRow>
                    <TableCell>{covidQuestionnaireDisplayResponse.contextId}</TableCell>
                    <TableCell>{this.getName(covidQuestionnaireDisplayResponse)}</TableCell>
                    <TableCell>{this.getConsent(covidQuestionnaireDisplayResponse)}</TableCell>
                    {covidQuestionnaireDisplayResponse.covidQuestionnaireResponses.slice(0, -1).map(covidQuestionAnswer => (// using 'slice(0, -1) ignore consent
                      this.getAnswers(covidQuestionAnswer, classes)
                    ))}
                    <TableCell>{covidQuestionnaireDisplayResponse.submittedDate} {covidQuestionnaireDisplayResponse.isSubmittedDateUTC ? "(UTC)" : ""}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
    );
  }

  private getQuestions(questionIds: number[], classes): React.ReactNode {
    return questionIds.slice(0, -1).map((questionid, index) => (// Ignore display consent as a question in header
      <TableCell className={classes.tableDateCol}>Question {index + 1}</TableCell>
    ));
  }

  private getConsent(covidQuestionnaireDisplayResponse: ICovidQuestionnaireDisplayResponse): React.ReactNode {
    return covidQuestionnaireDisplayResponse.consent ? 'Y' : '-';
  }

  private getAnswers(covidQuestionnaireResponse: ICovidQuestionnaireResponse, classes) {
    if (covidQuestionnaireResponse.covidQuestionAnswerTextId === 0) {
      return (<TableCell> - </TableCell>);
    }

    return (<TableCell>
      {
        covidQuestionnaireResponse.isAlertingAnswer &&
        (<Icon className={classes.priorityHighIcon}>report_problem</Icon>)
      }
      {covidQuestionnaireResponse.answerText}
    </TableCell>);
  }

  private getName(covidQuestionnaireDisplayResponse: ICovidQuestionnaireDisplayResponse) {
    return (covidQuestionnaireDisplayResponse.title === undefined || covidQuestionnaireDisplayResponse.title === '') ? (covidQuestionnaireDisplayResponse.firstName + ' ' + covidQuestionnaireDisplayResponse.surName) : (covidQuestionnaireDisplayResponse.title + ' ' + covidQuestionnaireDisplayResponse.firstName + ' ' + covidQuestionnaireDisplayResponse.surName);
  }
}

export default withStyles(styles)(CovidHealthScreeningTable);
