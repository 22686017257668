import { combineReducers } from 'redux';
import selectedDeparture from './selectedDeparture';
import searchCriteria from 'departures/reducers/searchCriteria';
import searchResults from 'departures/reducers/searchResults';

export default combineReducers({
  selectedDeparture: selectedDeparture,
  searchCriteria: searchCriteria,
  searchResults: searchResults
});
