import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import selectors from '../selectors';
import DepartureCard from './DepartureCard';
import IDeparture from '../types/IDeparture';
import actions from '../actions';
import { RouteComponentProps } from 'react-router';
import { AppState } from 'common/reducers/reducer';
import CovidQuestionnaireResponsesCard from './CovidQuestionnaireResponsesCard';
import ICovidQuestionnaireResponses from '../types/ICovidQuestionnaireResponses';
import ICovidQuestionnaireDisplayResponse from "../types/ICovidQuestionnaireDisplayResponse";
import ICovidQuestionnaireQuestion from 'departures/types/ICovidQuestionnaireQuestion';
import ICovidHealthScreeningQuestion from 'departures/types/ICovidHealthScreeningQuestion';
import ICovidQuestionnaireResponse from 'departures/types/ICovidQuestionnaireResponse';
import systemConfigSelectors from 'systemConfigs/selectors';
import { IS_OPERATIONS_DASHBOARD_COVID_SURVEY_ENABLED } from 'common/constants/systemConfigCode';
import enumCovidQuestionnaireType from 'departures/enums/enumCovidQuestionnaireType';

interface IDispatchProps {
  actions?: any;
}

interface IProps {
  departureId: number;
  departure: IDeparture;
  covidQuestionnaireResponses: ICovidQuestionnaireResponses;
  covidHealthScreeningResponses: ICovidQuestionnaireResponses;
  isOperationsDashboardCovidSurveyEnabled: boolean;
}

interface IRouteParams {
  id: string;
}

type Props = IProps & IDispatchProps & RouteComponentProps<IRouteParams>;

class CovidQuestionnaireResponsesContainer extends React.Component<Props> {
  public render() {
    const { departure, covidHealthScreeningResponses, covidQuestionnaireResponses, isOperationsDashboardCovidSurveyEnabled } = this.props;
    const paxResponses = this.getPaxResult(covidQuestionnaireResponses);
    const paxHealthScreeningResponses = this.getPaxResult(covidHealthScreeningResponses);
    const crewResponses = this.getCrewResult(covidQuestionnaireResponses);

    let covidQuestionnairePaxQuestions: ICovidQuestionnaireQuestion[] = [];
    let covidQuestionnaireCrewQuestions: ICovidQuestionnaireQuestion[] = [];
    let covidHealthScreeningPaxQuestions: ICovidHealthScreeningQuestion[] = [];

    if (covidQuestionnaireResponses != null) {
      if (covidQuestionnaireResponses.covidQuestionnairePaxResponses.length > 0) {
        covidQuestionnairePaxQuestions = this.getCovidQuestionnaireQuestions(covidQuestionnaireResponses.covidQuestionnairePaxResponses[0].covidQuestionnaireResponses, covidQuestionnaireResponses.covidQuestionnairePaxQuestions);

      }
      if (covidQuestionnaireResponses.covidQuestionnaireCrewResponses.length > 0) {
        covidQuestionnaireCrewQuestions = this.getCovidQuestionnaireQuestions(covidQuestionnaireResponses.covidQuestionnaireCrewResponses[0].covidQuestionnaireResponses, covidQuestionnaireResponses.covidQuestionnaireCrewQuestions);
      }
      if (covidHealthScreeningResponses != null) {
        covidHealthScreeningPaxQuestions = this.getCovidQuestionnaireQuestions(covidHealthScreeningResponses.covidQuestionnairePaxResponses[0].covidQuestionnaireResponses, covidHealthScreeningResponses.covidQuestionnairePaxQuestions)
      }
    }

    return (
      isOperationsDashboardCovidSurveyEnabled && departure && covidQuestionnaireResponses &&
      (
        <React.Fragment>
          <DepartureCard departure={departure} />
          <CovidQuestionnaireResponsesCard
            paxResponses={paxResponses}
            crewResponses={crewResponses}
            paxQuestions={covidQuestionnairePaxQuestions}
            crewQuestions={covidQuestionnaireCrewQuestions}
            paxHealthScreeningResponses={paxHealthScreeningResponses}
            PaxHealthScreeningQuestions={covidHealthScreeningPaxQuestions} />
        </React.Fragment>
      )
    );
  }

  public getCovidQuestionnaireQuestions(covidQuestionnaireResponses: ICovidQuestionnaireResponse[], covidQuestionnaireQuestions: ICovidQuestionnaireQuestion[])
    : ICovidQuestionnaireQuestion[] {

    const covidQuestionnaireOrderedQuestions: ICovidQuestionnaireQuestion[] = [];
    covidQuestionnaireResponses.map(element => {

      const covidQuestion = covidQuestionnaireQuestions
        .find(question => question.covidQuestionnaireQuestionId === element.covidQuestionnaireQuestionId)

      if (covidQuestion != null) {
        covidQuestionnaireOrderedQuestions.push(covidQuestion);
      }
    });

    return covidQuestionnaireOrderedQuestions;
  }

  public getPaxResult(covidQuestionnaireResponses: ICovidQuestionnaireResponses): ICovidQuestionnaireDisplayResponse[] {
    const covidQuestionnaireDisplayResponse: ICovidQuestionnaireDisplayResponse[] = [];

    if (covidQuestionnaireResponses == null) {
      return covidQuestionnaireDisplayResponse;
    }

    covidQuestionnaireResponses.covidQuestionnairePaxResponses.map(element => {
      const covidQuestionnaireResponse: any = {};
      covidQuestionnaireResponse.contextId = element.bookingId;
      covidQuestionnaireResponse.title = element.title;
      covidQuestionnaireResponse.firstName = element.firstName;
      covidQuestionnaireResponse.surName = element.surName;
      covidQuestionnaireResponse.covidQuestionnaireResponses = element.covidQuestionnaireResponses;
      covidQuestionnaireResponse.consent = element.consent;
      covidQuestionnaireResponse.submittedDate = element.submittedDate;
      covidQuestionnaireResponse.isSubmittedDateUTC = element.isSubmittedDateUTC;

      covidQuestionnaireDisplayResponse.push(covidQuestionnaireResponse);
    });

    return covidQuestionnaireDisplayResponse
  }

  public getCrewResult(covidQuestionnaireResponses: ICovidQuestionnaireResponses): ICovidQuestionnaireDisplayResponse[] {
    const covidQuestionnaireDisplayResponse: ICovidQuestionnaireDisplayResponse[] = [];

    if (covidQuestionnaireResponses == null) {
      return covidQuestionnaireDisplayResponse;
    }
    covidQuestionnaireResponses.covidQuestionnaireCrewResponses.map(element => {
      const covidQuestionnaireResponse: any = {};
      covidQuestionnaireResponse.contextId = element.crewId;
      covidQuestionnaireResponse.firstName = element.firstName;
      covidQuestionnaireResponse.surName = element.surName;
      covidQuestionnaireResponse.covidQuestionnaireResponses = element.covidQuestionnaireResponses;
      covidQuestionnaireResponse.consent = element.consent;
      covidQuestionnaireResponse.submittedDate = element.submittedDate;
      covidQuestionnaireResponse.isSubmittedDateUTC = element.isSubmittedDateUTC;

      covidQuestionnaireDisplayResponse.push(covidQuestionnaireResponse);
    });

    return covidQuestionnaireDisplayResponse
  }

  public componentDidMount() {
    this.loadData();
  }

  public loadData() {
    this.props.actions.getDeparture(this.props.departureId);
    this.props.actions.getCovidQuestionQuestionnaireByDeparture(this.props.departureId, enumCovidQuestionnaireType.CUSTOMER);
    this.props.actions.getCovidHealthScreeningByDeparture(this.props.departureId, enumCovidQuestionnaireType.PRE_TRIP_COVID_TEST);
  }
}

const mapStateToProps = (
  state: AppState,
  params: RouteComponentProps<IRouteParams>
): IProps => {
  const departure = selectors.selectedDeparture.getDepartureById(state);
  const covidQuestionnaireResponses = selectors.selectedDepartureCovidQuestionnaireResponses.getCovidQuestionnaireResponsesByDeparture(state);
  const healthScreeningResponses = selectors.selectedDeparturePaxHealthScreeningResponses.getHealthScreeningResponsesByDeparture(state);
  const isOperationsDashboardCovidSurveyEnabled = systemConfigSelectors.systemConfigs.getIsOperationsDashboardCovidSurveyEnabled(
    state,
    IS_OPERATIONS_DASHBOARD_COVID_SURVEY_ENABLED
  )
  return {
    departureId: parseInt(params.match.params.id, 10),
    departure: departure,
    covidQuestionnaireResponses: covidQuestionnaireResponses,
    isOperationsDashboardCovidSurveyEnabled: isOperationsDashboardCovidSurveyEnabled,
    covidHealthScreeningResponses: healthScreeningResponses
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        getDeparture: actions.selectedDeparture.getDepartureById,
        getCovidQuestionQuestionnaireByDeparture: actions.departureCovidQuestionnaire.getDepartureCovidQuestionnaireResponses,
        getCovidHealthScreeningByDeparture: actions.departureHealthScreening.getHealthScreeningResponses
      },
      dispatch
    )
  }
});

export default connect<IProps>(
  mapStateToProps,
  mapDispatchToProps
)(CovidQuestionnaireResponsesContainer);
