import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IPlanningDepartureSearchCriteria from 'common/types/IPlanningDepartureSearchCriteria';
import styles from './PlanningDepartureSearch.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import IPlanningDeparture from 'planning/types/IPlanningDeparture';
import CustomTextField from '../../common/components/CustomTextField';
import DateRangeFilter from 'common/forms/DateRangeFilter';
import avoidEnterOnKeyPress from '../../common/helpers/avoidEnterOnKeyPress';
import CustomSelectField from 'common/components/CustomSelectField';
import InlineDatePicker from 'common/forms/InlineDatePicker';
import isValidDateFormat from 'common/forms/validators/isValidDateFormat';
import isBeforeDate from 'common/forms/validators/isBeforeDate';
import statuses from 'planning/enums/statuses';

interface IProps extends WithStyles<typeof styles> {
  searchCriteria: IPlanningDepartureSearchCriteria;
  setSearchCriteria: (searchCriteria: IPlanningDepartureSearchCriteria) => void;
  getDepartures: (
    searchCriteria: IPlanningDepartureSearchCriteria
  ) => IPlanningDeparture[];
}

class PlanningDepartureSearch extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  public render() {
    const classes = this.props.classes;
    const today = new Date();

    return (
      <div className={classes.stickyContent}>
        <Card>
          <CardContent>
            <Form
              onSubmit={this.onSubmit}
              initialValues={this.props.searchCriteria}
              render={({
                handleSubmit,
                reset,
                submitting,
                pristine,
                values,
                errors
              }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate={true}
                    onKeyPress={avoidEnterOnKeyPress}
                  >
                    <div className={classes.searchContainer}>
                      <DateRangeFilter
                        minDateFrom={new Date()}
                        isToRequired={false}
                        alignment="horizontal"
                        id="startDate"
                      />

                      <div className={classes.inputsContainer}>
                        <Field
                          name={'updatedSince'}
                          allowNull={true}
                          validate={(value, allValues) =>
                            isValidDateFormat(value) || isBeforeDate(value, today)
                          }
                        >
                          {({ input, meta }) => {
                            const { value, onChange, ...restInput } = input;

                            return (
                              <InlineDatePicker
                                id={'updatedSince'}
                                label={'Updated Since'}
                                value={value}
                                {...restInput}
                                onChange={onChange}
                                disableFuture={true}
                                error={meta.touched && meta.error !== undefined}
                                helperText={
                                  meta.touched &&
                                  meta.error && (
                                    <React.Fragment>{meta.error}</React.Fragment>
                                  )
                                }
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className={classes.inputsContainer}>
                        <Field name={'departureCode'} allowNull={true}>
                          {({ input, meta }) => {
                            const { value, onChange } = input;

                            return (
                              <CustomTextField
                                label="Departure Code"
                                value={value}
                                onChange={onChange}
                                clearable={true}
                                onClear={onChange}
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className={classes.inputsContainer}>
                        <Field name={'statuses'} allowNull={true}>
                          {({ input, meta }) => {
                            const { value, onChange } = input;

                            return (
                              <CustomSelectField
                                label="Status"
                                value={value}
                                onChange={onChange}
                                options={statuses}
                                clearable={true}
                                onClear={onChange}
                                aggregateMultiSelectionsOnDisplay={true}
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className={classes.actionButtonsContainer}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          id="btn.search"
                          disabled={submitting}
                        >
                          Search
                      </Button>
                      </div>
                    </div>
                  </form>
                )}
            />
          </CardContent>
        </Card>
      </div>
    );
  }

  private onSubmit = async values => {
    try {
      const cleansedSearchCriteria: IPlanningDepartureSearchCriteria = {
        departureCode: values.departureCode,
        startDateFrom: values.startDateFrom,
        startDateTo: values.startDateTo,
        updatedSince: values.updatedSince,
        statuses: values.statuses
      };

      await this.props.setSearchCriteria(cleansedSearchCriteria);
      await this.props.getDepartures(cleansedSearchCriteria);
    } catch (e) {
      throw e;
    }
  };
}

export default withStyles(styles)(PlanningDepartureSearch);
