import * as actionTypes from '../../actionTypes/departureAccommodation';
import IDepartureAccommodation from '../../types/IDepartureAccommodation';

type State = IDepartureAccommodation | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.DEPARTURE_ACCOMMODATION_GET_SUCCESS:
      return action.payload.selectedDepartureAccommodation;
    default:
      return state;
  }
};
