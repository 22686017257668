import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import DateRangeFilter from 'common/forms/DateRangeFilter';
import IPlanningAccommodationSearchCriteria from 'common/types/IPlanningAccommodationSearchCriteria';
import styles from './PlanningAccommodationSearch.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import IPlanningAccommodation from 'planning/types/IPlanningAccommodation';
import avoidEnterOnKeyPress from '../../common/helpers/avoidEnterOnKeyPress';

interface IProps extends WithStyles<typeof styles> {
  initialSearchCriteria: IPlanningAccommodationSearchCriteria;
  selectedDmcs: string[];
  getAccommodation: (
    searchCriteria: IPlanningAccommodationSearchCriteria
  ) => IPlanningAccommodation[];
}

class PlanningAccommodationSearch extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const classes = this.props.classes;

    return (
      <Card>
        <CardContent>
          <Form
            onSubmit={this.onSubmit}
            initialValues={this.props.initialSearchCriteria}
            render={({
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
              errors
            }) => (
              <form
                onSubmit={handleSubmit}
                noValidate={true}
                onKeyPress={avoidEnterOnKeyPress}
              >
                <div className={classes.searchContainer}>
                  <DateRangeFilter id="accommodationDate" />
                </div>
                <div className={classes.actionButtonsContainer}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    id="btn.search"
                    disabled={submitting}
                  >
                    Search
                  </Button>
                </div>
              </form>
            )}
          />
        </CardContent>
      </Card>
    );
  }

  private onSubmit = async values => {
    try {
      const cleansedSearchCriteria: IPlanningAccommodationSearchCriteria = {
        accommodationDateFrom: values.accommodationDateFrom,
        accommodationDateTo: values.accommodationDateTo
      };

      await this.props.getAccommodation(cleansedSearchCriteria);
    } catch (e) {
      throw e;
    }
  };
}

export default withStyles(styles)(PlanningAccommodationSearch);
