import * as React from 'react';
import { detect } from 'detect-browser';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import env from '../../common/constants/env';
import styles from './BrowserNotSupportedMessage.styles';

let browserNameVersion = '';

const isBrowserSupported = () => {
  const browser = detect();
  if (browser && browser.version) {
    browserNameVersion = `(${browser.name}, ${browser.version}) `;

    // Browser Version
    const majorBrowserVersion = +browser.version.split('.')[0];
    if (isNaN(majorBrowserVersion)) {
      return false;
    }

    // Browser Name
    switch (browser && browser.name) {
      case 'chrome':
      case 'crios':
        return majorBrowserVersion >= +env.REACT_APP_BROWSER_MIN_VERSION_CHROME;
      default:
        return false;
    }
  }

  return false;
};

const BrowserNotSupportedMessage: React.FC<
  WithStyles<typeof styles>
> = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      {!isBrowserSupported() && (
        <div className={classes.container}>
          <Typography variant="body2" className={classes.content}>
            Your browser {browserNameVersion}is not supported.
            <br />
            As a minimum please use Chrome
            {' ' + process.env.REACT_APP_BROWSER_MIN_VERSION_CHROME}.
          </Typography>
        </div>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(BrowserNotSupportedMessage);
