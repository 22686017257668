import * as actionTypes from '../../actionTypes/selectedDeparture';
import IDeparture from '../../types/IDeparture';

type State = IDeparture[] | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.DEPARTURE_GET_SUCCESS:
      return action.payload.selectedDeparture;
    case actionTypes.DEPARTURE_CLEAR:
      return null;
    default:
      return state;
  }
};
