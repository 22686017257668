import { combineReducers } from 'redux';
import selectedDeparture from './selectedDeparture';
import selectedDepartureAccommodation from './selectedDepartureAccommodation';
import selectedDepartureSightseeing from './selectedDepartureSightseeing';
import selectedDepartureCrew from './selectedDepartureCrew';
import selectedDepartureItinerary from './selectedDepartureItinerary';
import selectedDeparturePax from './selectedDeparturePax';
import selectedDeparturePaxChanges from './selectedDeparturePaxChanges';
import selectedDepartureTrasfers from './selectedDepartureTransfers';
import selectedDepartureCovidQuestionnaireResponses from './selectedDepartureCovidQuestionnaireResponses';
import selectedDeparturePaxHealthScreeningResponses from './selectedDeparturePaxHealthScreeningResponses';

export default combineReducers({
  departure: selectedDeparture,
  accommodation: selectedDepartureAccommodation,
  sightseeing: selectedDepartureSightseeing,
  crew: selectedDepartureCrew,
  itinerary: selectedDepartureItinerary,
  pax: selectedDeparturePax,
  paxChanges: selectedDeparturePaxChanges,
  transfers: selectedDepartureTrasfers,
  covidQuestionnaireResponses: selectedDepartureCovidQuestionnaireResponses,
  healthScreeningResponses: selectedDeparturePaxHealthScreeningResponses
});
