import * as actionTypes from '../actionTypes/reporting';
import IUnlockTripReportCriteria from 'common/types/IUnlockTripReportCriteria';
import operationsPortalService from 'common/services/OperationsPortalService';

export const unlockTripReportRequest = IUnlockTripReportCriteria => ({
  type: actionTypes.REPORTING_GET_REQUEST,
  payload: {
    IUnlockTripReportCriteria
  }
});

export const unlockTripReportSuccess = () => ({
  type: actionTypes.REPORTING_GET_SUCCESS
});

export const unlockTripReportFailure = () => ({
  type: actionTypes.REPORTING_GET_FAILURE
});

export const unlockTripReport = (
  criteria: IUnlockTripReportCriteria
) => async dispatch => {
  try {
    dispatch(unlockTripReportRequest(criteria));

    await operationsPortalService.Reporting.unlockTripReportByCrewId(
      criteria
    );

    dispatch(unlockTripReportSuccess());
  } catch (e) {
    dispatch(unlockTripReportFailure());
    throw e;
  }
};
