import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import ApplicationFiltersContainer from 'common/containers/ApplicationFiltersContainer';
import styles from '../../common/components/CustomToolbar.styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { authContext } from 'common/authentication/adalConfig';
import Button from '@material-ui/core/Button';
import { ROUTE_DASHBOARD, ROUTE_PLANNING_DASHBOARD } from 'common/constants/routes';
import env from '../../common/constants/env';
import RoleType from 'reports/enums/roleType';
import { getUserRoles } from 'reports/utils/userRolesUtil';


interface IProps extends WithStyles<typeof styles> {
}

class CustomToolbar extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public logout() {
    authContext.logOut();
  }

  public render() {
    const { classes } = this.props;
    const roles = getUserRoles();

    return (
      <div className={classes.appBarContainer}>
        <AppBar position="static">
          <Toolbar>
            <div className={classes.title}>
              {this.showOperationDashboard(roles) && (<Button href={ROUTE_DASHBOARD} color="inherit" className={classes.titleButton} >
                <Typography color="inherit" variant="h6">{env.REACT_APP_NAME}</Typography>
              </Button>
              )}

              {this.showPlanningDashboard(roles) && (<>
                {this.showOperationDashboard(roles) && <Divider className={classes.divider} />}
                <Button href={ROUTE_PLANNING_DASHBOARD} color="inherit" className={classes.titleButton} >
                  <Typography color="inherit" variant="h6">Planning Dashboard</Typography>
                </Button>
              </>)}

            </div>
            <ApplicationFiltersContainer />
            <IconButton className={classes.logOffButton} color="inherit" onClick={this.logout} aria-label="Log Off">
              <Icon className={classes.logOffButtonIcon}>{"power_settings_new"}</Icon>
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    );
  }

  private showOperationDashboard = (roles: string[]): boolean => {
    let showOperation = false;
    if (roles) {
      roles.map((role) => {
        const lowerCaseRole = role.toLowerCase()
        if (lowerCaseRole === RoleType.Dashboard) {
          showOperation = true
        }
      })
    }
    return showOperation
  }

  private showPlanningDashboard = (roles: string[]): boolean => {
    let showPlanning = false;
    if (roles) {
      roles.map((role) => {
        const lowerCaseRole = role.toLowerCase()
        if (lowerCaseRole === RoleType.Accommodation || lowerCaseRole === RoleType.Departure_Status || lowerCaseRole === RoleType.Departure_manifest || lowerCaseRole === RoleType.Transfer) {
          showPlanning = true
        }
      })
    }
    return showPlanning
  }
}

export default withStyles(styles)(CustomToolbar);
