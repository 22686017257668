import * as React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography
} from '@material-ui/core/';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from './CovidQuestionnaireResponsesTable.styles';
import ICovidQuestionnaireQuestion from 'departures/types/ICovidQuestionnaireQuestion';

interface IProps extends WithStyles<typeof styles> {
  covidQuestionnaireQuestions: ICovidQuestionnaireQuestion[];
  title: string
}

class CovidQuestionnaireQuestionsTable extends React.Component<IProps> {
  constructor(props) {
    super(props);
  }

  public render() {

    const { covidQuestionnaireQuestions, title, classes } = this.props;

    if (
      covidQuestionnaireQuestions === undefined ||
      covidQuestionnaireQuestions.length === 0
    ) {
      return '';
    }

    return (
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item xs className={classes.titleText}>
            {title}
          </Grid>
          <Grid item xs>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.titleText}>Question Number</TableCell>
                  <TableCell className={classes.titleText}>Question</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {covidQuestionnaireQuestions.map((covidQuestionnaireQuestion, index) => (
                  <TableRow>
                    <TableCell>{index === (covidQuestionnaireQuestions.length - 1) ? 'Consent' : index + 1}. </TableCell>
                    <TableCell>
                      <Typography variant="body2" className={classes.typography}>
                        {covidQuestionnaireQuestion.covidQuestion}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(CovidQuestionnaireQuestionsTable);
