import * as React from 'react';
import NoResultsMessage from 'common/components/NoResultsMessage';
import IPlanningDeparture from '../types/IPlanningDeparture';
import { groupBy, sortBy } from 'lodash';
import PlanningProductDepartures from './PlanningProductDepartures';

interface IProps {
  departures?: IPlanningDeparture[];
}

class PlanningDepartureList extends React.Component<IProps> {
  public render() {
    const { departures } = this.props;

    if (!!departures && departures.length === 0) {
      return <NoResultsMessage message="No results to display." />;
    }
    const sortedDepartures = sortBy(departures, ['code']);
    const productGroups = groupBy(sortedDepartures, val => {
      return val.product.code;
    });

    return (
      <div>
        {Object.keys(productGroups).map(productGroupKey => {
          return (
            <PlanningProductDepartures
              key={productGroupKey}
              departures={productGroups[productGroupKey]}
            />
          );
        })}
      </div>
    );
  }
}

export default PlanningDepartureList;
