import { combineReducers } from 'redux';

import planningTransfers from './planningTransfers';
import planningAccommodation from './planningAccommodation';
import planningDepartures from './planningDepartures';
import planningProducts from './planningProducts';

export default combineReducers({
  transfers: planningTransfers,
  accommodation: planningAccommodation,
  departures: planningDepartures,
  products: planningProducts
});
