import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addMessage } from '../../common/actions/globalMessages';
import Router from './Router';

interface IDispatchProps {
  actions?: any;
}

class RouterContainer extends React.Component<IDispatchProps> {
  public render() {
    return <Router actions={this.props.actions} />;
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        addMessage: addMessage
      },
      dispatch
    )
  }
});

export default connect<IDispatchProps, {}, IDispatchProps>(
  undefined,
  mapDispatchToProps
)(RouterContainer);
