import * as actionTypes from '../actionTypes/departureAccommodation';
import IDepartureTransfer from '../types/IDepartureAccommodation';
import operationsPortalService from 'common/services/OperationsPortalService';

export const departureAccommodationGetRequest = departureId => ({
  type: actionTypes.DEPARTURE_ACCOMMODATION_GET_REQUEST,
  payload: {
    departureId
  }
});

export const departureAccommodationGetSuccess = selectedDepartureAccommodation => ({
  type: actionTypes.DEPARTURE_ACCOMMODATION_GET_SUCCESS,
  payload: {
    selectedDepartureAccommodation: selectedDepartureAccommodation
  }
});

export const departureAccommodationGetByDepartureFailure = () => ({
  type: actionTypes.DEPARTURE_ACCOMMODATION_GET_FAILURE
});

export const getDepartureAccommodation = id => async dispatch => {
  try {
    dispatch(departureAccommodationGetRequest(id));

    const data = await operationsPortalService.DepartureAccommodation.getByDeparture(
      id
    );
    dispatch(departureAccommodationGetSuccess(data as IDepartureTransfer[]));
  } catch (e) {
    dispatch(departureAccommodationGetByDepartureFailure());
    throw e;
  }
};
