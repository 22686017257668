import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import styles from './TripReportDialog.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { sortBy } from 'lodash';
import Typography from '@material-ui/core/Typography';
import ICrew from '../types/ICrew';
import createLink from 'common/helpers/createLink';
import { fileUploadStatus, EMPTY_FIELD_TEXT } from 'common/constants/constants';
import convertToAPIDate from 'common/helpers/convertToAPIDate';
import createURLWithQueryParams from 'common/helpers/createURLWithQueryParams';
import { ROUTE_REPORTING_TRIPREPORT } from 'common/constants/routes';
import CrewTripReportUnlockButton from './CrewTripReportUnlockButton';
import CustomButton from 'common/components/CustomButton';
import Grid from '@material-ui/core/Grid';
import IDeparture from '../types/IDeparture';
import formatDate from 'common/helpers/formatDate';
import env from 'common/constants/env';

interface IState {
  // Required property to trigger re-render on the material ui component to update the display text
  buttonsDisabled: boolean;
}

interface IProps extends WithStyles<typeof styles> {
  departure: IDeparture;
  selectedDepartureCrew?: ICrew[];
  showLoading?: any;
  hideLoading?: any;
  getAndDisplayFile?: any;
  actions: any;
}

class CrewTripReportStatus extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      buttonsDisabled: false
    };
  }

  public render() {
    const { departure, selectedDepartureCrew } = this.props;

    const crewSorted: ICrew[] =
      selectedDepartureCrew && sortBy(selectedDepartureCrew, ['name']);

    return (
      !!selectedDepartureCrew && (
        <React.Fragment>
          {crewSorted.map((crewMember, i) => (
            <Card key={i}>
              <CardHeader
                title={
                  <div>
                    <Grid container spacing={1}>
                      <Grid item xs={8} sm={8}>
                        <Typography variant="body1" color="inherit">
                          <span>{`${crewMember.name}`}</span>
                        </Typography>
                        <Typography>
                          {this.renderUploadDate(
                            crewMember.tripReportFirstUploaded,
                            'First Uploaded'
                          )}
                          {this.renderUploadDate(
                            crewMember.tripReportLastUploaded,
                            'Last Uploaded',
                            crewMember.tripReportUploadStatus ===
                            fileUploadStatus.UPLOADED
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <CustomButton
                          onClick={this.handleTripReport(
                            departure.id,
                            crewMember
                          )}
                          id="btn.tripReport"
                          label="View"
                          disabled={
                            this.state.buttonsDisabled ||
                            crewMember.tripReportUploadStatus !==
                            fileUploadStatus.UPLOADED
                          }
                          fontIcon="get_app"
                        />
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <CrewTripReportUnlockButton
                          departure={departure}
                          crewMember={crewMember}
                          actions={this.props.actions}
                        />
                      </Grid>
                    </Grid>
                  </div>
                }
              />
            </Card>
          ))}
          <Typography className={this.props.classes.note}>
            *Times are local to the Leader
          </Typography>
        </React.Fragment>
      )
    );
  }

  private handleDisableButtons = value => {
    this.setState({ buttonsDisabled: value });
  };

  private handleTripReport = (departureId, crewMember) => async e => {
    const route = createLink(ROUTE_REPORTING_TRIPREPORT, {
      departureId: departureId,
      crewId: crewMember.crewId
    });

    const urlParams = {
      executionDateTimeStr: convertToAPIDate(new Date())
    };

    const url = createURLWithQueryParams(`${route}`, urlParams);

    this.handleDisableButtons(true);

    this.props.showLoading();

    await this.props.getAndDisplayFile(url).then(() => {
      this.handleDisableButtons(false);
    });

    this.props.hideLoading();
  };

  private renderUploadDate(
    date: Date,
    label: string,
    displayToggle: boolean = true
  ) {
    return (
      <span className={this.props.classes.note}>
        <br />
        {`${label}: ${
          date && displayToggle
            ? formatDate(date, env.REACT_APP_DATETIME_FORMAT)
            : EMPTY_FIELD_TEXT
          }`}
      </span>
    );
  }
}

export default withStyles(styles)(CrewTripReportStatus);
