import * as React from 'react';
import { Redirect } from 'react-router-dom';
import * as routes from '../../common/constants/routes';
import RoleType from 'reports/enums/roleType';
import AuthorizedType from 'reports/enums/authorizedType';
import { getUserRoles } from 'reports/utils/userRolesUtil';

interface IProps {
  children: React.ReactNode;
}


class AuthorizedWrapper extends React.Component<IProps>{
  constructor(props) {
    super(props);
  }

  public render() {
    const roles = getUserRoles()
    const userAccess = this.findEnabledRoute(roles);

    if (userAccess === AuthorizedType.UnAuthorized) {
      return <Redirect to={{
        pathname: routes.ROUTE_ACCESSDENIED
      }} />
    }

    if (userAccess === AuthorizedType.OperationDashboardAccess) {
      if (window.location.pathname === routes.ROUTE_PLANNING_DASHBOARD) {
        return <Redirect to={{
          pathname: routes.ROUTE_DASHBOARD
        }} />
      }
    }

    if (userAccess === AuthorizedType.PlanningDashboardAccess) {
      if (window.location.pathname !== routes.ROUTE_PLANNING_DASHBOARD) {
        return <Redirect to={{
          pathname: routes.ROUTE_PLANNING_DASHBOARD
        }} />
      }
    }

    return (
      this.props.children
    );
  }

  private findEnabledRoute = (roles: string[]): string => {
    let authorized: string = '';
    if (roles.length === 0) {
      authorized = AuthorizedType.UnAuthorized

    } else if (roles.length === 1 && roles[0].toLowerCase() === RoleType.Dashboard) {
      authorized = AuthorizedType.OperationDashboardAccess;

    } else if (roles.length > 0 && !roles.toString().toLowerCase().includes(RoleType.Dashboard)) {
      authorized = AuthorizedType.PlanningDashboardAccess

    } else {
      authorized = AuthorizedType.FullAccess
    }
    return authorized;
  }
}



export default AuthorizedWrapper;
