import * as React from 'react';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps
} from '@material-ui/pickers';
import env from '../../common/constants/env';
import avoidEnterOnKeyPress from '../../common/helpers/avoidEnterOnKeyPress';

const maskChar = '_';
const dateFormat = env.REACT_APP_DATE_FORMAT;

export const InlineDatePicker: React.FC<
  any | KeyboardDatePickerProps
> = props => {
  const {
    id,
    value,
    onChange,
    label,
    disabled,
    helperText,
    error,
    minDate,
    maxDate,
    disableFuture,
    ...restInput
  } = props;

  return (
    <KeyboardDatePicker
      id={id}
      variant="inline"
      margin="normal"
      label={label}
      value={value}
      {...restInput}
      format={dateFormat}
      maskChar={maskChar}
      refuse={new RegExp(/\W/, 'gim')}
      onBlur={e => handleBlur(e, props)}
      allowKeyboardControl={false}
      disabled={disabled}
      placeholder={dateFormat}
      onChange={onChange}
      onKeyPress={avoidEnterOnKeyPress}
      error={error}
      helperText={helperText}
      minDate={minDate}
      maxDate={maxDate}
      disableFuture={disableFuture}
    />
  );
};

// Set the value to null if only mask is showing
//
// Workaround for bug in KeyboardDatePicker
// https://github.com/mui-org/material-ui-pickers/issues/1118
const handleBlur = (e, props) => {
  const displayValue: string = e.target.value;

  const maskOnly = maskChar.repeat(dateFormat.length);

  // if the field is completely empty, only the mask is showing
  if (displayValue === maskOnly) {
    props.onChange(null);
  }

  if (props.onBlur) {
    props.onBlur();
  }
};

export default InlineDatePicker;
