import * as actionTypes from '../actionTypes/planningTransfers';
import IPlanningTransfer from '../types/IPlanningTransfer';

type State = IPlanningTransfer[] | null;

export default (state: State = null, action) => {
  switch (action.type) {
    case actionTypes.PLANNING_TRANSFERS_GET_SUCCESS:
      return action.payload.transfers;
    default:
      return state;
  }
};
