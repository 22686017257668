import * as React from "react";
import Avatar from '@material-ui/core/Avatar';
import Logo from './Logo';
import styles from './AvatarLogo.styles'
import { withStyles } from '@material-ui/core/styles';

const AvatarLogo = ({ brand, imgId, classes }) => {
    return (
    <Avatar className={classes.avatar}>
      <Logo brand={brand} imgId={imgId} />
    </Avatar>
    )
}

 export default withStyles(styles)(AvatarLogo);