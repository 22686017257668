import * as actionTypes from '../../../actionTypes/planningDepartures/selectedDeparturePax';
import IPax from 'departures/types/IPax';

type State = IPax[] | null;

export default (state: State = null, action): State => {
  switch (action.type) {
    case actionTypes.PLANNING_DEPARTURE_PAX_LIST_GET_SUCCESS:
      return action.payload.selectedDeparturePax;
    case actionTypes.PLANNING_DEPARTURE_PAX_LIST_CLEAR:
      return null;
    default:
      return state;
  }
};
