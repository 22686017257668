import * as React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import AvatarLogo from 'common/components/AvatarLogo';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import IDeparture from '../types/IDeparture';
import ICrew from '../types/ICrew';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import DepartureGeneralDetails from './DepartureGeneralDetails';
import styles from './Departure.styles';
import DepartureActions from './DepartureActions';
import formatDate from 'common/helpers/formatDate';
import env from 'common/constants/env';
import { EMPTY_FIELD_TEXT, fileUploadStatus } from 'common/constants/constants';

interface IState {
  expanded: boolean;
}

interface IProps extends WithStyles<typeof styles> {
  departure: IDeparture;
  selectedDepartureCrew: ICrew[];
  actions: any;
  showLoading?: any;
  hideLoading?: any;
  getAndDisplayFile?: any;
  isOperationsDashboardCovidSurveyEnabled: boolean;
}

class Departure extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  public render() {
    const { departure, selectedDepartureCrew, classes, isOperationsDashboardCovidSurveyEnabled } = this.props;
    // ToolTip Text define here since not allowing line break inside the title property
    const HealthScreeningToolTip = <div style={{ whiteSpace: 'pre-line' }}>{'One or more passengers have indicated they may be ineligible to travel or are exempt from vaccination. \n\n For more information please check the detailed responses on the Covid Hub tab.'}</div>;
    const PaxCovidSurveyToolTip = <div style={{ whiteSpace: 'pre-line' }}>{'One or more passengers have indicated they may be ineligible to travel. \n\n For more information please check the detailed responses on the Covid Hub tab.'}</div>;
    const CrewCovidSurveyToolTip = <div style={{ whiteSpace: 'pre-line' }}>{'One or more crew members have indicated they may be ineligible to travel. \n\n For more information please check the detailed responses on the Covid Hub tab.'}</div>;
    return (
      <React.Fragment>
        <CardHeader
          avatar={
            <AvatarLogo
              brand={departure.brand}
              imgId={'img.header_' + departure.code}
            />
          }
          title={
            <Typography variant="body1" color="inherit">
              <span>{`${departure.code} - ${departure.name}`}</span>
            </Typography>
          }
          onClick={this.handleExpandClick}
          subheader={
            <React.Fragment>
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={isOperationsDashboardCovidSurveyEnabled ? 5 : 8} sm={isOperationsDashboardCovidSurveyEnabled ? 5 : 8}>
                  <DepartureGeneralDetails departure={departure} />
                </Grid>
                <Grid item={true} xs={isOperationsDashboardCovidSurveyEnabled ? 7 : 4} sm={isOperationsDashboardCovidSurveyEnabled ? 7 : 4}>
                  <Grid container={true} spacing={1}>
                    {isOperationsDashboardCovidSurveyEnabled && (
                      <Grid
                        item={true}
                        xs={2}
                        sm={2}
                        className={classes.gridItemCentered}
                      >
                        <div>{this.renderThumbsLabel('Crew Covid Survey')}</div>
                        <div>
                          {this.renderThumbs(
                            departure.isCovidSurveyCompletedByAllCrew,
                            'All crew submitted',
                            `${departure.covidSurveyCompletedCrewCount}/${departure.totalCrewCount
                            } submitted`
                          )}
                          {departure.isCrewCovidSurveyContainingAlertingAnswers ?
                            <Tooltip title={CrewCovidSurveyToolTip} aria-label="Add">
                              <Icon className={classes.priorityHighIcon}>report_problem</Icon>
                            </Tooltip> : ''}
                        </div>
                      </Grid>
                    )}
                    {isOperationsDashboardCovidSurveyEnabled && (
                      <Grid
                        item={true}
                        xs={2}
                        sm={2}
                        className={classes.gridItemCentered}
                      >
                        <div>{this.renderThumbsLabel('Pax Covid Survey')}</div>
                        <div>
                          {this.renderThumbs(
                            departure.isCovidSurveyCompletedByAllPax,
                            'All pax submitted',
                            `${departure.covidSurveyCompletedPaxCount}/${departure.totalPaxCount
                            } submitted`
                          )}
                          {departure.isPaxCovidSurveyContainingAlertingAnswers ?
                            <Tooltip title={PaxCovidSurveyToolTip} aria-label="Add">
                              <Icon className={classes.priorityHighIcon}>report_problem</Icon>
                            </Tooltip> : ''}
                        </div>
                      </Grid>
                    )}
                    {isOperationsDashboardCovidSurveyEnabled && (
                      <Grid
                        item={true}
                        xs={2}
                        sm={2}
                        className={classes.gridItemCentered}
                      >
                        <div>{this.renderThumbsLabel('Health Screening')}</div>
                        <div>
                          {this.renderThumbs(
                            departure.isPaxHealthScreeningCompletedByAllPax,
                            'All pax submitted',
                            `${departure.healthScreeningCompletedPaxCount}/${departure.totalPaxCount
                            } submitted`
                          )}
                          {departure.isPaxHealthScreeningContainingAlertingAnswers ?
                            <Tooltip title={HealthScreeningToolTip} aria-label="Add">
                              <Icon className={classes.priorityHighIcon}>report_problem</Icon>
                            </Tooltip> : ''}
                        </div>
                      </Grid>
                    )}
                    <Grid
                      item={true}
                      xs={isOperationsDashboardCovidSurveyEnabled ? 2 : 4}
                      sm={isOperationsDashboardCovidSurveyEnabled ? 2 : 4}
                      className={classes.gridItemCentered}
                    >
                      <div>{this.renderThumbsLabel('Pax Confirmed')}</div>
                      <div>
                        {this.renderThumbs(
                          departure.allPaxConfirmed,
                          'All pax confirmed',
                          `${departure.confirmedPaxCount}/${departure.totalPaxCount
                          } Pax confirmed`
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item={true}
                      xs={isOperationsDashboardCovidSurveyEnabled ? 2 : 4}
                      sm={isOperationsDashboardCovidSurveyEnabled ? 2 : 4}
                      className={classes.gridItemCentered}
                    >
                      <div>{this.renderThumbsLabel('Emergency Info')}</div>
                      <div>
                        {this.renderThumbs(
                          departure.isDeparturePaxReviewed,
                          <React.Fragment>
                            Leader review of Emergency details completed
                            {this.renderUploadDate(
                            departure.departurePaxFirstReviewedOn,
                            'First review complete'
                          )}
                            {this.renderUploadDate(
                              departure.departurePaxLastReviewedOn,
                              'Last review complete'
                            )}
                            <br />
                            <br />
                            *Times are local to leader
                          </React.Fragment>,
                          'Review of Emergency details not complete'
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item={true}
                      xs={isOperationsDashboardCovidSurveyEnabled ? 2 : 4}
                      sm={isOperationsDashboardCovidSurveyEnabled ? 2 : 4}
                      className={classes.gridItemCentered}
                    >
                      <div>{this.renderThumbsLabel('Trip Report')}</div>
                      <div>
                        {this.renderThumbs(
                          departure.tripReportSuccesfullyUploaded,
                          this.renderTripUploadDates(
                            'Trip report uploaded',
                            departure.crew
                          ),
                          this.renderTripUploadDates(
                            'Trip report not uploaded',
                            departure.crew
                          )
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          }
        />
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <DepartureActions
            departure={departure}
            selectedDepartureCrew={selectedDepartureCrew}
            actions={this.props.actions}
            showLoading={this.props.showLoading}
            hideLoading={this.props.hideLoading}
            getAndDisplayFile={this.props.getAndDisplayFile}
            isOperationsDashboardCovidSurveyEnabled={this.props.isOperationsDashboardCovidSurveyEnabled}
          />
        </Collapse>
      </React.Fragment>
    );
  }

  public componentWillReceiveProps(nextProps) {
    if (this.props.departure.id !== nextProps.departure.id) {
      this.setState({ expanded: false });
      return true;
    }

    return false;
  }

  private renderThumbs(
    complete: boolean,
    completeMessage: React.ReactNode,
    incompleteMessage: React.ReactNode
  ) {
    const { classes } = this.props;
    console.log(complete);
    return (
      <Typography variant="caption" className={classes.gridItemCentered}>
        {complete ? (
          <Tooltip title={completeMessage} aria-label="Add">
            <Icon className={classes.completedIcon}>thumb_up_alt</Icon>
          </Tooltip>
        ) : (
            <Tooltip title={incompleteMessage} aria-label="Add">
              <Icon className={classes.pendingIcon}>thumb_down_alt</Icon>
            </Tooltip>
          )}
      </Typography>
    );
  }

  private renderThumbsLabel(label: string) {
    return (
      <Typography
        variant="caption"
        color="inherit"
        className={this.props.classes.thumbsLabel}
      >
        {label}
      </Typography>
    );
  }

  private renderTripUploadDates(label: string, crew: ICrew[]) {
    const leaders = crew.filter(c => c.role === 'LEADER');
    const someLeadersUploaded =
      leaders.filter(c => c.tripReportFirstUploaded).length > 0;
    return (
      <React.Fragment>
        {label}
        {someLeadersUploaded && (
          <React.Fragment>
            {leaders.map(c => (
              <React.Fragment key={c.crewId}>
                <span>
                  <br />
                  {c.name}
                </span>
                {c.tripReportFirstUploaded ? (
                  <React.Fragment>
                    {this.renderUploadDate(
                      c.tripReportFirstUploaded,
                      'First Uploaded'
                    )}
                    {this.renderUploadDate(
                      c.tripReportLastUploaded,
                      'Last Uploaded',
                      c.tripReportUploadStatus === fileUploadStatus.UPLOADED
                    )}
                  </React.Fragment>
                ) : (
                    <span>
                      <br />
                      <span className={this.props.classes.indentLeft} />
                    Not Uploaded
                    </span>
                  )}
              </React.Fragment>
            ))}
            <br />
            <br />
            *Times are local to the Leader
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  private renderUploadDate(
    date: Date,
    label: string,
    displayToggle: boolean = true
  ) {
    return (
      <span>
        <br />
        <span className={this.props.classes.indentLeft} />
        {`${label}: ${date && displayToggle
          ? formatDate(date, env.REACT_APP_DATETIME_FORMAT)
          : EMPTY_FIELD_TEXT
          }`}
      </span>
    );
  }

  private handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };
}

export default withStyles(styles)(Departure);
