import * as React from 'react';
import styles from './ActionIcon.styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

const ActionIcon = ({ iconType, label, color, classes, className }) => (
  <div className={classes.actionAlignment}>
    <Icon color={color}>
      {iconType}
      <br />
      <Typography variant="caption" className={classes.iconText}>
        {label}
      </Typography>
    </Icon>
  </div>
);

export default withStyles(styles)(ActionIcon);
